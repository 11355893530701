import uniq from 'lodash/uniq';
import {
  SET_OBJECT,
  SET_SITE,
  SET_TIME_OFFSET,
  SET_ACTIVE_PANEL,
  ADD_PARENT_DOMAIN,
  SET_SHOW_MOBILE_CHANNEL_SELECTOR,
  TOGGLE_MOBILE_CHANNEL_SELECTOR,
  SET_CLOSE_CHANNEL_SELECT_ON_OUTSIDE_CLICK,
  SET_DISPLAY_MOBILE_NAV_MENU,
} from './actions';

// used for twitch parent value.
// if on non-prod, return the current domain. if not,
// return the site domain.
// as of 06/12/2020, looks like the twitch SDK used in our version
// of react-player only allows for one parent, so ordering matters.
const getDomains = () => {
  const siteDomain = window.INIT.SITE.settings.domain.url
    .split('/')[0];
  if (/maestro\.tv|maestro\.io|localhost/.test(document.domain)) {
    return uniq([document.domain, siteDomain]);
  }
  return [siteDomain];
};

export const getInitialState = () => (
  {
    activePanel: undefined,
    isChannelAvailable: true,
    mobileNavMenu: null,
    object: window.INIT.OBJECT?.archived ? null : window.INIT.OBJECT || null,
    objectLoaded: false,
    parentDomains: getDomains(),
    shouldCloseChannelSelectOnOutsideClick: false,
    showMobileChannelSelector: false,
    site: window.INIT.SITE,
    timeOffset: 0,
    timeOffsetLoaded: false,
  }
);

const appReducer = (state = getInitialState(), { payload, type }) => {
  switch (type) {
    case SET_OBJECT: {
      const { loaded: objectLoaded, object } = payload;
      return {
        ...state,
        object,
        objectLoaded,
      };
    }
    case SET_SITE: {
      const site = payload;
      return {
        ...state,
        site,
      };
    }
    case SET_ACTIVE_PANEL: {
      const activePanel = payload;
      return {
        ...state,
        activePanel,
      };
    }
    case SET_TIME_OFFSET: {
      const timeOffset = payload;
      return {
        ...state,
        timeOffset,
        timeOffsetLoaded: true,
      };
    }
    case ADD_PARENT_DOMAIN: {
      const { domain } = payload;
      return {
        ...state,
        parentDomains: uniq([...state.parentDomains, domain]),
      };
    }
    case SET_SHOW_MOBILE_CHANNEL_SELECTOR: {
      const showMobileChannelSelector = payload;
      return {
        ...state,
        showMobileChannelSelector,
      };
    }
    case SET_CLOSE_CHANNEL_SELECT_ON_OUTSIDE_CLICK: {
      const shouldCloseChannelSelectOnOutsideClick = payload;
      return {
        ...state,
        shouldCloseChannelSelectOnOutsideClick,
      };
    }
    case TOGGLE_MOBILE_CHANNEL_SELECTOR: {
      return {
        ...state,
        showMobileChannelSelector: !state.showMobileChannelSelector,
      };
    }
    case SET_DISPLAY_MOBILE_NAV_MENU: {
      const mobileNavMenu = payload;
      return {
        ...state,
        mobileNavMenu,
      };
    }
    default:
      return state;
  }
};

export default appReducer;
