export enum ActionKinds {
  AnswerAnyPoll = 'answer:polls:any',
  AnswerAnyPrediction = 'answer:predictions:any',
  AnswerAnyPredictionCorrectly = 'answer:predictions:any:correctly',
  AnswerAnyTrivia = 'answer:trivia:any',
  AnswerAnyTriviaCorrectly = 'answer:trivia:any:correctly',
  AnswerSpecificPoll = 'answer:polls:specific',
  AnswerSpecificPrediction = 'answer:predictions:specific',
  AnswerSpecificPredictionCorrectly = 'answer:predictions:specific:correctly',
  AnswerSpecificTrivia = 'answer:trivia:specific',
  AnswerSpecificTriviaCorrectly = 'answer:trivia:specific:correctly',
  Impossible = 'impossible',
  OpenLink = 'open:link',
  Retweet = 'retweet',
  ShareFacebookVideo = 'share:facebook:video',
  ViewPanel = 'view:panel',
  VisitChannel = 'visit:channel',
  VisitTime = 'visit:time',
  ViewQuest = 'quest:view', // placeholder action for quests with zero actions. used by dataflow.
  WatchVideo = 'watch:any:video',
  WatchSpecificVideo = 'watch:specific:video',
}

export enum ReadableKinds {
  'answer:polls:any' = 'Answer Any Poll',
  'answer:predictions:any' = 'Answer Any Prediction',
  'answer:predictions:any:correctly' = 'Answer Any Prediction Correctly',
  'answer:trivia:any' = 'Answer Any Trivia',
  'answer:trivia:any:correctly' = 'Answer Any Trivia Correctly',
  'answer:polls:specific' = 'Answer Specific Poll',
  'answer:predictions:specific' = 'Answer Specific Prediction',
  'answer:predictions:specific:correctly' = 'Answer Specific Prediction Correctly',
  'answer:trivia:specific' = 'Answer Specific Trivia',
  'answer:trivia:specific:correctly' = 'Answer SpecificTrivia Correctly',
  'impossible' = 'Impossible',
  'open:link' = 'Open Link',
  'quest:view' = 'View Quest',
  'retweet' = 'Retweet',
  'share:facebook:video' = 'Facebook Share',
  'view:panel' = 'View Panel',
  'visit:channel' = 'Visit Channel',
  'visit:time' = 'Visit Time',
  'watch:any:video' = 'Watch Video',
  'watch:specific:video' = 'Watch Specific Video',
}

export default interface IQuestAction {
  arrayId: string;
  body: string;
  callToAction?: string;
  channelId?: string | null;
  kind: ActionKinds;
  timeMaximum?: number | null;
  timeMinimum?: number | null;
  validationValue?: string | null;
  valueRequired?: number | null;
}
