import { IShopifyBlockData } from 'components/page-blocks/Shopify/utils';
import { PageType } from 'models';
import { createSelector } from 'reselect';
import { getPendingOrActualLandingPageContent } from 'services/admin/selectors';
import { getPageViewType } from 'services/app';
import { ILandingPageContent, IShopifyBlock } from 'services/app/models/ILandingPageContent';
import { getProductsByIds } from 'services/shopify/selectors';
import IState from 'services/state';
import { getShopifyBlockPanel } from 'services/user-layout/selectors';

export const getCart = (state: IState) => ({
  cartId: state.ecommerce.cartId,
  cartItems: state.ecommerce.cartItems,
});
export const getCartItems = (state: IState) => state.ecommerce.cartItems;
export const getCartItemsQuantity = (state: IState) => state.ecommerce.cartItems.reduce((acc, item) => {
  return acc + item.quantity;
}, 0);
export const getShopifyCheckout = (state: IState) => state.ecommerce.shopify.checkout;
export const getShopifyCheckoutAvailableShippingLines = (state: IState) => state.ecommerce.shopify.checkout?.availableShippingRates;
export const getShopifyCheckoutTotalTax = (state: IState) => state.ecommerce.shopify.checkout?.totalTaxV2;
export const getShopifyCheckoutLoading = (state: IState) => state.ecommerce.shopify.loading;
export const getShopifyCheckoutError = (state: IState) => state.ecommerce.shopify.error;
export const getShopifyCheckoutReady = (state: IState) => state.ecommerce.shopify.checkout?.ready;
export const getShopifyTransaction = (state: IState) => state.ecommerce.shopify.transaction;
export const getShopifyPaymentProcessing = (state: IState) => state.ecommerce.shopify.paymentProcessing;
export const getProductDetail = (state: IState) => state.ecommerce.productDetail;
export const getEcommerceViews = (state: IState) => state.ecommerce.views;
export const getShippingMethod = (state: IState) => state.ecommerce.shopify.shippingMethod;
export const getLoggedOutUserEmail = (state: IState) => state.ecommerce.loggedOutUserEmail;
export const getEcommerceView = createSelector(
  getEcommerceViews,
  (views) => views[views.length - 1],
);
export const getSubtotal = (state: IState) => {
  const cart = getCart(state);
  const productsIds = cart.cartItems.map(item => item.productId);
  const shopifyProducts = getProductsByIds(state, productsIds);

  let result = 0;
  for (const cartItem of cart.cartItems) {
    const product = shopifyProducts.find(p => p.id === cartItem.productId);
    if (!product) {
      continue;
    }

    const variants = product.variants?.edges.map(edge => edge.node);
    if (!variants) {
      continue;
    }

    const variant = variants.find(v => v.id === cartItem.variantId);
    if (!variant) {
      continue;
    }

    result += Number(variant.price.amount) * cartItem.quantity;
  }

  return result;
};

export const getShopifyBlockData = createSelector(
  [
    (state: IState) => getPageViewType(state),
    (state: IState) => getPendingOrActualLandingPageContent(state),
    (state: IState) => getShopifyBlockPanel(state),
  ],
  (pageType, landingContent, shopifyBlockPanel): IShopifyBlockData | null => {
    if (pageType === PageType.LANDING) {
      if (!landingContent) {
        return null;
      }
      const shopifyBlock = landingContent.find((content: ILandingPageContent) => {
        return content.kind === 'shopify';
      }) as IShopifyBlock;
      return shopifyBlock?.data;
    }

    if (pageType === PageType.CHANNEL) {
      if (!shopifyBlockPanel) {
        return null;
      }
      return shopifyBlockPanel?.renderer?.blockData || null;
    }

    return null;
  },
);
