import { IAccountAddressState } from './models';
import {
  IAccountAddressAction,
  RESET_ACCOUNT_ADDRESS_REDUCER,
  SET_ACCOUNT_ADDRESS_FIELD,
  SET_ACCOUNT_ADDRESSES,
  SET_ACCOUNT_ADDRESS_LOADING,
} from './actions';

const INITIAL_STATE: IAccountAddressState = {
  accountAddresses: [],
  loading: false,
  accountAddress: {
    accountId: '',
    address: {
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      countryCode: '',
      phoneNumber: '',
      complete: false,
    },
    name: '',
  },
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

const accountAddressReducer = (
  state: IAccountAddressState = INITIAL_STATE,
  action: IAccountAddressAction,
) => {
  switch (action.type) {
    case RESET_ACCOUNT_ADDRESS_REDUCER: {
      return {
        ...state,
        accountAddress: [],
      };
    }
    case SET_ACCOUNT_ADDRESS_FIELD: {
      return {
        ...state,
        accountAddress: action.payload,
      };
    }
    case SET_ACCOUNT_ADDRESS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_ACCOUNT_ADDRESSES: {
      return {
        ...state,
        accountAddresses: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default accountAddressReducer;
