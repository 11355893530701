import { call, delay, select, take } from 'redux-saga/effects';

import { ENABLE_INSIGHTS_LOGGING } from 'config';
import { getObjectId, getSiteId } from 'services/app/selectors';
import { getUserId } from 'services/auth/selectors';
import { getDevicePermanentId } from 'services/device/selectors';
import { getSessionId } from 'services/insights/selectors';
import { sendBeacon } from './api';
import { BEACON_INTERVAL_SECONDS_MAX, BEACON_INTERVAL_SECONDS_MIN } from './constants';

const waitForBeaconData = function* () {
  let state;
  while (!getSessionId(state = yield select())) { // eslint-disable-line no-cond-assign
    yield take('*');
  }
  const data = {
    accountId: getUserId(state),
    channelId: getObjectId(state),
    deviceId: getDevicePermanentId(state),
    sessionId: getSessionId(state),
    siteId: getSiteId(state),
  };
  return data;
};

const getInterval = () => {
  const max = BEACON_INTERVAL_SECONDS_MAX;
  const min = BEACON_INTERVAL_SECONDS_MIN;
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const activeUserSaga = function* () {
  while (true) {
    try {
      const beaconData = yield call(waitForBeaconData);
      yield call(sendBeacon, beaconData);
      if (ENABLE_INSIGHTS_LOGGING) {
        /* eslint-disable no-console */
        console.warn('Sent active user beacon');
        /* eslint-enable */
      }
    } catch (error) {
      /* eslint-disable no-console */
      console.error('Active beacon error:');
      console.error(error);
      /* eslint-enable */
    }
    const interval = getInterval();
    yield delay(interval * 1000);
  }
};

export default activeUserSaga;
