import { ISaveAddressParams, IAccountAddress } from './models';

export const SET_ACCOUNT_ADDRESS_FIELD = 'address/SET_ACCOUNT_ADDRESS_FIELD';
export const RESET_ACCOUNT_ADDRESS_REDUCER = 'address/RESET_ACCOUNT_ADDRESS_REDUCER';
export const SAVE_ADDRESS = 'address/SAVE_ADDRESS';
export const FETCH_ACCOUNT_ADDRESSES = 'address/FETCH_ACCOUNT_ADDRESSES';
export const SET_ACCOUNT_ADDRESSES = 'address/SET_ACCOUNT_ADDRESSES';
export const SET_ACCOUNT_ADDRESS_LOADING = 'address/SET_ACCOUNT_ADDRESS_LOADING';

export type IAccountAddressAction =
  IResetAccountAddressReducerAction |
  ISetAccountAddressFieldAction |
  ISetAccountAddressesAction |
  ISetAccountAddressLoadingAction;

interface IResetAccountAddressReducerAction {
  type: typeof RESET_ACCOUNT_ADDRESS_REDUCER;
}

interface ISetAccountAddressFieldAction {
  payload: IAccountAddress;
  type: typeof SET_ACCOUNT_ADDRESS_FIELD,
}

interface ISetAccountAddressLoadingAction {
  payload: boolean;
  type: typeof SET_ACCOUNT_ADDRESS_LOADING,
}

export interface ISaveAddressAction {
  payload: ISaveAddressParams;
  type: typeof SAVE_ADDRESS;
}

export interface ISetAccountAddressesPayload {
  addresses: IAccountAddress[];
}

export interface ISetAccountAddressesAction {
  payload: ISetAccountAddressesPayload;
  type: typeof SET_ACCOUNT_ADDRESSES;
}

export interface IFetchAccountAddressesAction {
  payload: string,
  type: typeof FETCH_ACCOUNT_ADDRESSES;
}

export const resetAccountAddressReducer = (): IResetAccountAddressReducerAction => {
  return {
    type: RESET_ACCOUNT_ADDRESS_REDUCER,
  };
};

export const setAccountAddressField = (payload: IAccountAddress): ISetAccountAddressFieldAction => {
  return {
    payload,
    type: SET_ACCOUNT_ADDRESS_FIELD,
  };
};

export const setAccountAddressLoading = (payload: boolean): ISetAccountAddressLoadingAction => {
  return {
    payload,
    type: SET_ACCOUNT_ADDRESS_LOADING,
  };
};

export const saveAddress = (payload: ISaveAddressParams): ISaveAddressAction => {
  return {
    payload,
    type: SAVE_ADDRESS,
  };
};

export const fetchAccountAddresses = (payload: string): IFetchAccountAddressesAction => {
  return {
    payload,
    type: FETCH_ACCOUNT_ADDRESSES,
  };
};

export function setAccountAddresses(payload: ISetAccountAddressesPayload): ISetAccountAddressesAction {
  return {
    payload,
    type: SET_ACCOUNT_ADDRESSES,
  };
}
