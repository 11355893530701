import IModel from 'models/IModel';
import { PageType } from 'models';

type ObjectValues<T> = T[keyof T];

export const NAVIGATION_TYPE = {
  custom: 'custom',
  default: 'default',
} as const;
export type NavigationType = ObjectValues<typeof NAVIGATION_TYPE>;

export const NAVIGATION_PARENT_TYPE = {
  channel: 'channel',
  folder: 'folder',
  landing: 'landing',
} as const;
export type NavigationParentType = ObjectValues<typeof NAVIGATION_PARENT_TYPE>;

export const NAVIGATION_CHILD_TYPE = {
  channel: 'channel',
  landing: 'landing',
} as const;
export type NavigationChildType = ObjectValues<typeof NAVIGATION_CHILD_TYPE>;

export interface IExternalLink {
  id: string,
  name: string,
  url: string,
}

export interface IBaseNavigationItem {
  id: string,
  name: string,
  private?: boolean,
  slug: string,
}

export interface INavigationChild extends IBaseNavigationItem {
  type: NavigationChildType,
}

export interface INavigationParent extends IBaseNavigationItem {
  children: INavigationChild[],
  type: NavigationParentType,
}

export type INavigationItem = INavigationChild | INavigationParent;

export default interface INavigation extends IModel {
  _id?: string,
  externalLinks: IExternalLink[],
  name: string,
  parents: INavigationParent[],
  siteId: string,
  type: NavigationType,
}

export const navigationParentTypeToPageType = {
  [NAVIGATION_PARENT_TYPE.landing]: PageType.LANDING,
  [NAVIGATION_PARENT_TYPE.channel]: PageType.CHANNEL,
};

export const pageTypeToNavigationParentType = {
  [PageType.LANDING]: NAVIGATION_PARENT_TYPE.landing,
  [PageType.CHANNEL]: NAVIGATION_PARENT_TYPE.channel,
};

export const pageTypeToNavigationChildType = {
  [PageType.LANDING]: NAVIGATION_CHILD_TYPE.landing,
  [PageType.CHANNEL]: NAVIGATION_CHILD_TYPE.channel,
};

export const navigationChildTypeToNavigationParentType = {
  [NAVIGATION_CHILD_TYPE.landing]: NAVIGATION_PARENT_TYPE.landing,
  [NAVIGATION_CHILD_TYPE.channel]: NAVIGATION_PARENT_TYPE.channel,
};

// should be removed after navigation v1 goes out
export interface INavigationLegacy {
  channelSpecific?: boolean;
  items?: Array<{
    arrayId?: string;
    children?: Array<{
      generatedKey?: string;
      link?: string;
      open?: boolean;
      text?: string;
    }>;
    hasMenu?: 'no' | 'yes'
    image?: string;
    link?: string;
    text?: string;
  }>;
  state: 'on' | 'off';
}
