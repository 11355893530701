import { prefix } from 'redux-utils';
import { IDeveloperSetting, IWebhooks } from './models';

const action = prefix('developer');

export const UPDATE_SAVED = action('UPDATE_SAVED');

export const CREATE_API_KEY = action('CREATE_API_KEY');
export const CREATED_API_KEY = action('CREATED_API_KEY');
export const STOP_IS_GENERATING_API_KEY = action('STOP_IS_GENERATING_API_KEY');
export const DELETE_API_KEY = action('DELETE_API_KEY');
export const DELETED_API_KEY = action('DELETED_API_KEY');
export const DELETE_API_KEY_FAILED = action('DELETE_API_KEY_FAILED');
export const FIND_API_KEY = action('FIND_API_KEY');
export const FOUND_API_KEY = action('FOUND_API_KEY');
export const FAILED_TO_FIND_API_KEY = action('FAILED_TO_FIND_API_KEY');
export const SET_CODE_INJECTION = action('SET_CODE_INJECTION');

export const FIND_WEBHOOKS = action('FIND_WEBHOOKS');
export const FOUND_WEBHOOKS = action('FOUND_WEBHOOKS');
export const FAILED_TO_FIND_WEBHOOKS = action('FAILED_TO_FIND_WEBHOOKS');
export const INSERT_WEBHOOKS = action('INSERT_WEBHOOKS');
export const INSERTED_WEBHOOKS = action('INSERTED_WEBHOOKS');
export const UPDATE_WEBHOOKS = action('UPDATE_WEBHOOKS');
export const UPDATED_WEBHOOKS = action('UPDATED_WEBHOOKS');
export const WEBHOOK_URL_CHANGED = action('WEBHOOK_URL_CHANGED');
export const RESET_WEBHOOKS = action('RESET_WEBHOOKS');
export const UPDATE_DEVELOPER_SETTING = action('UPDATE_DEVELOPER_SETTING');

export const updateSaved = (payload: boolean) => ({ payload, type: UPDATE_SAVED });

export const findApiKey = () => ({ type: FIND_API_KEY });
export const foundApiKey = (payload: any) => ({ payload, type: FOUND_API_KEY });
export const failedToFindApiKey = () => ({ type: FAILED_TO_FIND_API_KEY });
export const deleteApiKey = () => ({ type: DELETE_API_KEY });
export const deleteApiKeyFailed = () => ({ type: DELETE_API_KEY_FAILED });
export const deletedApiKey = () => ({ type: DELETED_API_KEY });
export const createApiKey = () => ({ type: CREATE_API_KEY });
export const createdApiKey = (payload: any) => ({ payload, type: CREATED_API_KEY });
export const stopIsGeneratingApiKey = () => ({ type: STOP_IS_GENERATING_API_KEY });

export const findWebhooks = () => ({ type: FIND_WEBHOOKS });
export const foundWebhooks = (payload: IWebhooks) => ({ payload, type: FOUND_WEBHOOKS });
export const failedToFindWebhooks = () => ({ type: FAILED_TO_FIND_WEBHOOKS });
export const insertWebhooks = (payload: IWebhooks) => ({ payload, type: INSERT_WEBHOOKS });
export const insertedWebhooks = (payload: IWebhooks) => ({ payload, type: INSERTED_WEBHOOKS });
export const updateWebhooks = (payload: IWebhooks) => ({ payload, type: UPDATE_WEBHOOKS });
export const updatedWebhooks = (payload: IWebhooks) => ({ payload, type: UPDATED_WEBHOOKS });
export const webhookUrlChanged = (payload: boolean) => ({ payload, type: WEBHOOK_URL_CHANGED });
export const resetWebhooks = () => ({ type: RESET_WEBHOOKS });

export interface ICodeInjectionPayload {
  type: 'body' | 'header',
  value?: string,
}

export const updateDeveloperSetting = (payload: IDeveloperSetting) => ({
  payload,
  type: UPDATE_DEVELOPER_SETTING,
});


export const setCodeInjection = (payload: ICodeInjectionPayload) => ({
  payload,
  type: SET_CODE_INJECTION,
});

export interface IInsertWebhooksAction {
  payload: IWebhooks,
  type: typeof INSERT_WEBHOOKS,
}

export interface IUpdateWebhooksAction {
  payload: IWebhooks,
  type: typeof UPDATE_WEBHOOKS,
}

export interface IUpdateDeveloperSettingAction {
  payload: IDeveloperSetting,
  type: typeof UPDATE_DEVELOPER_SETTING,
}
