import { IPrice } from 'models/ISubscription';
import { GrantedMethod } from 'services/gate/models';
import {
  CLOSE_MODAL,
  ICommunityAction,
  SET_USER_FILTER_MODAL_OPTION,
  SET_USER_FILTER,
  SET_USER_SEARCH_QUERY,
  SET_VIEW,
  SHOW_MODAL,
  UPDATE_USER_PROFILE,
  SET_ACTIVE_PAGE,
  SET_USER_SORT_QUERY,
  SET_EXPORT_STATUS,
  SET_EXPORT_FILE,
  SET_EXPORT_FILE_LINK,
  SET_USER_SEARCH_BY,
  RESET,
} from './actions';

export enum FilterModalTypes {
  accountInfo = 'accountInfo',
  dateTime = 'dateTime',
  entitlements = 'entitlements',
  paidEntitlements = 'paidEntitlements',
  grant = 'grant',
  refund = 'refund',
  cancel = 'cancel',
  confirmation = 'confirmation',
}

export enum SearchByTypes {
  accountInfo = 'accountInfo',
  entitlements = 'entitlements',
  paidEntitlements = 'paidEntitlements',
  accessCode = 'accessCode',
  receiptNumber = 'receiptNumber',
}

export type ModalTypes =
  | FilterModalTypes.confirmation
  | FilterModalTypes.dateTime
  | FilterModalTypes.entitlements
  | FilterModalTypes.grant
  | FilterModalTypes.paidEntitlements;

export enum Views {
  userSection = 'user-section',
  userProfile = 'user-profile',
  default = '',
}

type EntitlementStatus = 'ADMIN_LABEL_ACTIVE' | 'LABEL_REFUNDED_DATE' | 'LABEL_CANCELED' | 'LABEL_EXPIRED';
type EntitlementMethodDescription = (
  'LABEL_ACCESS_CODE_METHOD' |
  'LABEL_PURCHASED_METHOD' |
  'LABEL_MANUALLY_GRANTED' |
  'LABEL_GRANTED_BY_API'
);
type EntitlementStatusTooltip = (
  'LABEL_CANCELED_DATE' |
  'LABEL_TICKET_REMOVED' |
  'LABEL_SUB_CANCELED_DATE_PAST' |
  'LABEL_SUB_CANCELED_DATE_FUTURE'
);

export interface IEntitlementItem {
  accessCode?: string | null;
  actions: {
    canCancel?: boolean;
    canRefund?: boolean;
    canRemove?: boolean;
  };
  currentPeriodEnd: number;
  date: number | undefined;
  dateCanceled?: number;
  dateRefunded?: number;
  formattedPrice?: string | null;
  grantedMethod: GrantedMethod;
  id: string;
  methodDescription: EntitlementMethodDescription;
  name: string;
  price: IPrice | null;
  sku: string;
  status: EntitlementStatus;
  statusTooltip: EntitlementStatusTooltip;
  wasRefunded?: boolean;
}

export enum ExportStatus {
  ready = 'READY',
  loading = 'LOADING',
  download = 'DOWNLOAD',
}

export interface IExportFile {
  exportId: string;
  fileName: string;
  link?: string;
}

export interface IFilter {
  channelId?: string;
  channelName?: string;
  timeEnd?: number | null;
  timeStart?: number | null;
}

export interface ISearch {
  filter?: IFilter;
  searchBy: SearchByTypes;
  searchQuery: string;
}
export interface ICommunityState {
  modalType: string;
  userProfile: {
    accountId: string;
    subscription: Partial<IEntitlementItem>;
  };
  userSection: {
    activePage: number;
    exportFile: IExportFile | null;
    exportStatus: ExportStatus;
    filterModal: FilterModalTypes;
    queryDescription: string | undefined;
    search: ISearch;
    sortQuery: {
      sortBy: string;
      sortDirection: number;
    };
  };
  view: Views;
}

export const getInitialState = (): ICommunityState => ({
  modalType: '',
  userProfile: {
    accountId: '',
    subscription: {},
  },
  userSection: {
    activePage: 1,
    filterModal: FilterModalTypes.accountInfo,
    exportFile: null,
    exportStatus: ExportStatus.ready,
    queryDescription: '',
    search: {
      searchBy: SearchByTypes.accountInfo,
      searchQuery: '',
    },
    sortQuery: {
      sortBy: 'created',
      sortDirection: -1,
    },
  },
  view: Views.userSection,
});

export const communityReducer = (
  state: ICommunityState = getInitialState(),
  action: ICommunityAction,
): ICommunityState => {
  switch (action.type) {
    case SET_VIEW: {
      return {
        ...state,
        view: action.payload,
      };
    }
    case SET_USER_SORT_QUERY: {
      return {
        ...state,
        userSection: {
          ...state.userSection,
          sortQuery: { ...action.payload },
        },
      };
    }
    case SET_USER_SEARCH_QUERY: {
      return {
        ...state,
        userSection: {
          ...state.userSection,
          queryDescription: action.payload.queryDescription,
          filterModal: !action.payload
            ? FilterModalTypes.accountInfo
            : state.userSection.filterModal,
          search: {
            ...state.userSection.search,
            searchQuery: action.payload.searchQuery,
          },
        },
      };
    }
    case SET_USER_FILTER_MODAL_OPTION: {
      return {
        ...state,
        userSection: {
          ...state.userSection,
          filterModal: action.payload,
        },
      };
    }
    case SET_USER_SEARCH_BY: {
      return {
        ...state,
        userSection: {
          ...state.userSection,
          search: {
            ...state.userSection.search,
            searchBy: action.payload,
          },
        },
      };
    }
    case SET_USER_FILTER: {
      return {
        ...state,
        userSection: {
          ...state.userSection,
          search: {
            ...state.userSection.search,
            filter: action.payload,
          },
        },
      };
    }
    case SET_ACTIVE_PAGE: {
      return {
        ...state,
        userSection: {
          ...state.userSection,
          activePage: action.payload,
        },
      };
    }
    case SET_EXPORT_STATUS: {
      return {
        ...state,
        userSection: {
          ...state.userSection,
          exportStatus: action.payload,
        },
      };
    }
    case SET_EXPORT_FILE: {
      return {
        ...state,
        userSection: {
          ...state.userSection,
          exportFile: action.payload,
        },
      };
    }
    case SET_EXPORT_FILE_LINK: {
      return {
        ...state,
        userSection: {
          ...state.userSection,
          exportFile: {
            ...state.userSection.exportFile!,
            link: action.payload,
          },
        },
      };
    }
    case UPDATE_USER_PROFILE: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          ...action.payload,
        },
      };
    }
    case SHOW_MODAL:
      return {
        ...state,
        modalType: action.payload,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalType: '',
      };
    case RESET:
      return getInitialState();
    default: {
      return state;
    }
  }
};

export default communityReducer;
