export const RECEIVED_TWITCH_TOKEN = 'auth-legacy/RECEIVED_TWITCH_TOKEN';
export const RECEIVED_FACEBOOK_TOKEN = 'auth-legacy/RECEIVED_FACEBOOK_TOKEN';

declare interface IAuthWithTwitchTokenPayload {
  access_token?: string;
}

export declare interface IAuthWithTwitchTokenAction {
  payload: IAuthWithTwitchTokenPayload;
  type: typeof RECEIVED_TWITCH_TOKEN;
}

declare interface IAuthWithFacebookTokenPayload {
  accessToken: string;
  data_access_expiration_time: number;
  expiresIn: number;
  signedRequest: string;
  userID: string;
}

export declare interface IAuthWithFacebookTokenAction {
  payload: IAuthWithFacebookTokenPayload;
  type: typeof RECEIVED_FACEBOOK_TOKEN;
}

export const authWithTwitchToken = (
  payload: IAuthWithTwitchTokenPayload,
): IAuthWithTwitchTokenAction => ({
  payload,
  type: RECEIVED_TWITCH_TOKEN,
});

export const authWithFacebookToken = (
  payload: IAuthWithFacebookTokenPayload,
): IAuthWithFacebookTokenAction => ({
  payload,
  type: RECEIVED_FACEBOOK_TOKEN,
});
