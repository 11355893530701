import { STATIC_BASE_URL } from 'config';

export const pageTypes = {
  CHANNEL: 'channel',
  LANDING: 'landing',
};

export const defaultProfileImages = [
  {
    isAdmin: false,
    url: `${STATIC_BASE_URL}/media/552ef679e3d499087b0ea28b/57a522877712768b525ac186.jpg`,
  },
  {
    isAdmin: false,
    url: `${STATIC_BASE_URL}/media/552ef679e3d499087b0ea28b/57a5227bf3dc24143b67aa31.jpg`,
  },
];

export const defaultCustomTextModal = {
  privacy: '',
  terms: '',
  text: '',
};

export enum ISiteTags {
  FIRST_ADMIN_LOGIN = 'first-admin-login',
}
