import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApis } from 'contexts/ApisContext';
import { getSiteId } from 'services/app';
import { getPrimaryToken } from 'services/auth';
import { getDevicePermanentId } from 'services/device';
import { hasDeviceLimitModal } from 'services/modals/selectors';
import { isUserAdmin } from 'services/auth/selectors';
import { ModalKinds } from 'services/modals/types';
import { dismissModal, showModal } from 'services/modals';
import { getDeviceKickedAt, isDeviceLimitEnabled } from 'services/video';

export const useCanIPlay = (
  contentId: string | undefined | null,
  kind = 'video' as CanIPlay.CanIPlayParams['kind'],
) => {
  const deviceId = useSelector(getDevicePermanentId);
  const token = useSelector(getPrimaryToken) ?? undefined;
  const siteId = useSelector(getSiteId);
  const shouldWatchDeviceLimit = useSelector(isDeviceLimitEnabled);
  const deviceKickedAt = useSelector(getDeviceKickedAt);

  const { caniplay } = useApis();
  const [canIPlayResult, setCanIPlayResult] =
    useState<CanIPlay.CanIPlayResult>();

  useEffect(() => {
    let cleanup = () => {
      // placeholder
    };

    if (!contentId) {
      setCanIPlayResult(undefined);
      return;
    }

    caniplay
      .canIPlay({
        kind,
        deviceId,
        id: contentId,
        siteId,
        token,
        callback: setCanIPlayResult,
        poll: shouldWatchDeviceLimit,
      })
      .then((cancelCanIPlayListener) => {
        cleanup = cancelCanIPlayListener;
      });

    return () => {
      cleanup();
    };
  }, [
    deviceId,
    siteId,
    token,
    caniplay,
    contentId,
    kind,
    shouldWatchDeviceLimit,
    deviceKickedAt, // this refreshes the caniplay listener after a device is kicked
  ]);

  useDeviceLimit(canIPlayResult, deviceKickedAt ?? 0);

  return canIPlayResult;
};

export const useDeviceLimit = (
  canIPlayResult: CanIPlay.CanIPlayResult | undefined,
  deviceKickedAt: number,
) => {
  const dispatch = useDispatch();
  const isDeviceLimitModalOpen = useSelector(hasDeviceLimitModal);
  const isAdmin = useSelector(isUserAdmin);

  useEffect(() => {
    if (
      isAdmin ||
      !canIPlayResult ||
      canIPlayResult.receivedAt < deviceKickedAt
    ) {
      return;
    }

    if (canIPlayResult?.result === 'DEVICE_LIMIT') {
      if (!isDeviceLimitModalOpen) {
        dispatch(
          showModal({
            data: {
              accountDevices: canIPlayResult.devices,
              siteDeviceLimit: canIPlayResult.siteDeviceLimit,
              locked: true,
            },
            kind: ModalKinds.deviceLimit,
          }),
        );
      }

      return;
    }

    if (isDeviceLimitModalOpen) {
      dispatch(dismissModal(ModalKinds.deviceLimit));
    }
  }, [isAdmin, canIPlayResult, isDeviceLimitModalOpen, deviceKickedAt]);
};
