export enum Ranges {
  CUSTOM = 'custom',
  ONE_DAY = 'one-day',
  SEVEN_DAYS = 'seven-days',
  THIRTY_DAYS = 'thirty-days',
  NINETY_DAYS = 'ninety-days',
}

export interface IRange {
  rangeEnd: number;
  rangeStart: number;
}

export interface IAnalyticsState {
  customRange: IRange;
  defaultRange: IRange;
  rangeType: Ranges
}

