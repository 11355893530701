import IModel from './IModel';

export declare interface IFortniteLeaderboardItem {
  eliminations: number;
  matches: number;
  players: IPlayer[];
  points: number;
  rank: number;
  rankDivision?: RankDivision;
  royals: number;
  teamId: string;
}

export declare interface IPlayer {
  displayName: string;
  flag?: string;
}

export default interface IFortniteLeaderboard extends IModel {
  eventId: string;
  eventWindowId: string;
  items?: IFortniteLeaderboardItem[];
}

export const RANK_ICONS_MAP = {
  '-1': 'Unranked.png',
  '0': 'Bronze_1.png',
  '1': 'Bronze_2.png',
  '2': 'Bronze_3.png',
  '3': 'Silver_1.png',
  '4': 'Silver_2.png',
  '5': 'Silver_3.png',
  '6': 'Gold_1.png',
  '7': 'Gold_2.png',
  '8': 'Gold_3.png',
  '9': 'Platinum_1.png',
  '10': 'Platinum_2.png',
  '11': 'Platinum_3.png',
  '12': 'Diamond_1.png',
  '13': 'Diamond_2.png',
  '14': 'Diamond_3.png',
  '15': 'Elite.png',
  '16': 'Champion.png',
  '17': 'Unreal.png',
};

export type RankDivision = keyof typeof RANK_ICONS_MAP;
