import { ICustomPanel } from './models';
export const CLOSE_PANEL = `custom-panels/CLOSE_PANEL`;
export const OPEN_PANEL = `custom-panels/OPEN_PANEL`;
export const OPEN_PROFILE_PANEL = `custom-panels/OPEN_PROFILE_PANEL`;
export const TOGGLE_PROFILE_PANEL = `custom-panels/TOGGLE_PROFILE_PANEL`;

export type ICustomPanelsAction = IClosePanelAction | IOpenPanelAction;

export interface IClosePanelAction {
  type: typeof CLOSE_PANEL;
}

export interface IOpenPanelAction {
  payload: ICustomPanel;
  type: typeof OPEN_PANEL;
}

export const closePanel = (): IClosePanelAction => ({
  type: CLOSE_PANEL,
});

export const openPanel = (panel: ICustomPanel): IOpenPanelAction => ({
  payload: panel,
  type: OPEN_PANEL,
});

export const openProfilePanel = () => ({
  type: OPEN_PROFILE_PANEL,
});

export const toggleProfilePanel = () => ({
  type: TOGGLE_PROFILE_PANEL,
});
