import { createSelector } from 'reselect';
import IState from 'services/state';
import { ISupport } from './models';
import { isFeatureEnabled } from 'services/feature-gate/selectors';
import { Feature } from 'services/feature-gate/constants';


export const getAccessInstructions = (state: IState): string => state.customerProfile.accessInstructions;
export const getSupportInfo = createSelector(
  (state: IState): ISupport => state.customerProfile.support,
  support => support,
);
export const getSiteSettingsId = (state: IState): string => state.app.site.settingsId;

export const isMissingSupportEmail = createSelector(
  getSupportInfo,
  (state: IState) => isFeatureEnabled(state, Feature.MAESTRO_SUPPORT),
  (supportInfo, isMaestroSupportEnabled) => !isMaestroSupportEnabled && !supportInfo.email,
);
