import { call, delay, takeEvery, put, fork, select } from 'redux-saga/effects';
import { SET_TIME_OFFSET } from 'services/app/actions';
import { isTimeOffsetLoaded, getServerTime } from 'services/app/selectors';
import { persistenceService } from 'services/persistence';
import { MOBILE_LANDSCAPE_MEDIA } from 'style/constants';
import { msIn } from 'shared/datetime-utils';
import { matchMedia } from 'dom-utils';

import { updateDeviceCreated, updateDimensions, updateOrientation } from './actions';
import IState from 'services/state';

export const getCreatedTime = async (serverTime: number) => {
  const key = 'mio_pct_5855';
  const persistence = persistenceService();
  const time = await persistence.read<number>(key, true);
  if (!time) {
    persistence.write(key, serverTime, true);
  }
  return time || serverTime;
};

export const pollDimensions = function* () {
  // TODO: Jankity ultra jank
  while (true) {
    yield delay(1000);
    const { innerHeight: newHeight, innerWidth: newWidth } = window;
    const isLandscape = matchMedia(MOBILE_LANDSCAPE_MEDIA).matches;
    const {
      device: {
        width: oldWidth,
        height: oldHeight,
        orientation,
      },
    } = yield select();
    if (oldWidth !== newWidth || oldHeight !== newHeight) {
      yield put(updateDimensions({ height: newHeight, width: newWidth }));
    }
    const oldIsLandscape = orientation === 'landscape';
    if (oldIsLandscape !== isLandscape) {
      yield put(updateOrientation(isLandscape));
    }
  }
};

export const createdSaga = function* () {
  const state: IState = yield select();
  const offsetLoaded = isTimeOffsetLoaded(state);
  if (!offsetLoaded) {
    return;
  }

  const serverTime = getServerTime(state);
  if (!serverTime) {
    return;
  }

  const created: number = yield call(getCreatedTime, serverTime);
  yield put(updateDeviceCreated({
    created,
    newDevice: Math.abs(serverTime - created) < msIn.day,
  }));
};

const deviceSaga = function* () {
  yield takeEvery(SET_TIME_OFFSET, createdSaga);
  yield fork(pollDimensions);
};

export default deviceSaga;
