import {
  IRange,
  Ranges,
} from './models';
export const CUSTOM_RANGE ='analytics/CUSTOM_RANGE';
export const SET_CUSTOM_RANGE_TYPE ='analytics/SET_CUSTOM_RANGE_TYPE';
export const ONEDAY_RANGE ='analytics/ONEDAY_RANGE';
export const SEVENDAY_RANGE ='analytics/SEVENDAY_RANGE';
export const THIRTYDAY_RANGE ='analytics/THIRTYDAY_RANGE';

export type IAnalyticsAction =
  ISetCustomRangeTypeAction |
  IOneDayRangeAction |
  ISevenDayRangeAction |
  IThirtyDayRangeAction |
  ICustomRangeAction;

const ONE_DAY_MS = 1000 * 60 * 60 * 24;

interface ICustomRangeAction {
  payload: IRange;
  type: typeof CUSTOM_RANGE;
}

interface IRangeType {
rangeType: string,
}

interface IDefaultRange {
rangeEnd: number,
rangeStart: number,
rangeType: string,
}


interface IOneDayRangeAction {
payload: IDefaultRange;
type: typeof ONEDAY_RANGE;
}

interface ISetCustomRangeTypeAction {
payload: IRangeType;
type: typeof SET_CUSTOM_RANGE_TYPE;
}

interface ISevenDayRangeAction {
payload: IDefaultRange;
type: typeof SEVENDAY_RANGE;
}

interface IThirtyDayRangeAction {
payload: IDefaultRange;
type: typeof THIRTYDAY_RANGE;
}

export const setCustomRange = (payload: IRange): ICustomRangeAction => ({
  payload,
  type: CUSTOM_RANGE,
});

export const setOneDayRange = (): IOneDayRangeAction => ({
  payload: { rangeStart: Date.now() - ONE_DAY_MS, rangeEnd: Date.now(), rangeType: Ranges.ONE_DAY },
  type: ONEDAY_RANGE,
});

export const setCustomRangeType = (): ISetCustomRangeTypeAction => ({
  payload: { rangeType: Ranges.CUSTOM },
  type: SET_CUSTOM_RANGE_TYPE,
});


export const setSevenDayRange = (): ISevenDayRangeAction => ({
  payload: { rangeStart: Date.now() - (ONE_DAY_MS * 7), rangeEnd: Date.now(), rangeType: Ranges.SEVEN_DAYS },
  type: SEVENDAY_RANGE,
});


export const setThirtyDayRange = (): IThirtyDayRangeAction => ({
  payload: { rangeStart: Date.now() - (ONE_DAY_MS * 30),  rangeEnd: Date.now(), rangeType: Ranges.THIRTY_DAYS },
  type: THIRTYDAY_RANGE,
});
