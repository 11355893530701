import axios, { AxiosResponse } from 'axios';
import retry from 'async-retry';
import lzstring from 'lz-string';
import {
  ACCOUNTS_SERVICE_BASE_URL,
  AMAZON_CLIENT_ID,
  AUTH_SERVICE_BASE_URL,
  BOWLTV_LOGOUT_URL,
  BOWLTV_PRODUCTION_DOMAIN,
  SITE_ID,
  UBISOFT_APP_ID,
  UBISOFT_CONNECT_SDK,
  UBISOFT_ENVIRONMENT,
  UBISOFT_GENOME_ID,
  ADMIN_V3_BASE_URL,
} from 'config';
import jwtDecode from 'jwt-decode';
import isFinite from 'lodash/isFinite';
import { persistenceService } from 'services/persistence';
import { ILoginResponse, IRegisterUserInfo, IUpdateUserInfo, OauthProvider } from './actions';
import { IAccessTokenData, ISuperAdminResponse, IAccountLookupResponse, IGlobalAccountAdminSites } from './models';
import jwt from 'jsonwebtoken';

const CONSUME_TOKEN_URL = `${AUTH_SERVICE_BASE_URL}/intent/consume`;
const EMAIL_LOGIN_URL = `${AUTH_SERVICE_BASE_URL}/login`;
const REFRESH_TOKENS_URL = `${AUTH_SERVICE_BASE_URL}/refresh`;
const EXCHANGE_CREDENTIALS_URL = `${AUTH_SERVICE_BASE_URL}/exchange`;
const LOGIN_INTENT_URL = `${AUTH_SERVICE_BASE_URL}/intent`;
const BOWLTV_ID_STORAGE_KEY = 'bowl_id_token';

export type IUbisoftResponse = null | IUbisoftPayload;
export interface IUbisoftPayload {
  expiration: string;
  sessionId: string;
  ticket: string;
  userId: string;
}

export const getUbisoftNextUrl = () => (
  `${window.location.protocol}//${window.location.host}/api/ubisoft/v1/callback`
);

export function getOauthLoginUrl(
  provider: string,
  returnUrl: string = window.location.href,
): string {
  const url = new URL(`${AUTH_SERVICE_BASE_URL}/login/oauth/link/${SITE_ID}/${provider}`);
  url.searchParams.set('return', returnUrl);
  return url.href;
}

export const getIsSuperAdmin = async ({
  accessToken,
  siteId,
}: {
  accessToken: string,
  siteId: string,
}): Promise<ISuperAdminResponse> => {
  const { data } = await axios.get<ISuperAdminResponse>(`${ADMIN_V3_BASE_URL}/superadmin`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export async function registerUserRequest(data: any, siteId: string): Promise<AxiosResponse | void> {
  const headers = { 'x-maestro-client-id': siteId };
  const registerRequest = async () => axios({
    data: { ...data, siteId },
    headers,
    method: 'post',
    url: `${ACCOUNTS_SERVICE_BASE_URL}/register`,
  });

  let response;
  await retry(async (bail) => {
    try {
      response = await registerRequest();
      return;
    } catch (err) {
      if (err?.response?.status && err.response.status < 500) {
        return bail(err);
      }
      response = err;
      throw err;
    }
  }, {
    randomize: false,
    retries: 4,
    minTimeout: 5000,
  });

  return response;
}

export async function registerInviteUser(data: IRegisterUserInfo, siteId: string): Promise<AxiosResponse | void> {
  const headers = { 'x-maestro-client-id': siteId };
  const registerRequest = async () => axios({
    data,
    headers,
    method: 'post',
    url: `${ACCOUNTS_SERVICE_BASE_URL}`,
  });

  let response;
  await retry(async (bail) => {
    try {
      response = await registerRequest();
      return;
    } catch (err) {
      if (err?.response?.status && err.response.status < 500) {
        return bail(err);
      }
      response = err;
      throw err;
    }
  }, {
    randomize: false,
    retries: 4,
    minTimeout: 5000,
  });

  return response;
}

export async function getAccount(accessToken: string, siteId: string): Promise<AxiosResponse | void> {
  const { _id: userId } = parseDataFromAccessToken(accessToken);
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'x-maestro-client-id': siteId,
  };
  const getAccountRequest = async () => axios({
    headers,
    method: 'GET',
    url: `${ACCOUNTS_SERVICE_BASE_URL}/${userId}`,
  });

  let response;

  await retry(async (bail) => {
    try {
      response = await getAccountRequest();
      return;
    } catch (err) {
      if (err?.response?.status && err.response.status < 400) {
        return bail(err);
      }
      response = err;
      throw err;
    }
  }, {
    randomize: false,
    retries: 50,
    minTimeout: 500,
  });

  return response;
}

export async function putAccount(data: IUpdateUserInfo, accessToken: string, siteId: string): Promise<AxiosResponse | void> {
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'x-maestro-client-id': siteId,
  };
  const putAccountRequest = async () => axios({
    data: { ...data, siteId },
    headers,
    method: 'PUT',
    url: `${ACCOUNTS_SERVICE_BASE_URL}`,
  });

  let response;

  await retry(async (bail) => {
    try {
      response = await putAccountRequest();
      return;
    } catch (err) {
      if (err?.response?.status && err.response.status < 400) {
        return bail(err);
      }
      response = err;
      throw err;
    }
  }, {
    randomize: false,
    retries: 50,
    minTimeout: 500,
  });

  return response;
}

// TODO: Verify at runtime (maybe using io-ts)
export function parseDataFromAccessToken(accessToken: string): IAccessTokenData {
  return jwtDecode(accessToken) as IAccessTokenData;
}

export const DEFAULT_ACCESS_TOKEN_EXPIRATION_THRESHOLD_MS = 1000 * 60; // 1 minute

export interface ILoginIntentResponse {
  authenticated: boolean;
  name?: string;
  provider: string;
  siteId: string;
  thirdPartyAccountId?: string;
  token: string;
}

export const requestUbisoftLoginIntentToken = async ({
  sessionId,
  siteId,
  ticket,
}: {
  sessionId: string;
  siteId: string;
  ticket: string;
}): Promise<ILoginIntentResponse | null> => {
  const { data } = await axios.post<ILoginIntentResponse>(LOGIN_INTENT_URL, {
    provider: 'ubisoft',
    sessionId,
    ticket,
  }, {
    headers: {
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const requestAmazonLoginIntentToken = async ({
  code,
  siteId,
}: {
  code: string;
  siteId: string;
}): Promise<ILoginIntentResponse | null> => {
  const { data } = await axios.post<ILoginIntentResponse>(LOGIN_INTENT_URL, {
    provider: 'amazon',
    code,
  }, {
    headers: {
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const requestExternaLoginIntentToken = async ({
  service,
  thirdPartyAccountId,
  name,
  email,
  jwt: jwtToken,
  provider,
  siteId = SITE_ID,
  verificationToken,
}: {
  email?: string;
  jwt: string;
  name?: string;
  provider: string;
  service: string;
  siteId: string;
  thirdPartyAccountId?: string;
  verificationToken?: string;
}) => {
  const { data } = await axios.post<ILoginIntentResponse>(LOGIN_INTENT_URL, {
    service,
    thirdPartyAccountId,
    name,
    email,
    provider,
    jwt: jwtToken,
    verificationToken,
  }, {
    headers: {
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const registerInviteIntent = async ({
  email,
  inviteId,
  provider,
  service,
  siteId,
}: {
  email: string;
  inviteId: string;
  provider: string;
  service: string;
  siteId: string;
}): Promise<any> => (
  axios({
    headers: {
      'x-maestro-client-id': siteId,
    },
    method: 'POST',
    url: LOGIN_INTENT_URL,
    data: {
      email,
      provider,
      service,
      inviteId,
    },
  }));

export function isAccessTokenDataNearExpiring(
  accessTokenData: IAccessTokenData,
  thresholdMs = DEFAULT_ACCESS_TOKEN_EXPIRATION_THRESHOLD_MS,
): boolean {
  const { exp } = accessTokenData;
  if (!isFinite(exp)) {
    // tslint:disable-next-line no-console
    console.warn(`Invalid access token (bad exp): ${JSON.stringify(accessTokenData, null, 2)}`);
    return false;
  }
  const expireTimestamp = accessTokenData.exp * 1000;
  return expireTimestamp - thresholdMs <= Date.now();
}

export function fetchCredentialsFromExchangeToken(exchangeToken: string): Promise<ILoginResponse> {
  return axios({
    data: {
      token: exchangeToken,
    },
    method: 'post',
    url: EXCHANGE_CREDENTIALS_URL,
  }).then((response) => response.data);
}

interface IEmailCredentials {
  deviceId: string;
  email: string;
  inviteId?: string;
  password: string;
  provider?: string;
}

interface ILoginWithToken {
  deviceId: string;
  siteId: string;
  token: string;
}

const ACCESS_TOKEN_STORAGE_KEY = 'authData.v3.accessToken.v1';
const SERVICE_STORAGE_KEY = 'authData.v3.service';
const REFRESH_TOKEN_STORAGE_KEY = 'authData.v3.refreshToken';

export const logInWithToken = async ({ siteId, token, deviceId }: ILoginWithToken): Promise<ILoginResponse> => {
  const { data } = await axios.get(`${CONSUME_TOKEN_URL}/${token}`, {
    headers: {
      'x-maestro-client-id': siteId,
      'x-maestro-device-id': deviceId,
    },
  });
  return data;
};

export async function logInWithEmail({ email, password, deviceId, inviteId, provider }: IEmailCredentials): Promise<ILoginResponse | void> {
  const loginRequest = async () => await axios({
    data: {
      email,
      password,
      siteId: SITE_ID,
      inviteId: inviteId ? inviteId : undefined,
      provider: provider ? provider : undefined,
    },
    headers: {
      'x-maestro-device-id': deviceId,
    },
    method: 'POST',
    url: EMAIL_LOGIN_URL,
  });

  const response = await retry(async (bail) => {
    try {
      return await loginRequest();
    } catch (err) {
      if (err?.response?.status && err.response.status < 500) {
        return bail(err);
      }
      throw err;
    }
  }, {
    randomize: false,
    retries: 4,
    minTimeout: 5000,
  });

  if (response && response?.data) { return response.data; }

  throw new Error('Login failed');
}

export function sendSentinelPing(battleNetId: string): Promise<AxiosResponse> {
  return axios.post(
    'https://sentinel.majorleaguegaming.com/api/v1/viewers/owl', {
    battleNetId,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  },
  ).then((resp) => {
    return resp;
  });
}

interface INewTokensApiResponse {
  jwt: string;
  refreshToken: string;
}

export interface INewTokens {
  accessToken: string;
  previousRefreshToken: string;
  refreshToken: string;
}

interface IFetchNewTokensArgs {
  accessToken: string;
  deviceId: string;
  refreshToken: string;
}

// NOTE: THIS INVALIDATES THE ORIGINAL REFRESH TOKEN
export function fetchNewTokens(args: IFetchNewTokensArgs): Promise<INewTokens> {
  return axios({
    data: {
      refreshToken: args.refreshToken,
      deviceId: args.deviceId,
    },
    headers: {
      Authorization: `Bearer ${args.accessToken}`,
    },
    method: 'POST',
    url: REFRESH_TOKENS_URL,
  }).then((response: AxiosResponse<INewTokensApiResponse>) => {
    const { data } = response;
    return {
      accessToken: data.jwt,
      previousRefreshToken: args.refreshToken,
      refreshToken: data.refreshToken,
    };
  });
}

export function redirectTheWholeDangWindow({
  url,
  redirectRootWindow,
}: { redirectRootWindow?: boolean; url: any; }): void {
  const target = redirectRootWindow ? window.top : window;
  target!.location.href = url;
}

export function redirectToOauthLogin(
  provider: OauthProvider,
  { postAuthRedirect, redirectRootWindow, returnUrlOverride }:
    { postAuthRedirect?: string, redirectRootWindow?: boolean, returnUrlOverride?: string } = {},
): void {
  const url = new URL(getOauthLoginUrl(provider, returnUrlOverride));
  if (postAuthRedirect) {
    url.searchParams.set('post_auth_redirect', postAuthRedirect);
  }
  const embedded = window !== window.top;
  if (embedded && redirectRootWindow) {
    const message = {
      action: 'loginRedirect',
      url: url.href,
    };
    window.top!.postMessage(message, '*');
  }
  redirectTheWholeDangWindow({ url: url.href, redirectRootWindow });
}

export async function getStoredAccessToken(): Promise<string | null> {
  const accessToken = await persistenceService().read<string>(ACCESS_TOKEN_STORAGE_KEY);
  return accessToken;
}

export function setStoredAccessToken(accessToken: string): void {
  persistenceService().write(ACCESS_TOKEN_STORAGE_KEY, accessToken);
}

export async function getStoredRefreshToken(): Promise<string | null> {
  const refreshToken = await persistenceService().read<string>(REFRESH_TOKEN_STORAGE_KEY);
  return refreshToken;
}

export function setStoredRefreshToken(refreshToken: string): void {
  persistenceService().write(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
}

export async function getStoredService(): Promise<string | null> {
  const service = await persistenceService().read<string>(SERVICE_STORAGE_KEY);
  return service;
}

export function setStoredService(service: string): void {
  persistenceService().write(SERVICE_STORAGE_KEY, service);
}

export function setStoredBowlTvId(id: string): void {
  persistenceService().write(BOWLTV_ID_STORAGE_KEY, id);
}

export function clearStoredBowlTvId(): void {
  persistenceService().remove(BOWLTV_ID_STORAGE_KEY);
}

export async function getStoredBowlTvId(): Promise<string | null> {
  const bowlTvId = await persistenceService().read<string>(BOWLTV_ID_STORAGE_KEY);
  return bowlTvId;
}

export const bowltvLogoutRedirect = async () => {
  const idToken = await getStoredBowlTvId();
  const redirect = encodeURIComponent(BOWLTV_PRODUCTION_DOMAIN);
  const url = `${BOWLTV_LOGOUT_URL}?id_token_hint=${idToken}&post_logout_redirect_uri=${redirect}`;
  clearStoredBowlTvId();
  window.location.href = url;
};

export function clearStoredAuthData(): void {
  const keys = [
    ACCESS_TOKEN_STORAGE_KEY,
    SERVICE_STORAGE_KEY,
    REFRESH_TOKEN_STORAGE_KEY,
  ];
  const persistence = persistenceService();
  keys.forEach((key) => {
    persistence.remove(key);
  });
}

export const loadUbisoftSdk = () => new Promise<void>((resolve) => {
  let count = 0;
  const waitForLoad = () => {
    count++;
    if (count > 30) {
      // tslint:disable-next-line no-console
      console.error(`timeout waiting for ubisoft sdk to load`);
      resolve();
      return;
      // @ts-ignore
    } else if (!window.Connect) {
      setTimeout(waitForLoad, 100);
      return;
    }
    resolve();
  };
  // stolen from socials/api
  ((d: Document, s: string, id: string) => {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    const js = d.createElement(s); js.id = id;
    // @ts-ignore
    js.src = UBISOFT_CONNECT_SDK;
    // @ts-ignore
    fjs.parentNode.insertBefore(js, fjs);
    waitForLoad();
  })(document, 'script', 'ubisoft-connect-sdk');
});

let ubisoftSdk: any = null;
const initUbisoftSdk = () => new Promise((resolve) => {
  // @ts-ignore
  if (!window.Connect) {
    // tslint:disable-next-line no-console
    console.error('no connect loaded');
    resolve(null);
    return;
  } else if (ubisoftSdk) {
    resolve(ubisoftSdk);
    return;
  } else {
    // @ts-ignore
    Connect.init({
      appId: UBISOFT_APP_ID,
      env: UBISOFT_ENVIRONMENT,
      genomeId: UBISOFT_GENOME_ID,
      lang: 'en-US',
      nextUrl: encodeURIComponent(getUbisoftNextUrl()),
      thirdPartyCookiesSupport: false,
    });
    // @ts-ignore
    Connect.sdk.subscribe((sdk) => {
      // @ts-ignore
      ubisoftSdk = sdk;
      resolve(sdk);
    });
  }
});

interface IGetTicketResp {
  payload: IUbisoftPayload,
}

interface IGetTicketError {
  errorMessage: string;
  opId: string;
  status: 'error';
}

const isGetTicketResp = (obj: any): obj is IGetTicketResp => {
  return typeof obj === 'object' && typeof obj.payload === 'object';
};

const isGetTicketError = (obj: any): obj is IGetTicketError => {
  return typeof obj === 'object' && obj.status === 'error';
};

type IGetTicket = IGetTicketResp | IGetTicketError | null;

export const getAmazonCode = (siteId: string): Promise<string | null> => new Promise((resolve) => {
  const options: any = {} ;
  options.popup = false;
  options.scope = 'profile collect:sandbox:all collect::benefit:redeem';
  options.response_type = 'code';
  options.state = lzstring.compressToEncodedURIComponent(JSON.stringify(
    {
      service: 'amazon',
      siteId,
      redirect_uri: `${AUTH_SERVICE_BASE_URL}/login/oauth`,
      return: window.location.href,
    },
  ));
  options.redirect_uri= window.location.href;
  // @ts-ignore
  amazon.Login.authorize(options, `${AUTH_SERVICE_BASE_URL}/login/oauth`);
});

export const getUbisoftTicket = (): Promise<IUbisoftResponse> => new Promise((resolve) => {
  initUbisoftSdk().then((sdk) => {
    if (!sdk) {
      resolve(null);
      return;
    }
    // @ts-ignore
    sdk.getTicket().subscribe((resp: IGetTicket) => {
      if (isGetTicketResp(resp)) {
        resolve(resp.payload);
      } else {
        resolve(null);
      }
    });
  });
});

export const logoutUbisoftSdk = () => new Promise<void | null>((resolve) => {
  initUbisoftSdk().then((sdk) => {
    if (!sdk) {
      resolve(null);
      return;
    }
    // @ts-ignore
    sdk.getTicket().subscribe((resp: IGetTicket) => {
      if (!resp || isGetTicketError(resp)) {
        resolve();
        return;
      }
      // @ts-ignore
      sdk.logout(resp.payload.ticket, resp.payload.sessionId).subscribe((resp2) => {
        resolve();
      });
    });
  });
});

export const performAccountLookup = async ({
  email,
  siteId = SITE_ID,
  token,
}: {
  email: string;
  siteId: string;
  token: string;
}) => {
  const { data } = await axios.get<IAccountLookupResponse>(`${ACCOUNTS_SERVICE_BASE_URL}/${email}?type=email&token=${token}`, {
    headers: {
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const loadAmazon = () => {
  window.onAmazonLoginReady = () => {
    // @ts-ignore
    amazon.Login.setClientId(AMAZON_CLIENT_ID);
  };
  ((d) => {
    const a = d.createElement('script'); a.type = 'text/javascript';
    a.async = true; a.id = 'amazon-login-sdk';
    a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
     d.getElementById('amazon-root')?.appendChild(a);
  })(document);
};



export interface IFetchGlobalAccountsRequest {
  accessToken: string;
  siteId: string;
}

export interface ISwitchAccessTokenRequest {
  accessToken: string;
  fromSiteId: string;
  toSiteId: string;
}

export async function fetchGlobalAccounts({
  accessToken,
  siteId,
}: IFetchGlobalAccountsRequest): Promise<IGlobalAccountAdminSites[]> {
  const { data } = await axios.get<IGlobalAccountAdminSites[]>(
    `${ACCOUNTS_SERVICE_BASE_URL}/global/accounts`,
    {
      headers: {
        'x-maestro-client-id': siteId,
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  // filter out any objects that do not have the required properties
  const filteredData = data.filter(siteObject => (
    'accountId' in siteObject &&
    'siteId' in siteObject &&
    'slug' in siteObject
  ));

  return filteredData;
}

export async function switchAccessToken({
  accessToken,
  fromSiteId,
  toSiteId,
}: ISwitchAccessTokenRequest): Promise<ILoginResponse> {
  const { data } = await axios.post(
    `${AUTH_SERVICE_BASE_URL}/login/switch?siteId=${toSiteId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'x-maestro-client-id': fromSiteId,
      },
    },
  );
  return data;
}

interface ICreateJwtToken {
  options?: jwt.SignOptions | undefined;
  payload: string | object | Buffer;
  secretOrPrivateKey: jwt.Secret;
}

export const createJwtToken = ({
  payload,
  secretOrPrivateKey,
  options,
}: ICreateJwtToken): string => {
  return jwt.sign(payload, secretOrPrivateKey, options);
};
