import { EMPTY_ARRAY } from 'services/utils';
import { createObjectId } from 'utils';
import { typeCheckSet } from 'shared/type-utils';
import cloneDeep from 'lodash/cloneDeep';
import {
  Payload,
  MessagePayload,
  SEND_LOWP_NOTIFICATION,
  SEND_MEDIUMP_NOTIFICATION,
  SEND_HIGHP_NOTIFICATION,
  SEND_CUSTOM_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
} from './actions';
import { DEFAULT_NOTIFICATION_MAP } from './constants';
import {
  Notification,
  AdminNotificationsState,
  NotificationQuery,
  AdminNotificationPriority,
  NotificationWithId,
} from './models';

const INITIAL_STATE: AdminNotificationsState = {
  low: EMPTY_ARRAY,
  medium: EMPTY_ARRAY,
  high: EMPTY_ARRAY,
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

export default function adminNotificationsReducer(
  state = INITIAL_STATE,
  { payload, type }: { payload?: Payload, type: string },
): AdminNotificationsState {
  switch (type) {
    case SEND_LOWP_NOTIFICATION:
    case SEND_MEDIUMP_NOTIFICATION:
    case SEND_HIGHP_NOTIFICATION: {
      const PriorityMap: Record<typeof type, AdminNotificationPriority> = {
        [SEND_LOWP_NOTIFICATION]: 'low',
        [SEND_MEDIUMP_NOTIFICATION]: 'medium',
        [SEND_HIGHP_NOTIFICATION]: 'high',
      };
      const priority = PriorityMap[type];
      const msgPayload = payload as MessagePayload;

      const notification = cloneDeep(DEFAULT_NOTIFICATION_MAP[priority]);

      const newNotification: NotificationWithId = {
        ...notification,
        id: createObjectId(),
      };

      const buttons = msgPayload.buttons?.map(buttonStyle => ({
        ...newNotification.buttons[0],
        ...buttonStyle,
      }));
      if (buttons) {
        newNotification.buttons = buttons;
      }
      typeCheckSet('string', msgPayload, 'notificationText', newNotification, 'message.text');
      typeCheckSet('string', msgPayload, 'notificationTextKey', newNotification, 'message.textKey');
      typeCheckSet('function', msgPayload, 'action', newNotification);

      return {
        ...state,
        [priority]: [
          ...state[priority],
          newNotification,
        ],
      };
    }

    case SEND_CUSTOM_NOTIFICATION: {
      const defaultNotification = cloneDeep(DEFAULT_NOTIFICATION_MAP.custom);
      const { priority, ...notification } = payload as Notification;

      if (!(priority === 'high' || priority === 'medium' || priority === 'low')) {
        return state;
      }

      const newNotification: NotificationWithId = {
        ...defaultNotification,
        id: createObjectId(),
        priority,
      };

      if (notification.buttons?.length) {
        newNotification.buttons = notification.buttons.map(buttonStyle => ({
          ...newNotification.buttons[0],
          ...buttonStyle,
        }));
      }
      typeCheckSet('string', notification, 'message.background', newNotification);
      typeCheckSet('string', notification, 'message.text', newNotification);
      typeCheckSet('string', notification, 'message.textColor', newNotification);
      typeCheckSet('string', notification, 'message.textKey', newNotification);

      typeCheckSet('function', notification, 'action', newNotification);
      typeCheckSet('string', notification, 'borderColor', newNotification);
      typeCheckSet('string', notification, 'icon.name', newNotification);
      typeCheckSet('string', notification, 'icon.color', newNotification);

      return {
        ...state,
        [priority]: [
          ...state[priority],
          newNotification,
        ],
      };
    }

    case REMOVE_NOTIFICATION: {
      const { id, priority } = payload as NotificationQuery;
      const filtered = state[priority].filter(({ id: currentId }) => currentId !== id);

      return {
        ...state,
        [priority]: filtered,
      };
    }

    case CLEAR_NOTIFICATIONS: {
      return getInitialState();
    }

    default: {
      return state;
    }
  }
}
