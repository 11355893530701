import axios from 'axios';
import invariant from 'invariant';
import { ACTIVE_USER_SERVICE_URL } from 'config';
import axiosRetry from 'axios-retry';
import { BEACON_TIMEOUT_SECONDS } from './constants';

const axiosInstance = axios.create({
  method: 'POST',
  timeout: BEACON_TIMEOUT_SECONDS * 1000,
  url: ACTIVE_USER_SERVICE_URL,
});

axiosRetry(axiosInstance, {
  retries: 2,
  retryCondition: (error) => {
    const status = error.response?.status;
    return [400, 502].includes(status);
  },
});

export const sendBeacon = ({
  accountId,
  channelId,
  deviceId,
  sessionId,
  siteId,
}) => {
  invariant(
    siteId && typeof siteId === 'string',
    `Missing/invalid siteId: ${siteId}`,
  );
  invariant(
    channelId && typeof channelId === 'string',
    `Missing/invalid channelId: ${channelId}`,
  );
  invariant(
    sessionId && typeof sessionId === 'string',
    `Missing/invalid sessionId: ${sessionId}`,
  );
  invariant(
    deviceId && typeof deviceId === 'string',
    `Missing/invalid deviceId: ${deviceId}`,
  );
  invariant(
    accountId === null || (accountId && typeof accountId === 'string'),
    `Missing/invalid accountId: ${accountId}`,
  );
  const postBody = {
    accountId,
    channelId,
    deviceId,
    sessionId,
    siteId,
  };
  return axiosInstance.request({
    data: postBody,
  });
};
