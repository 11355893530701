import IVideo, { DEFAULT_VIDEO_PLAYER } from './IVideo';
import shortid from 'shortid';
import { IScheduleItem } from 'models/IScheduleItem';

export class VideoModel implements IVideo {
  durationSeconds!: IVideo['durationSeconds'];
  id!: IVideo['_id'];
  player!: IVideo['player'];
  siteId!: IVideo['siteId'];
  subscriptions!: IVideo['subscriptions'];
  thumbnail!: IVideo['thumbnail'];
  title!: IVideo['title'];
  type!: IVideo['type'];
  url!: IVideo['url'];

  constructor(dto: IVideo) {
    this.id = dto._id;
    this.durationSeconds = dto.durationSeconds || 0;
    this.player = dto.player || DEFAULT_VIDEO_PLAYER;
    this.siteId = dto.siteId;
    this.type = dto.type;
    this.title = dto.title;
    this.url = dto.url;
  }

  static toScheduleItem = (video: Required<IVideo>): IScheduleItem => {
    return {
      arrayId: shortid.generate(),
      duration: video.durationSeconds >= 60 ? video.durationSeconds : 60,
      kind: 'video',
      repeat: 'none',
      showCountdown: false,
      syncStartTime: true,
      video: {
        _id: video._id,
        data: {
          name: video.title,
        },
      },
    };
  }

}
