import IState from 'services/state';
import clamp from 'lodash/clamp';
import { createSelector } from 'reselect';
import { getAdminBarWidth } from 'services/admin/selectors';
import { isUserAdmin } from 'services/auth/selectors/common';
import { hasHeader } from 'services/app/selectors';
import { isVideoEmbed } from 'services/user-layout/selectors/common';
import {
  SIDEBAR_WIDTH_PX,
  SIDEBAR_EXTENDED_WIDTH_PX,
  HEADER_HEIGHT,
  MOBILE_MAX_WIDTH_PX,
} from 'style/constants';
import { IDeviceState } from '../reducer';

export const getWidth = (state: IState) => state.device.width;

export const getCalculatedVideoHeight = createSelector(
  [
    ({ device }: IState): number => device.height,
    ({ device }: IState): number => device.width,
    (state: IState) => hasHeader(state),
    (state: IState) => isUserAdmin(state),
    ({ userLayout }: IState) => userLayout.viewMode === 'theater',
    (state: IState) => isVideoEmbed(state),
  ],
  (deviceHeight, deviceWidth, headerVisible, isAdmin, theaterActive, videoEmbed) => {
    if (deviceWidth <= MOBILE_MAX_WIDTH_PX) {
      return deviceWidth * 9 / 16;
    }

    let videoHeight = deviceHeight - 90;

    if (videoEmbed) {
      videoHeight += 90;
    }

    if (headerVisible) {
      videoHeight -= HEADER_HEIGHT;
    }

    if (isAdmin) {
      videoHeight -= 52;
    }

    if (theaterActive) {
      return videoHeight - 15;
    }

    return videoHeight;
  },
);

export const getCalculatedVideoWidth = createSelector(
  [getCalculatedVideoHeight],
  (videoHeight) => videoHeight * 16 / 9,
);

export const getInitialSidebarWidth = createSelector(
  getWidth,
  (state: IState) => getAdminBarWidth(state),
  getCalculatedVideoWidth,
  (width, adminbarWidth, videoWidth) => {
    if (!videoWidth) {
      return SIDEBAR_WIDTH_PX;
    }

    return clamp(
      width - videoWidth - adminbarWidth - 41,
      SIDEBAR_WIDTH_PX,
      SIDEBAR_EXTENDED_WIDTH_PX,
    );
  },
);
