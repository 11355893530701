import { PaymentMethod } from '@stripe/stripe-js';
import {
  ISubmitPaymentPayload,
  ISubmitPaymentMethodPayload,
  IPurchasePlanPayload,
  IWallet,
  IBillingInfo,
  ITaxRate,
  ICancelSubscription,
  IEditPaymentMethodPayload,
  IBillingInfoErrorInput,
  IContract,
  IDeleteSourcePayload,
  RegionRenderingStripeCreditCardEnum,
  ISetEntitlementDataPayload,
  IUpgradePlanPayload,
} from './models';
import Plan from 'models/IPlan';

export const COMPLETE_PAYMENT_PROCESS = 'billing/COMPLETE_PAYMENT_PROCESS';
export const ADD_REGION_RENDERING_STRIPE_CREDIT_CARD = 'billing/ADD_REGION_RENDERING_STRIPE_CREDIT_CARD';
export const REMOVE_REGION_RENDERING_STRIPE_CREDIT_CARD = 'billing/REMOVE_REGION_RENDERING_STRIPE_CREDIT_CARD';
export const SUBMIT_PAYMENT = 'billing/SUBMIT_PAYMENT';
export const SUBMIT_PAYMENT_METHOD = 'billing/SUBMIT_PAYMENT_METHOD';
export const SET_PAYMENT_METHODS = 'billing/SET_PAYMENT_METHODS';
export const EDIT_PAYMENT_METHOD = 'billing/EDIT_PAYMENT_METHOD';
export const COMPLETE_EDIT_PAYMENT_METHOD = 'billing/COMPLETE_EDIT_PAYMENT_METHOD';
export const SET_DEFAULT_PAYMENT_METHOD = 'billing/SET_DEFAULT_PAYMENT_METHOD';
export const SUBMIT_DEFAULT_PAYMENT_METHOD = 'billing/SUBMIT_DEFAULT_PAYMENT_METHOD';
export const UPDATE_WALLET = 'billing/UPDATE_WALLET';
export const RESET_BILLING_REDUCER = 'billing/RESET_BILLING_REDUCER';
export const ADD_PAYMENT_METHOD = 'billing/ADD_PAYMENT_METHOD';
export const REMOVE_PAYMENT = 'billing/REMOVE_PAYMENT';
export const REMOVE_PAYMENT_CONFIRM = 'billing/REMOVE_PAYMENT_CONFIRM';
export const STOP_PAYMENTS_LOADING = 'billing/STOP_PAYMENT_LOADING';
export const SET_TAX_RATE = 'billing/SET_TAX_RATE';
export const SET_BILLING_INFO = 'billing/SET_BILLING_INFO';
export const SET_BILLING_POSTAL_CODE = 'billing/SET_BILLING_POSTAL_CODE';
export const SET_BILLING_COUNTRY_CODE = 'billing/SET_BILLING_COUNTRY_CODE';
export const SET_BILLING_NAME = 'billing/SET_BILLING_NAME';
export const SET_BILLING_NAME_ERROR = 'billing/SET_BILLING_NAME_ERROR';
export const SET_BILLING_STATE_CODE = 'billing/SET_BILLING_STATE_CODE';
export const SET_BILLING_INFO_ERROR = 'billing/SET_BILLING_INFO_ERROR';
export const SET_BILLING_INFO_COMPLETED = 'billing/SET_BILLING_INFO_COMPLETED';
export const CLEAR_BILLING_INFO = 'billing/CLEAR_BILLING_INFO';
export const CLEAR_ZIPCODE = 'billing/CLEAR_ZIPCODE';
export const FETCH_TAX_RATE = 'billing/FETCH_TAX_RATE';
export const SET_ENTITLEMENT_DATA = 'billing/SET_ENTITLEMENT_DATA';
export const WATCH_ENTITLEMENT_ACCESS = 'billing/WATCH_ENTITLEMENT_ACCESS';
export const CHECK_APPLE_PAY_VERIFY = 'billing/CHECK_APPLE_PAY_VERIFY';
export const CANCEL_SUBSCRIPTION = 'billing/CANCEL_SUBSCRIPTION';
export const SET_ENTITLEMENT_LOADING = 'billing/SET_ENTITLEMENT_LOADING';
export const GET_WALLET_DATA = 'billing/GET_PAYMENTS_DATA';
export const UPDATE_VIEWER_SERVICE_CHARGE_RATE = 'billing/UPDATE_VIEWER_SERVICE_CHARGE_RATE';
export const GET_CONTRACT_DATA = 'billing/GET_CONTRACT_DATA';
export const UPDATE_PLAN_DATA = 'billing/UPDATE_PLAN_DATA';
export const UPDATE_CONTRACT_DATA = 'billing/UPDATE_CONTRACT_DATA';
export const DELETE_SOURCE = 'billing/DELETE_SOURCE';
export const DELETE_SOURCE_COMPLETE = 'billing/DELETE_SOURCE_COMPLETE';
export const PURCHASE_PLAN = 'billing/PURCHASE_PLAN';
export const PURCHASE_PLAN_COMPLETE = 'billing/PURCHASE_PLAN_COMPLETE';
export const PURCHASE_PLAN_ERROR = 'billing/PURCHASE_PLAN_ERROR';
export const SET_NATIVE_CURRENCY = 'billing/SET_NATIVE_CURRENCY';
export const UPGRADE_PLAN = 'billing/UPGRADE_PLAN';
export const UPGRADE_PLAN_COMPLETE = 'billing/UPGRADE_PLAN_COMPLETE';
export const UPGRADE_PLAN_ERROR = 'billing/UPGRADE_PLAN_ERROR';

export interface ISetEntitlementDataAction {
  payload: ISetEntitlementDataPayload;
  type: typeof SET_ENTITLEMENT_DATA;
}

export interface IFetchTaxRateAction {
  payload?: PaymentMethod;
  type: typeof FETCH_TAX_RATE;
}

export interface ISetTaxRateAction {
  payload: ITaxRate;
  type: typeof SET_TAX_RATE;
}

export interface ISetBillingInfoAction {
  payload: IBillingInfo;
  type: typeof SET_BILLING_INFO;
}

export interface ISetBillingPostalCodeAction {
  payload: string;
  type: typeof SET_BILLING_POSTAL_CODE;
}

export interface ISetBillingCountryCodeAction {
  payload: string;
  type: typeof SET_BILLING_COUNTRY_CODE;
}

export interface ISetBillingNameAction {
  payload: string,
  type: typeof SET_BILLING_NAME;
}

export interface ISetBillingNameErrorAction {
  payload: IBillingInfoErrorInput;
  type: typeof SET_BILLING_NAME_ERROR;
}

export interface ISetBillingStateCodeAction {
  payload: string,
  type: typeof SET_BILLING_STATE_CODE;
}

export interface ISetBillingInfoErrorAction {
  payload: string,
  type: typeof SET_BILLING_INFO_ERROR;
}

export interface ISetBillingInfoCompletedAction {
  payload: boolean,
  type: typeof SET_BILLING_INFO_COMPLETED;
}

export interface IClearBillingInfoAction {
  type: typeof CLEAR_BILLING_INFO;
}

export interface IClearZipcodeAction {
  type: typeof CLEAR_ZIPCODE;
}

export interface ICompletePaymentProcess {
  type: typeof COMPLETE_PAYMENT_PROCESS;
}

export interface IAddRegionRenderingStripeCreditCardAction {
  payload: RegionRenderingStripeCreditCardEnum;
  type: typeof ADD_REGION_RENDERING_STRIPE_CREDIT_CARD;
}

export interface IRemoveRegionRenderingStripeCreditCardAction {
  payload: RegionRenderingStripeCreditCardEnum;
  type: typeof REMOVE_REGION_RENDERING_STRIPE_CREDIT_CARD;
}

export interface ISubmitPaymentAction {
  payload: ISubmitPaymentPayload;
  type: typeof SUBMIT_PAYMENT;
}

export interface ISubmitPaymentMethodAction {
  payload: ISubmitPaymentMethodPayload;
  type: typeof SUBMIT_PAYMENT_METHOD;
}

export interface ICompleteEditPaymentMethodAction {
  type: typeof COMPLETE_EDIT_PAYMENT_METHOD;
}

export interface ISetDefaultPaymentMethodAction {
  payload: string;
  type: typeof SET_DEFAULT_PAYMENT_METHOD;
}

export interface ISubmitDefaultPaymentMethodAction {
  payload: string;
  type: typeof SUBMIT_DEFAULT_PAYMENT_METHOD;
}

interface ISetPaymentMethodsAction {
  payload: PaymentMethod[];
  type: typeof SET_PAYMENT_METHODS;
}

interface IUpdateWalletAction {
  payload: IWallet;
  type: typeof UPDATE_WALLET;
}

interface IResetBillingReducerAction {
  type: typeof RESET_BILLING_REDUCER;
}

interface IAddPaymentMethodAction {
  payload: PaymentMethod;
  type: typeof ADD_PAYMENT_METHOD;
}

export interface IWatchEntitlementAccessAction {
  payload: string;
  type: typeof WATCH_ENTITLEMENT_ACCESS;
}

export interface IRemovePaymentAction {
  payload: string;
  type: typeof REMOVE_PAYMENT;
}

export interface IRemovePaymentConfirmAction {
  payload: {
    id: string | null,
    removeFunction: () => void,
  };
  type: typeof REMOVE_PAYMENT_CONFIRM;
}

export interface IStopPaymentsLoading {
  type: typeof STOP_PAYMENTS_LOADING;
}

export type ICreditCardFormAction = (
  typeof submitPayment | typeof submitPaymentMethod
);

export type ICancelSubscriptionAction = {
  payload: ICancelSubscription
  type: typeof cancelSubscription;
};

export type ISetEntitlementLoadingAction = {
  payload: string;
  type: typeof SET_ENTITLEMENT_LOADING;
};

export type IEditPaymentMethodAction = {
  payload: IEditPaymentMethodPayload;
  type: typeof EDIT_PAYMENT_METHOD;
};

export interface IGetWalletData {
  type: typeof GET_WALLET_DATA;
}

export interface IUpdateViewerServiceChargeRate {
  payload: number;
  type: typeof UPDATE_VIEWER_SERVICE_CHARGE_RATE;
}

export interface IGetContractData {
  type: typeof GET_CONTRACT_DATA;
}

export interface IUpdatePlanData {
  payload: Plan,
  type: typeof UPDATE_PLAN_DATA;
}

export interface IUpdateContractData {
  payload: IContract,
  type: typeof UPDATE_CONTRACT_DATA;
}

export interface IDeleteSourceAction {
  payload: IDeleteSourcePayload;
  type: typeof DELETE_SOURCE;
}

export interface IDeleteSourceCompleteAction {
  payload: string;
  type: typeof DELETE_SOURCE_COMPLETE;
}

export interface IPurchasePlanAction {
  payload: IPurchasePlanPayload;
  type: typeof PURCHASE_PLAN;
}

export interface IPurchasePlanCompleteAction {
  payload: IContract;
  type: typeof PURCHASE_PLAN_COMPLETE;
}
export interface IPurchasePlanErrorAction {
  payload: string;
  type: typeof PURCHASE_PLAN_ERROR;
}

export interface ISetNativeCurrencyAction {
  payload: string | null;
  type: typeof SET_NATIVE_CURRENCY;
}

export interface IUpgradePlanAction {
  payload: IUpgradePlanPayload;
  type: typeof UPGRADE_PLAN;
}

export interface IUpgradePlanCompleteAction {
  payload: IContract;
  type: typeof UPGRADE_PLAN_COMPLETE;
}

export interface IUpgradePlanErrorAction {
  payload: string;
  type: typeof UPGRADE_PLAN_ERROR;
}

export type IBillingAction = (
  IEditPaymentMethodAction |
  ICompleteEditPaymentMethodAction |
  ISetEntitlementDataAction |
  ISetTaxRateAction |
  ISetBillingInfoAction |
  ISetBillingPostalCodeAction |
  ISetBillingCountryCodeAction |
  ISetBillingStateCodeAction |
  ISetBillingNameErrorAction |
  ISetBillingNameAction |
  ISetBillingInfoErrorAction |
  ISetBillingInfoCompletedAction |
  IClearBillingInfoAction |
  IClearZipcodeAction |
  IFetchTaxRateAction |
  ICompletePaymentProcess |
  ISubmitPaymentAction |
  IAddPaymentMethodAction |
  IAddRegionRenderingStripeCreditCardAction |
  IRemoveRegionRenderingStripeCreditCardAction |
  ISubmitPaymentMethodAction |
  ISubmitDefaultPaymentMethodAction |
  ISetDefaultPaymentMethodAction |
  ISetPaymentMethodsAction |
  IUpdateWalletAction |
  IRemovePaymentAction |
  IRemovePaymentConfirmAction |
  IStopPaymentsLoading |
  IResetBillingReducerAction |
  IWatchEntitlementAccessAction |
  ICancelSubscriptionAction |
  ISetEntitlementLoadingAction |
  IGetWalletData |
  IUpdateViewerServiceChargeRate |
  IGetContractData |
  IUpdateContractData |
  IUpdatePlanData |
  IDeleteSourceAction |
  IDeleteSourceCompleteAction |
  IPurchasePlanAction |
  IPurchasePlanCompleteAction |
  IPurchasePlanErrorAction |
  ISetNativeCurrencyAction |
  IUpgradePlanAction |
  IUpgradePlanCompleteAction |
  IUpgradePlanErrorAction
);

export const setEntitlementData = (payload: ISetEntitlementDataPayload): ISetEntitlementDataAction => {
  return {
    payload,
    type: SET_ENTITLEMENT_DATA,
  };
};

export const setTaxRate = (payload: ITaxRate): ISetTaxRateAction => {
  return {
    payload,
    type: SET_TAX_RATE,
  };
};

export const setBillingInfo = (payload: IBillingInfo): ISetBillingInfoAction => {
  return {
    payload,
    type: SET_BILLING_INFO,
  };
};

export const setBillingCountryCode = (payload: string): ISetBillingCountryCodeAction => {
  return {
    payload,
    type: SET_BILLING_COUNTRY_CODE,
  };
};

export const setBillingStateCode = (payload: string): ISetBillingStateCodeAction => {
  return {
    payload,
    type: SET_BILLING_STATE_CODE,
  };
};

export const setBillingName = (payload: string): ISetBillingNameAction => {
  return {
    payload,
    type: SET_BILLING_NAME,
  };
};

export const setBillingNameError = (payload: IBillingInfoErrorInput): ISetBillingNameErrorAction => {
  return {
    payload,
    type: SET_BILLING_NAME_ERROR,
  };
};

export const setBillingPostalCode = (payload: string): ISetBillingPostalCodeAction => {
  return {
    payload,
    type: SET_BILLING_POSTAL_CODE,
  };
};

export const setBillingInfoError = (payload: string): ISetBillingInfoErrorAction => {
  return {
    payload,
    type: SET_BILLING_INFO_ERROR,
  };
};

export const setBillingInfoCompleted = (payload: boolean): ISetBillingInfoCompletedAction => {
  return {
    payload,
    type: SET_BILLING_INFO_COMPLETED,
  };
};

export const clearBillingInfo = (): IClearBillingInfoAction => {
  return {
    type: CLEAR_BILLING_INFO,
  };
};

export const clearZipcode = (): IClearZipcodeAction => {
  return {
    type: CLEAR_ZIPCODE,
  };
};

export const fetchTaxRate = (payload?: PaymentMethod): IFetchTaxRateAction => {
  return {
    payload,
    type: FETCH_TAX_RATE,
  };
};

export const submitPayment = (payload: ISubmitPaymentPayload): ISubmitPaymentAction => {
  return {
    payload,
    type: SUBMIT_PAYMENT,
  };
};

export const completePaymentProcess = (): ICompletePaymentProcess => {
  return {
    type: COMPLETE_PAYMENT_PROCESS,
  };
};

export const addRegionRenderingStripeCreditCard = (payload: RegionRenderingStripeCreditCardEnum): IAddRegionRenderingStripeCreditCardAction => {
  return {
    payload,
    type: ADD_REGION_RENDERING_STRIPE_CREDIT_CARD,
  };
};

export const removeRegionRenderingStripeCreditCard = (payload: RegionRenderingStripeCreditCardEnum): IRemoveRegionRenderingStripeCreditCardAction => {
  return {
    payload,
    type: REMOVE_REGION_RENDERING_STRIPE_CREDIT_CARD,
  };
};

export const submitPaymentMethod = (payload: ISubmitPaymentMethodPayload): ISubmitPaymentMethodAction => {
  return {
    payload,
    type: SUBMIT_PAYMENT_METHOD,
  };
};

export const completeEditPaymentMethodProcess = (): ICompleteEditPaymentMethodAction => {
  return {
    type: COMPLETE_EDIT_PAYMENT_METHOD,
  };
};

export const submitDefaultPaymentMethod = (paymentId: string): ISubmitDefaultPaymentMethodAction => {
  return {
    payload: paymentId,
    type: SUBMIT_DEFAULT_PAYMENT_METHOD,
  };
};

export const setDefaultPaymentMethod = (paymentId: string): ISetDefaultPaymentMethodAction => {
  return {
    payload: paymentId,
    type: SET_DEFAULT_PAYMENT_METHOD,
  };
};

export const setPaymentMethods = (billingStatePayments: PaymentMethod[]): ISetPaymentMethodsAction => {
  return {
    payload: billingStatePayments,
    type: SET_PAYMENT_METHODS,
  };
};

export const addPaymentMethod = (billingStatePayment: PaymentMethod): IAddPaymentMethodAction => {
  return {
    payload: billingStatePayment,
    type: ADD_PAYMENT_METHOD,
  };
};

export const removePayment = (key: string): IRemovePaymentAction => {
  return {
    payload: key,
    type: REMOVE_PAYMENT,
  };
};

export const removePaymentConfirm = (payload: { id: string | null, removeFunction: () => void }): IRemovePaymentConfirmAction => {
  return {
    payload,
    type: REMOVE_PAYMENT_CONFIRM,
  };
};

export const stopPaymentsLoading = (): IStopPaymentsLoading => {
  return {
    type: STOP_PAYMENTS_LOADING,
  };
};

export const updateWallet = (wallet: IWallet): IUpdateWalletAction => {
  return {
    payload: wallet,
    type: UPDATE_WALLET,
  };
};

export const resetBillingReducer = (): IResetBillingReducerAction => {
  return {
    type: RESET_BILLING_REDUCER,
  };
};

export const watchEntitlementAccess = (payload: string): IWatchEntitlementAccessAction => {
  return {
    payload,
    type: WATCH_ENTITLEMENT_ACCESS,
  };
};

export const checkApplePayVerify = () => ({
  type: CHECK_APPLE_PAY_VERIFY,
});

export const cancelSubscription = (payload: ICancelSubscription) => ({
  payload,
  type: CANCEL_SUBSCRIPTION,
});

export const setEntitlementLoading = (payload: string | null) => ({
  payload,
  type: SET_ENTITLEMENT_LOADING,
});

export const editPaymentMethod = (payload: IEditPaymentMethodPayload): IEditPaymentMethodAction => ({
  payload,
  type: EDIT_PAYMENT_METHOD,
});

export const getWalletData = (): IGetWalletData => ({
  type: GET_WALLET_DATA,
});

export const updateViewerServiceChargeRate = (payload: number): IUpdateViewerServiceChargeRate => ({
  payload,
  type: UPDATE_VIEWER_SERVICE_CHARGE_RATE,
});

export const getContractData = (): IGetContractData => ({
  type: GET_CONTRACT_DATA,
});

export const updatePlanData = (payload: Plan): IUpdatePlanData => ({
  payload,
  type: UPDATE_PLAN_DATA,
});

export const updateContractData = (payload: IContract): IUpdateContractData => ({
  payload,
  type: UPDATE_CONTRACT_DATA,
});

export const deleteSource = (payload: IDeleteSourcePayload): IDeleteSourceAction => ({
  payload,
  type: DELETE_SOURCE,
});

export const deleteSourceComplete = (payload: string): IDeleteSourceCompleteAction => ({
  payload,
  type: DELETE_SOURCE_COMPLETE,
});

export const purchasePlan = (payload: IPurchasePlanPayload): IPurchasePlanAction => {
  return {
    payload,
    type: PURCHASE_PLAN,
  };
};

export const purchasePlanComplete = (payload: IContract): IPurchasePlanCompleteAction => {
  return {
    payload,
    type: PURCHASE_PLAN_COMPLETE,
  };
};

export const purchasePlanError = (payload: string): IPurchasePlanErrorAction => {
  return {
    payload,
    type: PURCHASE_PLAN_ERROR,
  };
};

export const setNativeCurrency = (payload: string | null): ISetNativeCurrencyAction => {
  return {
    payload,
    type: SET_NATIVE_CURRENCY,
  };
};

export const upgradePlan = (payload: IUpgradePlanPayload): IUpgradePlanAction => {
  return {
    payload,
    type: UPGRADE_PLAN,
  };
};

export const upgradePlanComplete = (payload: IContract): IUpgradePlanCompleteAction => {
  return {
    payload,
    type: UPGRADE_PLAN_COMPLETE,
  };
};

export const upgradePlanError = (payload: string): IUpgradePlanErrorAction => {
  return {
    payload,
    type: UPGRADE_PLAN_ERROR,
  };
};
