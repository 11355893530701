import { createSelector } from 'reselect';
import IState from '../state';
import { ExportStatus, FilterModalTypes, IEntitlementItem, IExportFile, IFilter, ISearch, SearchByTypes, Views } from './reducer';
export const getView = (state: IState): Views => state.community.view;
export const getModalType = (state: IState): string => state.community.modalType;
export const getActivePage = (state: IState): number => state.community.userSection.activePage;
export const getFilterModal = (state: IState): FilterModalTypes => state.community.userSection.filterModal;
export const getSearch = (state: IState): ISearch => state.community.userSection.search;
export const getFilter = (state: IState): IFilter | undefined => state.community.userSection.search.filter;
export const getSearchBy = (state: IState): SearchByTypes => state.community.userSection.search.searchBy;
export const getSearchQuery = (state: IState): string => state.community.userSection.search.searchQuery;
export const getExportStatus = (state: IState): ExportStatus => state.community.userSection.exportStatus;
export const getExportFile = (state: IState): IExportFile | null => state.community.userSection.exportFile;
export const getExportFileExportId = (state: IState): string | null => state.community.userSection.exportFile?.exportId || null;
export const getExportFileLink = (state: IState): string | undefined => state.community.userSection.exportFile?.link;
export const getSortQuery = (state: IState): { sortBy: string, sortDirection: number } => state.community.userSection.sortQuery;
export const getQueryDescription = (state: IState): string | undefined => state.community.userSection.queryDescription;
export const getUserAccountId = (state: IState): string => state.community.userProfile.accountId;
export const getCurrentUserSubscriptions = createSelector(
  (state: IState): Partial<IEntitlementItem> => state.community.userProfile.subscription,
  subscription => subscription,
);
export const isChannelFilterActive = createSelector([getFilter], (filter) => {
  return Boolean(filter?.channelId) && Boolean(filter?.channelName);
});
export const isTimeStartFilterActive = createSelector([getFilter], (filter) => {
  return Boolean(filter?.timeStart);
});
export const isTimeEndFilterActive = createSelector([getFilter], (filter) => {
  return Boolean(filter?.timeEnd);
});
export const isTimeFilterActive = createSelector([isTimeStartFilterActive, isTimeEndFilterActive],
  (isTimeStartActive, isTimeEndActive) => isTimeStartActive && isTimeEndActive);
export const isAccountInfoFilterActive = createSelector([getSearch], (search) => {
  return search.searchBy === SearchByTypes.accountInfo && search.searchQuery.length > 0;
});
export const isAccessCodeFilterActive = createSelector([getSearch], (search) => {
  return search.searchBy === SearchByTypes.accessCode && search.searchQuery.length > 0;
});
export const isReceiptNumberFilterActive = createSelector([getSearch], (search) => {
  return search.searchBy === SearchByTypes.receiptNumber && search.searchQuery.length > 0;
});
export const isEntitlementFilterActive = createSelector([getSearch], (search) => {
  return search.searchBy === SearchByTypes.entitlements && search.searchQuery.length > 0;
});
export const isPropertyFilterActive = createSelector([
  isAccessCodeFilterActive, isReceiptNumberFilterActive, isEntitlementFilterActive,
], (isAccessCodeActive, isReceiptNumberActive, isEntitlementActive) => {
  return isAccessCodeActive || isReceiptNumberActive || isEntitlementActive;
});
