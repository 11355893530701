import {
 IAnalyticsAction,
 CUSTOM_RANGE,
 ONEDAY_RANGE,
 SEVENDAY_RANGE,
 THIRTYDAY_RANGE,
 SET_CUSTOM_RANGE_TYPE,
} from './actions';
import {
  IAnalyticsState, Ranges,
} from './models';

export const getInitialState = (): IAnalyticsState => ({
  customRange: {
    rangeStart: Date.now(),
    rangeEnd: Date.now(),
  },
  defaultRange: {
    rangeStart: Date.now(),
    rangeEnd: Date.now(),
  },
  rangeType: Ranges.ONE_DAY,
});

const AnalyticsReducer = (
  state: IAnalyticsState = getInitialState(),
  action: IAnalyticsAction,
): IAnalyticsState => {
  switch (action.type) {
    case CUSTOM_RANGE: {
      const customRange: any = action.payload;
      return {
        ...state,
        customRange,
      };
    }
    case SET_CUSTOM_RANGE_TYPE: {
      const { rangeType }: any = action.payload;
      const { rangeStart, rangeEnd } = state.customRange;
      return {
        ...state,
        defaultRange: { rangeStart, rangeEnd },
        rangeType,
      };
    }
    case ONEDAY_RANGE:
    case SEVENDAY_RANGE:
    case THIRTYDAY_RANGE: {
      const { rangeStart, rangeEnd, rangeType }: any = action.payload;
      return {
        ...state,
        defaultRange: { rangeStart, rangeEnd },
        rangeType,
      };
    }
    default:
      return state;
  }
};

export default AnalyticsReducer;
