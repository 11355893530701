import { PaymentMethod, StripeCardNumberElement } from '@stripe/stripe-js';
import { IOnSubmitPaymentMethodsParams } from 'components/payment/PaymentMethods';
import ISubscription from 'models/ISubscription';
import { IProductVariant } from 'services/shopify/models';
import IBundle from 'models/IBundle';

export const CREATE_BUNDLE = 'bundle/CREATE_BUNDLE';
export const SET_BUNDLE = 'bundle/SET_BUNDLE';
export const GET_BUNDLE = 'bundle/GET_BUNDLE';
export const CHARGE_BUNDLE = 'bundle/CHARGE_BUNDLE';

export type IBundleActions =
  ISetBundleAction;

interface IBundleIdPayload {
  bundle: IBundle;
}

interface IGetBundlePayload {
  id: string;
}

interface IChargeBundlePayload extends IOnSubmitPaymentMethodsParams {
  bundle: IBundle;
  element?: StripeCardNumberElement;
  paymentMethod?: PaymentMethod;
  presentmentCurrency: string;
  productsVariant: Record<string, IProductVariant['node']>;
  tickets: ISubscription[];
}
export interface ICreateBundlePayload {
  bundle: Omit<IBundle, 'siteId'>
}

export interface IGetBundleByIdPayload {
  bundle: IBundle;
}

export interface ICreateBundleAction {
  payload: ICreateBundlePayload;
  type: typeof CREATE_BUNDLE;
}

export interface ISetBundleAction {
  payload: IBundleIdPayload,
  type: typeof SET_BUNDLE;
}

export interface IGetBundleAction {
  payload: IGetBundlePayload,
  type: typeof GET_BUNDLE;
}

export interface IChargeBundleAction {
  payload: IChargeBundlePayload,
  type: typeof CHARGE_BUNDLE;
}

export function createBundle(payload: ICreateBundlePayload): ICreateBundleAction {
  return {
    payload,
    type: CREATE_BUNDLE,
  };
}

export function setBundle(payload: IBundleIdPayload): ISetBundleAction {
  return {
    payload,
    type: SET_BUNDLE,
  };
}

export function chargeBundle(payload: IChargeBundlePayload): IChargeBundleAction {
  return {
    payload,
    type: CHARGE_BUNDLE,
  };
}
