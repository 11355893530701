import axios from 'axios';
import {
  BILLING_SERVICE_BASE_URL,
  CONTRACT_SERVICE_BASE_URL,
  INVOICE_SERVICE_BASE_URL,
  WALLET_SERVICE_BASE_URL,
  TAX_SERVICE_BASE_URL,
  PLAN_SERVICE_BASE_URL,
} from 'config';

import {
  IChargeResult,
  IWallet,
  IWalletData,
  IPurchaseRequest,
  ITaxRate,
  IBillingInfo,
  ICancelSubscription,
  IEditPaymentMethodParams,
  IPurchaseSummary,
  INativeCurrencyData,
} from './models';
import { StripeCardNumberElement, Stripe, StripeError, PaymentMethod, Token } from '@stripe/stripe-js';
import { IContract } from 'models/ISite';
import Plan from 'models/IPlan';
import { IAccountEntitlement } from 'services/gate';
import { ISubmitPaymentMethodAPI } from '.';
import maestroApiRequest from 'services/maestro-api-client';

export const getTaxRate = async (accessToken: string, payload: IBillingInfo, siteId: string): Promise<ITaxRate> => {
  const { data } = await axios.post<ITaxRate>(`${TAX_SERVICE_BASE_URL}`, payload, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const getWallet = async (accessToken: string, siteId: string): Promise<IWallet> => {
  const { data } = await axios.get<IWallet>(`${WALLET_SERVICE_BASE_URL}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

// get array of payments
export const getPayments = async (
  accessToken: string,
  siteId: string,
  walletId: string,
): Promise<PaymentMethod[]> => {
  const { data } = await axios.get<PaymentMethod[]>(`${WALLET_SERVICE_BASE_URL}/${walletId}/cards`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

// get array of payments
export const getAdminPayments = async (
  accessToken: string,
  siteId: string,
  stripeCustomerId: string,
): Promise<PaymentMethod[]> => {
  const { data } = await axios.get<PaymentMethod[]>(`${INVOICE_SERVICE_BASE_URL}/payments/${stripeCustomerId}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const removePayment = async (
  accessToken: string,
  siteId: string,
  walletId: string,
  paymentId: string,
): Promise<{ defaultPaymentMethod: string | null, success: boolean }> => {
  const { data } = await axios.delete(`${WALLET_SERVICE_BASE_URL}/${walletId}/card/${paymentId}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const removeAdminPayment = async (
  accessToken: string,
  siteId: string,
  paymentId: string,
): Promise<{ defaultPaymentMethod: string | null, success: boolean }> => {
  const { data } = await axios.delete(`${INVOICE_SERVICE_BASE_URL}/payments/${paymentId}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const submitPaymentMethod = async (
  accessToken: string,
  siteId: string,
  walletId: string,
  payload: ISubmitPaymentMethodAPI,
): Promise<IChargeResult> => {
  const { data } = await axios.post<IChargeResult>(`${WALLET_SERVICE_BASE_URL}/${walletId}/card`, payload, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const submitAdminPaymentMethod = async (
  accessToken: string,
  stripeCustomerId: string,
  siteId: string,
  payload: PaymentMethod,
): Promise<{success: boolean}> => {
  const body = { paymentMethod: payload, stripeCustomerId, siteId };
  const { data } = await axios.post<IChargeResult>(`${INVOICE_SERVICE_BASE_URL}/payments`, body, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

// update payment id to default payment method
export const submitDefaultPaymentMethod = async (
  accessToken: string,
  siteId: string,
  walletId: string,
  paymentId: string,
): Promise<IChargeResult> => {
  const { data } = await axios.put<IChargeResult>(`${WALLET_SERVICE_BASE_URL}/${walletId}/card/${paymentId}/default`, {}, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

// update payment id to default payment method
export const submitAdminDefaultPaymentMethod = async (
  accessToken: string,
  siteId: string,
  stripeCustomerId: string,
  paymentMethodId: string,
): Promise<IChargeResult> => {
  const { data } = await axios.put<IChargeResult>(`${INVOICE_SERVICE_BASE_URL}/payments/default`, {
    paymentMethodId,
    stripeCustomerId,
  }, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const getStripePaymentMethod = async (
  cardNumber: StripeCardNumberElement,
  stripe: Stripe,
  billingInfo: IBillingInfo,
): Promise<{ error?: StripeError; paymentMethod?: PaymentMethod }> => {
  const { name, postalCode, countryCode, stateCode, city, address, phone } = billingInfo;
  return await stripe.createPaymentMethod({
    type: 'card',
    card: cardNumber,
    billing_details: {
      ...(!!name && { name }),
      address: {
        postal_code: postalCode,
        country: countryCode,
        state: stateCode,
        ...(!!city && { city }),
        ...(!!address && { line1: address }),
      },
      ...(!!phone && { phone }),
    },
  });
};

export const getStripePaymentMethodWithoutBillingInfo = async (
  cardNumber: StripeCardNumberElement,
  stripe: Stripe,
): Promise<{ error?: StripeError; paymentMethod?: PaymentMethod }> => {
  return await stripe.createPaymentMethod({
    type: 'card',
    card: cardNumber,
  });
};

export const getStripeCardToken = async (
  cardNumber: StripeCardNumberElement,
  stripe: Stripe,
  billingInfo: IBillingInfo,
): Promise<{ error?: StripeError; token?: Token }> => {
  const {
    address,
    postalCode,
    city,
    countryCode,
    stateCode,
    name,
  } = billingInfo;
  return await stripe.createToken(cardNumber, {
    address_line1: address,
    address_zip: postalCode,
    address_city: city,
    address_country: countryCode,
    address_state: stateCode || '',
    name,
  });
};

export const submitPayment = async (
  accessToken: string,
  siteId: string,
  payload: IPurchaseRequest,
): Promise<IAccountEntitlement | IChargeResult> => {
  const { data } = await axios.post<IAccountEntitlement>(`${BILLING_SERVICE_BASE_URL}/charge`, payload, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const confirmPaymentStatus = async (
  accessToken: string,
  siteId: string,
  payload: IAccountEntitlement,
): Promise<boolean> => {
  const { data } = await axios.post<boolean>(`${BILLING_SERVICE_BASE_URL}/status/confirm`, payload, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const applePayVerify = async (
  primaryToken: string | null,
  siteId: string,
): Promise<null | Record<string, string>> => {
  const { data } = await axios.put(`${BILLING_SERVICE_BASE_URL}/status/apple-pay-verify`, {}, {
    headers: {
      'x-maestro-client-id': siteId,
      'Authorization': `Bearer ${primaryToken}`,
    },
  });
  return data;
};

export const cancelSubscription = async (
  accessToken: string,
  siteId: string,
  walletId: string,
  payload: ICancelSubscription,
): Promise<boolean> => {
  const { data } = await axios.put(
    `${WALLET_SERVICE_BASE_URL}/recurring/${walletId}/${payload.subscriptionId}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'x-maestro-client-id': siteId,
      },
    },
  );
  return data;
};

export const submitEditPaymentMethod = async (
  accessToken: string,
  siteId: string,
  payload: IEditPaymentMethodParams,
): Promise<boolean> => {
  const { walletId, paymentMethodId } = payload;
  const { data } = await axios.put<boolean>(`${WALLET_SERVICE_BASE_URL}/${walletId}/card/${paymentMethodId}`, payload, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const submitEditAdminPaymentMethod = async (
  accessToken: string,
  siteId: string,
  payload: Omit<IEditPaymentMethodParams, 'walletId'>,
): Promise<boolean> => {
  const { data } = await axios.put<boolean>(`${INVOICE_SERVICE_BASE_URL}/payments`, payload, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const getWalletData = async (
  accessToken: string,
  siteId: string,
  isAdmin: boolean,
) => {
  const { data } = await axios.get<IWalletData>(
    `${WALLET_SERVICE_BASE_URL}${isAdmin ? '?kind=site' : ''}`,
    {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'x-maestro-client-id': siteId,
      },
    },
  );
  return data;
};

export const getViewerServiceCharge = async (accessToken: string, siteId: string): Promise<number> => {
  const { data } = await axios.get<number>(`${PLAN_SERVICE_BASE_URL}/payout-terms/viewer-service-charge`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const getPlanData = async (
  accessToken: string,
  siteId: string,
  planId: string,
) => {
  const { data } = await axios.get<Plan>(`${PLAN_SERVICE_BASE_URL}/${planId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const getContractData = async (
  accessToken: string,
  siteId: string,
) => {
  const { data } = await axios.get<IContract>(CONTRACT_SERVICE_BASE_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

export const purchasePlan = async (
  accessToken: string,
  stripeCustomerId: string,
  siteId: string,
  payload: PaymentMethod,
) => {
  const body = { paymentMethod: payload, stripeCustomerId, siteId };
  const { data } = await axios.post<IPurchaseSummary>(
    `${INVOICE_SERVICE_BASE_URL}/payments/purchase`,
    body,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'x-maestro-client-id': siteId,
      },
    },
  );
  return data;
};

export interface IChangePlanParams {
  accessToken: string,
  paymentMethod: PaymentMethod,
  planId: string,
  siteId: string,
  stripeCustomerId: string,
}

export const changePlan = async (
  { accessToken: primaryToken, stripeCustomerId, siteId, paymentMethod, planId }: IChangePlanParams,
) => {
  const body = { paymentMethod, planId, stripeCustomerId, siteId };
  const { data } = await maestroApiRequest({ primaryToken, siteId }).post<IPurchaseSummary>(
    `${INVOICE_SERVICE_BASE_URL}/payments/change-plan`,
    body,
  );
  return data;
};

export const fetchNativeCurrency = async (
  countryCode: string,
) => {
  const { data } = await axios.get<INativeCurrencyData>(
    `${BILLING_SERVICE_BASE_URL}/currency/${countryCode}`,
  );
  return data;
};
