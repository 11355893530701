import uuid from 'uuid/v4';
import { ADD_REGION_RENDERING_STRIPE_CREDIT_CARD, PURCHASE_PLAN, PURCHASE_PLAN_COMPLETE, PURCHASE_PLAN_ERROR, REMOVE_REGION_RENDERING_STRIPE_CREDIT_CARD, SET_NATIVE_CURRENCY,UPGRADE_PLAN, UPGRADE_PLAN_COMPLETE, UPGRADE_PLAN_ERROR } from '.';
import {
  COMPLETE_PAYMENT_PROCESS,
  ADD_PAYMENT_METHOD,
  IBillingAction,
  REMOVE_PAYMENT,
  RESET_BILLING_REDUCER,
  SUBMIT_PAYMENT,
  SUBMIT_DEFAULT_PAYMENT_METHOD,
  SET_DEFAULT_PAYMENT_METHOD,
  SET_PAYMENT_METHODS,
  UPDATE_WALLET,
  SUBMIT_PAYMENT_METHOD,
  STOP_PAYMENTS_LOADING,
  SET_TAX_RATE,
  SET_BILLING_INFO,
  CLEAR_BILLING_INFO,
  CLEAR_ZIPCODE,
  FETCH_TAX_RATE,
  WATCH_ENTITLEMENT_ACCESS,
  SET_BILLING_POSTAL_CODE,
  SET_BILLING_INFO_COMPLETED,
  SET_BILLING_COUNTRY_CODE,
  SET_BILLING_NAME,
  SET_BILLING_STATE_CODE,
  SET_BILLING_INFO_ERROR,
  SET_ENTITLEMENT_LOADING,
  COMPLETE_EDIT_PAYMENT_METHOD,
  EDIT_PAYMENT_METHOD,
  SET_BILLING_NAME_ERROR,
  UPDATE_VIEWER_SERVICE_CHARGE_RATE,
  UPDATE_CONTRACT_DATA,
  UPDATE_PLAN_DATA,
  DELETE_SOURCE_COMPLETE,
  SET_ENTITLEMENT_DATA,
} from './actions';

import { IBillingState } from './models';

const INITIAL_STATE: IBillingState = {
  amountBreakdown: {
    taxRate: 0,
    entitlementPrice: {
      currency: 'usd',
      default: true,
      value: 0,
    },
    subscriptionType: null,
  },
  billingInfo: {
    name: '',
    postalCode: '',
    complete: false,
    countryCode: '',
    error: null,
    markDefaultPaymentMethod: true,
  },
  billingInfoError: {
    errorName: {
      errorMsg: '',
      hasError: false,
    },
  },
  entitlementLoadingId: null,
  idempotencyKey: uuid(),
  paymentPending: false,
  editPaymentMethodSuccess: false,
  editPaymentMethodPending: false,
  defaultPayment: null,
  paymentsLoading: false,
  addPaymentSuccess: false,
  setPrimaryPaymentSuccess: false,
  payments: [],
  taxLoaded: false,
  taxLoading: false,
  viewerServiceChargeRate: null,
  wallet: null,
  watchedEntitlementIds: [],
  removePaymentSuccess: false,
  plan: window.INIT?.PLAN || null,
  contract: null,
  purchasePlanLoading: false,
  purchasePlanErrorMessage: null,
  purchasePlanSuccess: false,
  regionRenderingStripeCreditCard: [],
  nativeCurrencyCode: null,
  usingPaymentRequestButtonForEntitlementPurchase: false,
  upgradePlanErrorMessage: null,
  upgradePlanLoading: false,
  upgradePlanSuccess: false,
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

const billingReducer = (state: IBillingState = INITIAL_STATE, action: IBillingAction) => {
  switch (action.type) {
    case SET_ENTITLEMENT_DATA: {
      const { entitlementPrice, subscriptionType } = action.payload;
      return {
        ...state,
        amountBreakdown: {
          ...state.amountBreakdown,
          taxRate: 0,
          entitlementPrice,
          subscriptionType,
        },
      };
    }
    case SET_TAX_RATE: {
      return {
        ...state,
        taxLoading: false,
        taxLoaded: true,
        amountBreakdown: {
          ...state.amountBreakdown,
          ...action.payload,
        },
      };
    }
    case SET_BILLING_INFO: {
      return {
        ...state,
        billingInfo: action.payload,
      };
    }
    case SET_BILLING_NAME_ERROR: {
      return {
        ...state,
        billingInfoError: {
          ...state.billingInfoError,
          errorName: action.payload,
        },
      };
    }
    case SET_BILLING_POSTAL_CODE: {
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          postalCode: action.payload,
        },
      };
    }
    case SET_BILLING_COUNTRY_CODE: {
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          countryCode: action.payload,
          ...(action.payload !== 'US' && { stateCode: null }),
        },
      };
    }
    case SET_BILLING_STATE_CODE: {
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          stateCode: action.payload,
        },
      };
    }
    case SET_BILLING_NAME: {
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          name: action.payload,
        },
      };
    }
    case SET_BILLING_INFO_ERROR: {
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          error: action.payload,
        },
      };
    }
    case SET_BILLING_INFO_COMPLETED: {
      return {
        ...state,
        addPaymentSuccess: false,
        editPaymentMethodSuccess: false,
        removePaymentSuccess: false,
        setPrimaryPaymentSuccess: false,
        billingInfo: {
          ...state.billingInfo,
          complete: action.payload,
        },
      };
    }
    case EDIT_PAYMENT_METHOD: {
      return {
        ...state,
        editPaymentMethodPending: true,
        paymentsLoading: true,
      };
    }
    case COMPLETE_EDIT_PAYMENT_METHOD: {
      return {
        ...state,
        addPaymentSuccess: false,
        editPaymentMethodSuccess: true,
        removePaymentSuccess: false,
        setPrimaryPaymentSuccess: false,
        editPaymentMethodPending: false,
      };
    }
    case CLEAR_BILLING_INFO: {
      return {
        ...state,
        billingInfo: {
          postalCode: '',
          countryCode: '',
          stateCode: null,
          name: '',
          markDefaultPaymentMethod: true,
        },
        billingInfoError: {
          errorName: {
            errorMsg: '',
            hasError: false,
          },
        },
        taxLoaded: false,
        paymentPending: false,
        addPaymentSuccess: false,
        editPaymentMethodSuccess: false,
        removePaymentSuccess: false,
        setPrimaryPaymentSuccess: false,
      };
    }
    case CLEAR_ZIPCODE: {
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          postalCode: '',
        },
        billingInfoError: {
          errorName: {
            errorMsg: '',
            hasError: false,
          },
        },
        taxLoaded: false,
        paymentPending: false,
        addPaymentSuccess: false,
        editPaymentMethodSuccess: false,
        removePaymentSuccess: false,
        setPrimaryPaymentSuccess: false,
      };
    }
    case FETCH_TAX_RATE: {
      return {
        ...state,
        taxLoading: true,
        amountBreakdown: {
          ...state.amountBreakdown,
          taxRate: 0,
        },
      };
    }
    case COMPLETE_PAYMENT_PROCESS: {
      return {
        ...state,
        idempotencyKey: uuid(),
        paymentPending: false,
        usingPaymentRequestButtonForEntitlementPurchase: false,
      };
    }
    case ADD_REGION_RENDERING_STRIPE_CREDIT_CARD: {
      return {
        ...state,
        regionRenderingStripeCreditCard: [
          ...state.regionRenderingStripeCreditCard.filter(region => region !== action.payload), action.payload,
        ],
      };
    }
    case REMOVE_REGION_RENDERING_STRIPE_CREDIT_CARD: {
      return {
        ...state,
        regionRenderingStripeCreditCard: [
          ...state.regionRenderingStripeCreditCard.filter(region => region !== action.payload),
        ],
      };
    }
    case SUBMIT_PAYMENT: {
      return {
        ...state,
        paymentPending: true,
        usingPaymentRequestButtonForEntitlementPurchase: action.payload.usingPaymentRequestButtonForm,
      };
    }
    case ADD_PAYMENT_METHOD: {
      const filteredPayments = state.payments.filter(payment => payment?.card?.fingerprint === action.payload.card?.fingerprint);
      return {
        ...state,
        paymentsLoading: false,
        addPaymentSuccess: true,
        editPaymentMethodSuccess: false,
        removePaymentSuccess: false,
        setPrimaryPaymentSuccess: false,
        payments: [...filteredPayments, action.payload],
      };
    }
    case REMOVE_PAYMENT: {
      return {
        ...state,
        addPaymentSuccess: false,
        editPaymentMethodSuccess: false,
        removePaymentSuccess: true,
        setPrimaryPaymentSuccess: false,
        payments: state.payments.filter(payment => payment.id !== action.payload),
      };
    }
    case STOP_PAYMENTS_LOADING: {
      return {
        ...state,
        paymentsLoading: false,
      };
    }
    case SET_PAYMENT_METHODS: {
      let defaultPayment = state.defaultPayment;
      const paymentSort = action.payload.sort((a, b) => {
        if (defaultPayment) {
          return a.id === defaultPayment ? -1 : b.id === defaultPayment ? 1 : 0;
        }
        defaultPayment = !defaultPayment && (a.id.includes('pm_')) ? a.id : b.id;

        return 0;
      });

      return {
        ...state,
        paymentsLoading: false,
        payments: paymentSort,
        defaultPayment,
      };
    }

    case SET_DEFAULT_PAYMENT_METHOD: {
      const defaultPayment = action.payload;
      const paymentMethodBySource = state.wallet?.paymentMethodBySource;
      const contract = state.contract;
      const defaultCardSource = paymentMethodBySource?.[defaultPayment] || null;
      return {
        ...state,
        ...(!contract && { defaultPayment }),
        ...(contract && { contract: { ...state.contract, paymentMethod: defaultPayment } }),
        editPaymentMethodSuccess: false,
        setPrimaryPaymentSuccess: !!(state.defaultPayment && defaultPayment) && state.defaultPayment !== defaultPayment,
        payments: [...state.payments].sort((a, b) => {
          if (defaultPayment) {
            const isDefaultPayment = (pmId: string) => pmId === defaultPayment;
            const isDefaultSource = (pmId: string) => pmId === paymentMethodBySource?.[defaultPayment];
            return isDefaultPayment(a.id) || isDefaultSource(a.id) ? -1 : isDefaultPayment(b.id) || isDefaultSource(b.id) ? 1 : 0;
          }
          return 0;
        }),
        wallet: {
          ...state.wallet,
          defaultPaymentMethod: defaultPayment,
          defaultCardSource,
        },
      };
    }
    case UPDATE_WALLET: {
      return {
        ...state,
        wallet: action.payload,
      };
    }
    case RESET_BILLING_REDUCER: {
      return {
        ...state,
        billingInfo: {
          name: '',
          postalCode: '',
          complete: false,
          countryCode: '',
          error: '',
          markDefaultPaymentMethod: true,
        },
        invoice: null,
        payments: [],
        defaultPayment: null,
        wallet: null,
        plan: window.INIT?.PLAN,
        contract: null,
        regionRenderingStripeCreditCard: [],
      };
    }
    case SUBMIT_PAYMENT_METHOD: {
      return {
        ...state,
        paymentsLoading: true,
      };
    }
    case SUBMIT_DEFAULT_PAYMENT_METHOD: {
      return {
        ...state,
        addPaymentSuccess: false,
      };
    }
    case WATCH_ENTITLEMENT_ACCESS: {
      return {
        ...state,
        watchedEntitlementIds: [
          ...state.watchedEntitlementIds,
          action.payload,
        ],
      };
    }
    case SET_ENTITLEMENT_LOADING: {
      return {
        ...state,
        entitlementLoadingId: action.payload,
      };
    }
    case UPDATE_VIEWER_SERVICE_CHARGE_RATE: {
      return {
        ...state,
        viewerServiceChargeRate: action.payload,
      };
    }
    case UPDATE_PLAN_DATA: {
      return {
        ...state,
        plan: action.payload,
      };
    }
    case UPDATE_CONTRACT_DATA: {
      return {
        ...state,
        contract: action.payload,
      };
    }
    case DELETE_SOURCE_COMPLETE: {
      return {
        ...state,
        payments: state.payments.filter(payment => payment.id !== action.payload),
      };
    }
    case PURCHASE_PLAN: {
      return {
        ...state,
        purchasePlanLoading: true,
      };
    }
    case PURCHASE_PLAN_COMPLETE: {
      return {
        ...state,
        purchasePlanLoading: false,
        contract: action.payload,
        purchasePlanErrorMessage: null,
        purchasePlanSuccess: true,
      };
    }
    case PURCHASE_PLAN_ERROR: {
      return {
        ...state,
        purchasePlanLoading: false,
        purchasePlanErrorMessage: action.payload,
        purchasePlanSuccess: false,
      };
    }
    case SET_NATIVE_CURRENCY: {
      return {
        ...state,
        nativeCurrencyCode: action.payload,
      };
    }
    case UPGRADE_PLAN: {
      return {
        ...state,
        upgradePlanLoading: true,
      };
    }
    case UPGRADE_PLAN_COMPLETE: {
      return {
        ...state,
        upgradePlanLoading: false,
        contract: action.payload,
        upgradePlanErrorMessage: null,
        upgradePlanSuccess: true,
      };
    }
    case UPGRADE_PLAN_ERROR: {
      return {
        ...state,
        upgradePlanLoading: false,
        upgradePlanErrorMessage: action.payload,
        upgradePlanSuccess: false,
      };
    }
    default:
      return state;
  }
};

export default billingReducer;
