import IState from '../state';
import { createSelector } from 'reselect';

/*TODO: move code injections from services/app to services/developer
and also write it to mongo on the site setting model*/

export const getSaved = (state: IState) => state.developer?.saved;
export const getApiKeyData = createSelector(
  (state: IState) => state.developer?.apiKey,
  apiKey => apiKey,
);
export const hasApiKeyData = (state: IState) => !!state.developer?.apiKey?.data?.key;
export const webhookUrlHasChanged = (state: IState) => state.developer?.webhooks?.webhookUrlHasChanged;
export const getDeveloperSetting = (state: IState) => state.developer;
