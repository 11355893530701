import { ObjectValues } from 'utils';
import IModel from './IModel';

export default interface ITheme extends IModel {
  button: IThemeButton;
  channelOfflineThumbnail?: string;
  colors: IThemeColors;
  desktopBackground?: string;
  icon?: string;
  logo?: string;
  mobileBackground?: string;
  mode?: ThemeModes;
  name: string;
  siteId?: string;
  type: ThemeTypes;
  typography: IThemeTypography;
}

export interface IThemeColors {
  accentPrimary: string;
  accentSecondary: string;
  accentTertiary: string;
  alertConfirmation: string;
  alertError: string;
  alertWarning: string;
  highlightPrimary: string;
  highlightSecondary: string;
  highlightTertiary: string;
  surface1: string;
  surface2: string;
  surface3: string;
  surface4: string;
  surface5: string;
  surface6: string;
  text100: string;
  text200: string;
  text300: string;
  text400: string;
  text500: string;
}

export interface IThemeButton {
  borderRadius: BorderRadius;
  textCapitalization: TextCapitalization;
}

export interface IThemeTypography {
  body: string;
  headline: string;
}

export interface IClassicThemeOptions {
  accentPrimary: string;
  accentSecondary: string;
}

export const BORDER_RADIUS = {
  rounded: 'rounded',
  square: 'square',
  pill: 'pill',
} as const;
export type BorderRadius = ObjectValues<typeof BORDER_RADIUS>;

export const TEXT_CAPITALIZATION = {
  lowercase: 'lowercase',
  original: 'original',
  uppercase: 'uppercase',
} as const;
export type TextCapitalization = ObjectValues<typeof TEXT_CAPITALIZATION>;

export const THEME_TYPES = {
  ADMIN: 'admin',
  ADVANCED: 'advanced',
  CLASSIC: 'classic',
  CUSTOM: 'custom',
} as const;
export type ThemeTypes = ObjectValues<typeof THEME_TYPES>;

export const THEME_MODES = {
  DARK: 'dark',
  LIGHT: 'light',
} as const;
export type ThemeModes = ObjectValues<typeof THEME_MODES>;
