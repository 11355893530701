import IGate from 'models/IGate';
import { IFooter } from './IFooter';
import INavigationLegacy from './INavigation';
import { IClassicThemeOptions, ThemeTypes } from './ITheme';
import { ILandingPageContent } from 'services/app/models/ILandingPageContent';
import { StreamSource } from 'services/livestream/constants';
export enum PageType {
  CHANNEL = 'channel',
  LANDING = 'landing',
}

interface IObjectStyle {
  channelBackgroundColor?: string;
  gridTextColor?: string;
}

type ContentKind = 'playlist' | 'video';

export interface IScheduleItem {
  array_id: string;
  duration: number;
  end_time: string;
  kind: ContentKind;
  playlist?: { _id: string };
  repeat: 'none' | 'daily' | 'weekly';
  show_countdown: boolean;
  start_time: string;
  title: string;
  video?: { _id: string };
}

export interface IChannelOfflineContent {
  image?: string;
  kind: ContentKind; // WILL BE DEPRECATED SOON. OFFLINE CONTENT WILL BE EITHER VIDEO OR IMAGE
  mode: 'tv' | 'image';
  playlist?: { _id: string }; // WILL BE DEPRECATED SOON. OFFLINE CONTENT WILL BE EITHER VIDEO OR IMAGE
  video?: { _id: string }; // only if kind is video
}

export interface IPageStyle {
  activeColor?: string;
  activeTextColor?: string;
  backgroundColor?: string;
  bodyFont?: string;
  channelSelectColor?: string;
  contentBackgroundColor?: string;
  contentTextColor?: string;
  countdownTextColor?: string;
  footerLinksColor?: string;
  gridTextColor?: string;
  navigationTextColor?: string;
  socialLinksColor?: string;
  titleBackgroundColor?: string;
  titleFont?: string;
  titleTextColor?: string;
}

export interface IPageDataNavigationRegion {
  background?: string;
  items?: Array<{
    children?: Array<{
      link?: string;
      text?: string;
    }>
    hasMenu: 'yes' | 'no';
    image?: string;
    link?: string;
    text: string;
  }>
  layout: 'over_header' | 'top';
  state: 'off' | 'on';
}

interface IPageDataRegions {
  'channel-select': {
    pages: Array<{
      page: {
        _id: string,
        slug: string,
      },
    }>;
    state: string;
  }
  footer?: IFooter,
  header: {
    countdown?: {
      startTime: string;
      title: string;
    },
  }
  navigation?: IPageDataNavigationRegion;
  [name: string]: Record<string, any> | undefined,
}

type ChangeFields<T, R> = Omit<T, keyof R> & R;
type IGateWithoutPassword = ChangeFields<IGate, {
  gate: Omit<IGate['gate'], 'password'>,
}>;

export default interface IObject {
  _id: string;
  activeRenderers?: {
    [key: string]: boolean;
  };
  baseLanguage: string;
  collection: string;
  created?: number;
  data: {
    artwork?: {
      background?: string;
      header?: {
        logo?: string;
        logoLink?: string;
        logoTitle?: string;
      },
      video?: {
        kind?: string;
      },
    }
    card?: {
      active?: boolean;
      card?: {
        _id: string;
      }
    };
    channelLanguage?: string;
    channelSelectActiveImageUrl?: string;
    content?: {
      live?: {
        liveStreams?: Array<{ _id: string }>
        schedule?: IScheduleItem[];
      };
      offline?: IChannelOfflineContent;
    };
    countdown?: any;
    customVideoOverlay?: {
      enabled?: boolean;
      url?: string;
    }
    gate: IGateWithoutPassword;
    landingContent?: ILandingPageContent[];
    name?: string;
    navigationId?: string;
    private?: boolean;
    regions?: IPageDataRegions;
    sidebar?: Array<{ _id: string, slug: string }>;
    style?: IPageStyle;
    theme?: {
      classicThemeOptions: IClassicThemeOptions;
      id: string;
      type: ThemeTypes;
    };
  } & Record<string, any>;
  lastModified?: number;
  modified?: number;
  renderers: {
    livestream?: {
      error?: string;
      livestreamPublished?: boolean;
      source?: StreamSource;
      status?: 'creating' | 'ok' | 'error';
      streamId?: string; // new provider
      studioSessionId?: string;
      videoId?: string;
    } & Record<string, any>
    navigation?: INavigationLegacy;
    sidebar?: {
      cardId?: string,
      items?: Array<{
        arrayId: string;
        id: string;
      }>;
    };
    socials?: {
      hashtag?: string;
      links: Array<{
        arrayId: string;
        icon: string;
        title: string;
        url: string;
      }>,
      tweetMessage?: string;
    }
  };
  seo?: {
    description?: string;
    image?: string;
    isImageSameAsCard?: boolean;
    keywords?: string;
    title?: string;
  };
  site_id: string;
  siteId?: string;
  slug: string;
  style?: IObjectStyle;
  tags?: string[];
  type: PageType;
}
