import { createSelector } from 'reselect';
import IState from 'services/state';
import { NotificationWithId } from './models';

export const getLowPriorityNotifications = (state: IState) => (
  state.adminNotifications?.low || []
);
export const getMediumPriorityNotifications = (state: IState) => (
  state.adminNotifications?.medium || []
);
export const getHighPriorityNotifications = (state: IState) => (
  state.adminNotifications?.high || []
);

export const getCurrentNotification = createSelector([
  getHighPriorityNotifications,
  getMediumPriorityNotifications,
  getLowPriorityNotifications,
], (high, medium, low): NotificationWithId | null => {
  return high[0] || medium[0] || low[0] || null;
});
