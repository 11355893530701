import axios from 'axios';
import { ADMIN_QUEUE, CHAT_QUEUE, CHAT_SERVICE_BASE_URL, INSIGHTS_BASE_URL } from 'config';
import { db } from 'firebase-db';
import download from 'downloadjs';
import maestroApiRequest from '../maestro-api-client';

export const pushToChatQueue = async (key: string, doc: any) => {
  const address = `${CHAT_QUEUE}/${key}`;
  await db.database().ref(address).set(doc);
};
export const pushToAdminQueue = (doc: any) => db.database().ref(ADMIN_QUEUE).push(doc);

// use this to get your message key, which will allow you to know what the key
// is of a message you render locally, and allow you to dedup
export const getChatMessageKey = (): string => {
  const { key } = db.database().ref(CHAT_QUEUE).push();
  return key as string;
};


export const exportChat = async ({
  channelId,
  endTime,
  startTime,
  siteId,
  token,
}: any) => {
  const { data, headers } = await axios({
    headers: {
      Accept: 'text/csv',
      'x-auth-version': 'v2',
    },
    method: 'GET',
    params: {
      from_time: startTime,
      page_id: channelId,
      site_id: siteId,
      to_time: endTime,
      token,
    },
    responseType: 'blob',
    url: `${INSIGHTS_BASE_URL}/query/v1/chat-export`,
  });

  download(new Blob([data]), headers['x-suggested-filename'], 'text/csv');
};

export const getSilencedUsers = async ({
  primaryToken,
  siteId,
  channelId,
}: {
  channelId: string,
  primaryToken: string,
  siteId: string,
}) => {
  const response = await maestroApiRequest({ primaryToken, siteId })
    .get(`${CHAT_SERVICE_BASE_URL}/${channelId}/silenced`);

  if (response.status === 200) {
    return response.data;
  }

  return [];
};
