import {
  ANON_SESSION_INIT,
  IAuthAction,
  DISMISS_LOGIN_ERROR,
  DISMISS_REGISTER_ERROR,
  LOG_IN_FAILURE,
  LOG_IN_SUCCESS,
  LOG_OUT,
  REFRESH_TOKENS,
  REGISTER_USER_FAILURE,
  SET_ACCOUNT_DATA,
  SET_SUPER_ADMIN,
  TRACKING_ACCEPTED,
  SET_ACCOUNT_NAME,
  SET_FOUND_ACCOUNT,
  SET_ACCOUNT_TAGS,
  SET_GLOBAL_ACCOUNT_ADMIN_SITES,
  GLOBAL_ACCOUNTS_LOG_IN_SUCCESS,
  SET_GLOBAL_ACCOUNT,
  RESET_GLOBAL_ACCOUNT,
} from './actions';
import { IAccessTokenData, IGlobalAccountAdminSites, IFoundAccount } from './models';

export interface IAuthState {
  accessToken: string | null;
  account: IAccessTokenData | null;
  autoLogin: boolean | null;
  busy: boolean;
  foundAccount: IFoundAccount | null;
  gdprAccepted: boolean;
  globalAccount: {
    accessToken: string | null;
    account: IAccessTokenData | null;
    adminSites: IGlobalAccountAdminSites[];
  };
  isSuperAdmin: boolean | null;
  loginError: string | null;
  refreshToken: string | null;
  registerError: string | null;
}

const INITIAL_STATE: IAuthState = {
  accessToken: null,
  account: null,
  autoLogin: null,
  busy: true,
  gdprAccepted: false,
  isSuperAdmin: null,
  loginError: null,
  refreshToken: null,
  registerError: null,
  foundAccount: null,
  globalAccount: {
    adminSites: [],
    accessToken: null,
    account: null,
  },
};

export const getInitialState = (): IAuthState => ({
  ...INITIAL_STATE,
});

function warn(...args: any[]): void {
  // tslint:disable-next-line no-console
  console.warn(...args);
}

export default function authReducer(state: IAuthState = INITIAL_STATE, action: IAuthAction): IAuthState {
  switch (action.type) {
    case LOG_IN_FAILURE:
      return {
        ...state,
        accessToken: null,
        account: null,
        autoLogin: null,
        busy: false,
        isSuperAdmin: null,
        loginError: action.payload.message,
        refreshToken: null,
      };
    case LOG_IN_SUCCESS:
      if (state.accessToken) {
        warn('Cannot process LOG_IN_SUCCESS action when already logged in:');
        warn(action);
        return state;
      }
      return {
        ...state,
        accessToken: action.payload.accessToken,
        autoLogin: action.payload.auto,
        busy: false,
        isSuperAdmin: false,
        loginError: null,
        refreshToken: action.payload.refreshToken,
        registerError: null,
      };
    case REGISTER_USER_FAILURE:
      return {
        ...state,
        accessToken: null,
        account: null,
        autoLogin: null,
        busy: false,
        isSuperAdmin: null,
        loginError: null,
        refreshToken: null,
        registerError: action.payload.message,
      };
    case LOG_OUT:
    case ANON_SESSION_INIT:
      if (!state.accessToken && action.type === LOG_OUT) {
        warn('Cannot process LOG_OUT action when already logged out:');
        warn(action);
        return state;
      }
      if (!state.busy && action.type === ANON_SESSION_INIT) {
        warn('Cannot process ANON_SESSION_INIT action when auth is already initialized:');
        warn(action);
        return state;
      }
      return {
        ...state,
        accessToken: null,
        account: null,
        autoLogin: null,
        busy: false,
        isSuperAdmin: null,
        loginError: null,
        refreshToken: null,
        foundAccount: null,
      };
    case REFRESH_TOKENS:
      if (state.refreshToken === action.payload.previousRefreshToken) {
        return {
          ...state,
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
        };
      } else {
        warn('Cannot process REFRESH_TOKENS action due to refresh token mismatch:');
        warn(action);
        return state;
      }
    case SET_ACCOUNT_DATA: {
      if (!state.accessToken) {
        warn('Cannot set account data unless user is logged in');
        return state;
      }
      return {
        ...state,
        account: action.payload,
      };
    }
    case TRACKING_ACCEPTED: {
      return {
        ...state,
        gdprAccepted: true,
      };
    }
    case SET_SUPER_ADMIN: {
      if (!state.accessToken) {
        warn('Cannot set super admin unless user is logged in');
        return state;
      }
      return {
        ...state,
        isSuperAdmin: action.payload,
      };
    }
    case DISMISS_LOGIN_ERROR: {
      return {
        ...state,
        loginError: null,
      };
    }
    case DISMISS_REGISTER_ERROR: {
      return {
        ...state,
        registerError: null,
      };
    }
    case SET_ACCOUNT_NAME: {
      if(state.account !== null){
        return {
          ...state,
          account: {
            ...state.account,
            name: action.payload,
            normalizedName: action.payload,
          },
        };
      }

      return state;
    }
    case SET_FOUND_ACCOUNT: {
      return {
        ...state,
        foundAccount: action.payload,
      };
    }
    case SET_ACCOUNT_TAGS: {
      if(state.account !== null) {
        return {
          ...state,
          account: {
            ...state.account,
            tags: action.payload,
          },
        };
      }

      return state;
    }
    case SET_GLOBAL_ACCOUNT_ADMIN_SITES: {
      return {
        ...state,
        globalAccount: {
          ...state.globalAccount,
          adminSites: action.payload,
        },
      };
    }
    case GLOBAL_ACCOUNTS_LOG_IN_SUCCESS: {
      return {
        ...state,
        globalAccount: {
          ...state.globalAccount,
          accessToken: action.payload.accessToken,
        },
      };
    }
    case SET_GLOBAL_ACCOUNT: {
      return {
        ...state,
        globalAccount: {
          ...state.globalAccount,
          account: action.payload,
        },
      };
    }
    case RESET_GLOBAL_ACCOUNT: {
      return {
        ...state,
        globalAccount: INITIAL_STATE.globalAccount,
      };
    }
    default:
      return state;
  }
}
