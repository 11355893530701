export const REPLACE = 'router/REPLACE';
export const REMOVE_KEYS = 'router/REMOVE_KEYS';

export interface IReplacePayload {
  path?: string;
  query?: Record<string, any>;
}

export interface IReplaceAction {
  payload: IReplacePayload;
  type: typeof REPLACE;
}

export interface IRemoveKeysAction {
  payload: string[];
  type: typeof REMOVE_KEYS;
}

export const replace = ({ path, query }: IReplacePayload): IReplaceAction => ({
  payload: { path, query },
  type: REPLACE,
});

export const removeKeys = (keys: string[]): IRemoveKeysAction => ({
  payload: keys,
  type: REMOVE_KEYS,
});
