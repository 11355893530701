import { push } from 'connected-react-router';
import qs from 'querystring';
import { put, select, takeEvery } from 'redux-saga/effects';
import IState from '../state';
import { IRemoveKeysAction, IReplaceAction, REMOVE_KEYS, REPLACE, replace } from './actions';
import { getQuery } from './selectors';

export const handleRouteChange = function* ({ payload }: IReplaceAction) {
  const state: IState = yield select();
  const currentQuery = getQuery(state);
  const { router: { location: { pathname } } } = state;
  const { path, query = {} } = payload;
  const newPath = path || pathname || '/';
  const newQuery = { ...currentQuery, ...query };

  for (const param in newQuery) {
    if ({}.hasOwnProperty.call(newQuery, param) && (newQuery[param] === undefined)) {
      delete newQuery[param];
    }
  }
  yield put(push(`${newPath}?${qs.stringify(newQuery)}`));
};

export const removeKeysSaga = function* ({ payload }: IRemoveKeysAction) {
  const state: IState = yield select();
  const currentQuery = getQuery(state);

  // no need to trigger a route change if the keys are not in the current query
  if (!payload.some((key) => key in currentQuery)) {
    return;
  }

  const query = payload.reduce((obj, key) => ({
    ...obj,
    [key]: undefined,
  }), {});

  yield put(replace({ query }));
};

const appRouterSaga = function* () {
  yield takeEvery(REMOVE_KEYS, removeKeysSaga);
  yield takeEvery(REPLACE, handleRouteChange);
};

export default appRouterSaga;
