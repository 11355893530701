import produce from 'immer';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import shortid from 'shortid';
import { ActionType, SubMenuKey } from 'components/admin-bridge/AdminBar/constants';
import IGate, { GateKind } from 'models/IGate';
import {
  CLEAR_EDIT_TARGET,
  SET_ADMIN_MODE,
  SET_EDIT_TARGET,
  SET_PENDING_ADMIN_CHANGE,
  RESET_ALL_PENDING_ADMIN_CHANGES,
  RESET_PENDING_ADMIN_CHANGE,
  SET_REGION_SAVED,
  SET_REGION_RENDERER_DRAFT,
  CLEAR_REGION_RENDERER_DRAFT,
  WRITE_LEGACY_SUCCESS,
  SET_PREVIEW_CARD_RENDERER,
  SET_PREVIEW_PANEL_RENDERER,
  SET_PENDING_PAGE_DOC,
  CLEAR_PENDING_PAGE_DOC,
  SET_MOCK_PANEL_RENDERER,
  SET_MOCK_CARD_RENDERER,
  SET_MAESTRO_LIVESTREAM_PREVIEW,
  SET_DRAFT_THEME,
  UPDATE_REFRESH_KEY,
  ENABLE_PANELS,
  DISABLE_PANELS,
  ADD_ADMIN_MODAL,
  POP_ADMIN_MODAL,
  SET_HAS_UNSAVED_CHANGES,
  SET_ACTIVE_ACTION,
  SET_ADMIN_BAR_SUB_MENU_QUEUE,
  PUSH_ADMIN_BAR_SUB_MENU_KEY,
  POP_ADMIN_BAR_SUB_MENU_KEY,
  CLOSE_PANELS,
  SET_ACTIVE_ACTION_TYPE,
  SET_CURRENT_SIDEBAR_PAGE,
  CLEAR_PENDING_THEMES_DOCS,
  SET_PENDING_THEME_DOC,
  SET_PREVIEW_ACTIVE_THEME,
  SET_GATE_TITLE_CHANGES,
  SET_GATE_SUBTITLE_CHANGES,
  SET_GATE_DATE_AND_TIME,
  SET_GATE_NAVIGATION,
  SET_GATE_LOGO,
  SET_GATE_BACKGROUND,
  SET_GATE_KIND_INFORMATION,
  SET_IS_EDITING_GATE_LOGO,
  CLEAR_PENDING_GATE_CHANGE,
  ADMIN_BAR_NAVIGATION,
  CLEAR_PENDING_PANEL_CHANGE,
  SET_PENDING_PANEL_CHANGE,
  CREATE_PANEL,
  CREATE_PANEL_SUCCESS,
  CREATE_PANEL_ERROR,
  SET_ADMIN_BAR_NOTIFICATIONS,
  DISPLAY_ADMIN_BAR_NOTIFICATIONS_ALERT,
  DISPLAY_ADMIN_BAR_NUMERIC_NOTIFICATION,
  CLEAR_ADMIN_BAR_NOTIFICATION,
  PUBLISH_PENDING_CHANGES,
} from './actions';

import { ActionKey, ModeTypes, TargetTypes } from './constants';
import { ITheme, IObject } from 'models';
import IPanel, { IRenderer } from 'models/IPanel';

const MOCK_GATE_DATA = {
  kind: GateKind.PASSWORD,
  gate: {},
} as IGate;

type PanelRendererMap = Record<string, IPanel<{}>['renderer']>;

export interface IAdminState {
  activeAction: ActionKey | null;
  activeTab?: string | null;
  activeType?: ActionType | null;
  adminBarNotifications: IAdminBarNotifications;
  adminBarSubMenuQueue: SubMenuKey[];
  adminMode: null | string;
  creatingKindPanel: null | string,
  creatingPanelAction: 'enable' | 'edit' | null,
  currentSidebarPage: null | string;
  editModeTarget: TargetTypes | null;
  hasUnsavedChanges: boolean;
  isCreatingPanel: boolean,
  isEditingGateLogo: boolean,
  isPublishingPendingChanges: boolean;
  livestreamPreviewActive: boolean;
  mockCardRenderer: null | Record<string, any>;
  mockPanelRenderer: null | Record<string, any>;
  mockThemeRenderer: null | ITheme;
  modalCount: number;
  panelsActive?: boolean;
  pendingAdminChanges?: Record<TargetTypes, any>;
  pendingGateChanges?: Record<string, IGate>;
  pendingPageDocs: Record<string, IObject>;
  pendingPanelChanges: Record<string, PanelRendererMap>;
  pendingThemesDocs: Record<string, ITheme>;
  previewActiveTheme: ITheme | null;
  previewCardRenderer: null;
  previewPanelRenderer: IRenderer | null;
  refreshKey: string;
  regionRendererDrafts: Record<string, any>;
}

export interface NotificationDetails {
  isAlert: boolean;
  isHidden: boolean;
  notificationsAmount: number;
}

export type IAdminBarNotifications = {
  [K in ActionKey]: NotificationDetails;
};

function initializeAdminBarNotifications(): IAdminBarNotifications {
  const notifications: Partial<IAdminBarNotifications> = {};

  for (const key of Object.keys(ActionKey)) {
    notifications[key as ActionKey] = {
      isHidden: true,
      isAlert: false,
      notificationsAmount: 0,
    };
  }

  return notifications as IAdminBarNotifications;
}

export const getInitialState = (): IAdminState => ({
  activeAction: null,
  activeTab: null,
  activeType: null,
  adminBarSubMenuQueue: ['main'],
  adminBarNotifications: initializeAdminBarNotifications(),
  adminMode: null,
  currentSidebarPage: null,
  editModeTarget: null,
  hasUnsavedChanges: false,
  creatingKindPanel: null,
  creatingPanelAction: null,
  isCreatingPanel: false,
  isEditingGateLogo: false,
  livestreamPreviewActive: false,
  mockCardRenderer: null,
  mockPanelRenderer: null,
  mockThemeRenderer: null,
  modalCount: 0,
  panelsActive: false,
  pendingAdminChanges: {} as Record<TargetTypes, any>,
  pendingPageDocs: {},
  pendingGateChanges: {},
  pendingThemesDocs: {},
  pendingPanelChanges: {},
  previewActiveTheme: null,
  previewCardRenderer: null,
  previewPanelRenderer: null,
  refreshKey: shortid.generate(),
  regionRendererDrafts: {},
  isPublishingPendingChanges: false,
});

// TODO: Wrap the whole thing in `produce`
const adminReducer = (state: IAdminState = getInitialState(), { payload, type }) => {
  switch (type) {
    case ADD_ADMIN_MODAL: {
      return {
        ...state,
        modalCount: state.modalCount + 1,
      };
    }
    case POP_ADMIN_MODAL: {
      return {
        ...state,
        modalCount: Math.max(state.modalCount - 1, 0),
      };
    }
    case SET_ADMIN_BAR_SUB_MENU_QUEUE: {
      return {
        ...state,
        adminBarSubMenuQueue: payload,
      };
    }
    case PUSH_ADMIN_BAR_SUB_MENU_KEY: {
      const currentSubMenuKey = state.adminBarSubMenuQueue[state.adminBarSubMenuQueue.length - 1];
      return currentSubMenuKey === payload ? state : {
        ...state,
        adminBarSubMenuQueue: [...state.adminBarSubMenuQueue, payload],
      };
    }
    case POP_ADMIN_BAR_SUB_MENU_KEY: {
      return state.adminBarSubMenuQueue.length === 1 ? state : {
        ...state,
        adminBarSubMenuQueue: state.adminBarSubMenuQueue.slice(0, -1),
      };
    }
    case ADMIN_BAR_NAVIGATION: {
      const { activeAction, adminBarSubMenuQueue } = payload;
      return {
        ...state,
        activeAction,
        adminBarSubMenuQueue,
      };
    }
    case SET_ACTIVE_ACTION: {
      return {
        ...state,
        activeAction: payload,
      };
    }
    case SET_ACTIVE_ACTION_TYPE: {
      return {
        ...state,
        activeType: payload,
        currentSidebarPage: !payload ? null : state.currentSidebarPage,
      };
    }
    case SET_HAS_UNSAVED_CHANGES: {
      return {
        ...state,
        hasUnsavedChanges: payload.hasUnsavedChanges,
      };
    }
    case SET_ADMIN_MODE: {
      return {
        ...state,
        activeTab: (!state.adminMode || payload.modeName) ? state.activeTab : null,
        adminMode: payload.modeName,
        livestreamPreviewActive: payload.modeName === 'maestroStream',
        panelsActive: false,
      };
    }
    case DISABLE_PANELS: {
      return {
        ...state,
        adminMode: null,
        editModeTarget: null,
        panelsActive: false,
      };
    }
    case CLOSE_PANELS: {
      return {
        ...state,
        editModeTarget: null,
        panelsActive: false,
      };
    }
    case SET_REGION_SAVED: {
      const { targetType } = payload;
      const targetData = state.pendingAdminChanges![targetType];
      return {
        ...state,
        pendingAdminChanges: {
          ...state.pendingAdminChanges,
          [targetType]: {
            ...targetData,
            saved: true,
          },
        },
      };
    }
    case SET_EDIT_TARGET:
      return {
        ...state,
        editModeTarget: payload.targetName,
        mockCardRenderer: null,
        mockPanelRenderer: null,
        previewCardRenderer: null,
        previewPanelRenderer: null,
      };
    case ENABLE_PANELS:
      return {
        ...state,
        adminMode: payload.isStudioLive ? ModeTypes.STUDIO_STREAM : ModeTypes.EDIT,
        editModeTarget: TargetTypes.SIDEBAR,
        mockCardRenderer: null,
        mockPanelRenderer: null,
        panelsActive: true,
        previewCardRenderer: null,
        previewPanelRenderer: null,
      };
    case RESET_ALL_PENDING_ADMIN_CHANGES:
      return {
        ...state,
        pendingAdminChanges: {},
      };
    case RESET_PENDING_ADMIN_CHANGE: {
      const { targetType } = payload as { targetType: TargetTypes };
      const { [targetType]: value, ...withoutTarget } = state.pendingAdminChanges!;
      return {
        ...state,
        pendingAdminChanges: withoutTarget,
      };
    }
    case SET_PENDING_ADMIN_CHANGE: {
      const { clearTarget, targetType, pendingData } = payload;
      const targetData = state.pendingAdminChanges![targetType];
      const localData = targetData?.localData;

      if (!isEqual(localData, pendingData)) {
        return {
          ...state,
          editModeTarget: clearTarget ? null : state.editModeTarget,
          pendingAdminChanges: {
            ...state.pendingAdminChanges,
            [targetType]: {
              ...targetData,
              pendingData,
            },
          },
        };
      }
      // The pending changes are the same as the original data
      return {
        ...state,
        pendingAdminChanges: {
          ...state.pendingAdminChanges,
          [targetType]: {
            localData,
          },
        },
      };
    }
    case CLEAR_EDIT_TARGET: {
      const {
        editModeTarget,
        ...newState
      } = state;
      const { hasUnsavedChanges } = state;

      return hasUnsavedChanges ? {
        ...state,
        editModeTarget: undefined,
        mockCardRenderer: null,
        mockPanelRenderer: null,
        mockThemeRenderer: null,
        previewCardRenderer: null,
        previewPanelRenderer: null,
      } : produce(newState, (draft) => {
        draft.previewCardRenderer = null;
        draft.mockPanelRenderer = null;
        draft.mockCardRenderer = null;
        draft.previewPanelRenderer = null;
      });
    }
    // renderers
    case SET_REGION_RENDERER_DRAFT:
      return produce(state, (draft) => {
        const { id, renderer } = payload;
        draft.regionRendererDrafts[id] = renderer;
      });
    case CLEAR_REGION_RENDERER_DRAFT:
      return produce(state, (draft) => {
        const { id } = payload;
        delete draft.regionRendererDrafts[id];
      });
    case WRITE_LEGACY_SUCCESS:
      return {
        ...state,
        refreshKey: shortid.generate(),
        isPublishingPendingChanges: false,
      };
    case UPDATE_REFRESH_KEY:
      return {
        ...state,
        refreshKey: shortid.generate(),
      };
    case SET_PREVIEW_CARD_RENDERER:
      return {
        ...state,
        mockCardRenderer: null,
        previewCardRenderer: payload.cardRenderer,
      };
    case SET_PREVIEW_ACTIVE_THEME:
      return {
        ...state,
        previewActiveTheme: payload.theme,
      };
    case SET_PREVIEW_PANEL_RENDERER:
      return {
        ...state,
        mockPanelRenderer: null,
        previewPanelRenderer: payload.panelRenderer,
      };
    case SET_PENDING_PAGE_DOC:
      return produce(state, (draft) => {
        const { id, doc } = payload;
        if (id) {
          draft.pendingPageDocs[id] = doc;
        }
      });
    case SET_PENDING_THEME_DOC:
      return produce(state, (draft) => {
        const { id, doc } = payload;
        if (id) {
          draft.pendingThemesDocs[id] = doc;
        }
      });
    case CLEAR_PENDING_PAGE_DOC: {
      const { id } = payload;
      return {
        ...state,
        pendingPageDocs: omit(state.pendingPageDocs, id),
      };
    }
    case CLEAR_PENDING_GATE_CHANGE: {
      const { id } = payload;
      return {
        ...state,
        pendingGateChanges: omit(state.pendingPageDocs, id),
      };
    }
    case SET_PENDING_PANEL_CHANGE: {
      return produce(state, (draft) => {
        const { pageId, kind, panel } = payload;
        if (state.pendingPanelChanges[pageId]) {
          draft.pendingPanelChanges[pageId][kind] = panel;
        } else {
          draft.pendingPanelChanges[pageId] = {
            [kind]: panel,
          };
        }
      });
    }
    case CLEAR_PENDING_PANEL_CHANGE: {
      const { pageId } = payload;
      return {
        ...state,
        pendingPanelChanges: omit(state.pendingPanelChanges, pageId),
      };
    }
    case CLEAR_PENDING_THEMES_DOCS:
      return {
        ...state,
        pendingThemesDocs: {},
      };
    case SET_MOCK_PANEL_RENDERER:
      return {
        ...state,
        mockPanelRenderer: payload,
      };
    case SET_MOCK_CARD_RENDERER:
      return {
        ...state,
        mockCardRenderer: payload,
      };
    case SET_DRAFT_THEME:
      return {
        ...state,
        mockThemeRenderer: payload,
      };
    case SET_MAESTRO_LIVESTREAM_PREVIEW:
      return {
        ...state,
        livestreamPreviewActive: Boolean(payload),
      };
    case SET_CURRENT_SIDEBAR_PAGE: {
      return {
        ...state,
        currentSidebarPage: payload,
      };
    }
    case SET_GATE_TITLE_CHANGES:
      return produce(state, (draft) => {
        const { id, styledTitle, field } = payload;
        if (id && state.pendingPageDocs[id]) {
          draft.pendingPageDocs[id].data.gate.gate[field] = styledTitle;
        }
      });
    case SET_GATE_SUBTITLE_CHANGES:
      return produce(state, (draft) => {
        const { id, styledSubtitle, field } = payload;
        if (id && state.pendingPageDocs[id]) {
          draft.pendingPageDocs[id].data.gate.gate[field] = styledSubtitle;
        }
      });
    case SET_GATE_DATE_AND_TIME:
      return produce(state, (draft) => {
        const { id, dateAndTime } = payload;
        if (id && state.pendingPageDocs[id]) {
          draft.pendingPageDocs[id].data.gate.gate.subscriptionTimestamp = dateAndTime;
        }
      });
    case SET_GATE_NAVIGATION:
      return produce(state, (draft) => {
        const { id, navigation } = payload;
        if (id && state.pendingPageDocs[id]) {
          draft.pendingPageDocs[id].data.gate.gate.navigation = navigation;
        }
      });
    case SET_GATE_LOGO:
      return produce(state, (draft) => {
        const { id, logoData } = payload;
        if (id && state.pendingPageDocs[id]) {
          draft.pendingPageDocs[id].data.gate.gate = {
            ...state.pendingPageDocs[id].data.gate.gate,
            ...logoData,
          };
        }
      });
    case SET_GATE_BACKGROUND:
      return produce(state, (draft) => {
        const { id, backgroundData } = payload;
        if (id && state.pendingPageDocs[id]) {
          draft.pendingPageDocs[id].data.gate.gate.background = backgroundData;

          // To handle legacy background deprecated in Channels 2.0
          if (!backgroundData?.desktopImage) {
            draft.pendingPageDocs[id].data.gate.gate.subscriptionGateBackgroundWeb = undefined;
          }
          if (!backgroundData?.mobileImage) {
            draft.pendingPageDocs[id].data.gate.gate.subscriptionGateBackgroundMobile = undefined;
          }
        }
      });
    case PUBLISH_PENDING_CHANGES:
      return {
        ...state,
        isPublishingPendingChanges: true,
      };
    case SET_GATE_KIND_INFORMATION:
      return produce(state, (draft) => {
        const { id, kind, password, subscriptionsData, amazonBenefitData } = payload;
        if (id && state.pendingPageDocs[id]) {
          // 1. Set Gate Kind (Login, Subscriptions, Password)
          draft.pendingPageDocs[id].data.gate.kind = kind;

          /**
           * 2. Set Password information
           * We need to set a mock data, before to set the password to avoid
           */
          if (!state.pendingGateChanges![id]) {
            draft.pendingGateChanges![id] = MOCK_GATE_DATA;
          }
          draft.pendingGateChanges![id].gate.password = password;

          // 3. Set Subscription Data
          if (subscriptionsData) {
            draft.pendingPageDocs[id].data.gate.gate.subscriptions =
              subscriptionsData.subscriptions;
            draft.pendingPageDocs[id].data.gate.gate.bundles =
              subscriptionsData.bundles;
            draft.pendingPageDocs[id].data.gate.gate.hiddenEntitlements =
              subscriptionsData.hiddenEntitlements;
            draft.pendingPageDocs[id].data.gate.gate.hiddenBundles =
              subscriptionsData.hiddenBundles;
          }

          if (kind === 'amazon_benefit' && amazonBenefitData) {
            draft.pendingPageDocs[id].data.gate.gate.amazonBenefitData = amazonBenefitData;
          }
        }
      });
    case SET_IS_EDITING_GATE_LOGO:
      return {
        ...state,
        isEditingGateLogo: payload,
      };
    // CREATION PANEL FLOW
    case CREATE_PANEL:
      return produce(state, (draft) => {
        const { kind, action } = payload;
        draft.isCreatingPanel = true;
        draft.creatingKindPanel = kind;
        draft.creatingPanelAction = action;
      });
    case CREATE_PANEL_SUCCESS:
    case CREATE_PANEL_ERROR:
      return produce(state, (draft) => {
        draft.isCreatingPanel = false;
        draft.creatingKindPanel = null;
        draft.creatingPanelAction = null;
      });
    case SET_ADMIN_BAR_NOTIFICATIONS:
      return {
        ...state,
        adminBarNotifications: {
          ...state.adminBarNotifications,
          [payload.type]: {
            ...payload.notification,
          },
        },
      };
    case DISPLAY_ADMIN_BAR_NOTIFICATIONS_ALERT:
      return {
        ...state,
        adminBarNotifications: {
          ...state.adminBarNotifications,
          [payload.type]: {
            ...state.adminBarNotifications[payload.type],
            isAlert: true,
            isHidden: false,
          },
        },
      };
    case DISPLAY_ADMIN_BAR_NUMERIC_NOTIFICATION:
      return {
        ...state,
        adminBarNotifications: {
          ...state.adminBarNotifications,
          [payload.type]: {
            ...state.adminBarNotifications[payload.type],
            notificationsAmount: payload.count,
            isHidden: false,
          },
        },
      };
    case CLEAR_ADMIN_BAR_NOTIFICATION:
      return {
        ...state,
        adminBarNotifications: {
          ...state.adminBarNotifications,
          [payload.type]: {
            notificationsAmount: 0,
            isAlert: false,
            isHidden: true,
          },
        },
      };
    default:
      return state;
  }
};

export default adminReducer;
