import { getObjectId } from 'services/app/selectors/common';
import { isDesktopLayout } from 'services/device/selectors';
import { getDocument } from 'services/realtime/selectors';
import { ID_SIDEBAR } from 'services/renderer/ids';
import { getRenderer } from 'services/renderer/selectors';
import { getOwn } from 'utils';
import { CARD_COLLAPSED, CARD_OPEN } from './constants';

export const getCardKey = (state, cardDoc) => {
  // TODO: Some of this fallback stuff is probably not strictly necessary
  const objectId = getObjectId(state) || '?';
  const cardId = cardDoc?.renderer?.id || cardDoc?._id || '?';
  const cardKind = cardDoc?.renderer?.cardType || cardDoc?.data?.kind || '?';
  return `object:${objectId}-card:${cardId}-kind:${cardKind}`;
};

export const getCardState = (state, cardKey) => {
  const { stateMap } = state.card;
  return getOwn(stateMap, cardKey) || (
    isDesktopLayout(state) ?
      CARD_OPEN :
      CARD_COLLAPSED
  );
};

export const isCurrentCardOpen = (state) => {
  const { cardId } = getRenderer(state, ID_SIDEBAR) || {}; // TODO: TEST
  if (!cardId) {
    return false;
  }
  const cardDoc = getDocument(state, 'objects', cardId);
  if (!cardDoc) {
    return false;
  }
  const cardKey = getCardKey(state, cardDoc);
  return getCardState(state, cardKey) === CARD_OPEN;
};
