import { ICustomerInfo } from './models';

export const UPDATE_CUSTOMER_INFO = 'customerProfile/UPDATE_CUSTOMER_INFO';
export const SET_CUSTOMER_INFO = 'customerProfile/SET_CUSTOMER_INFO';
export const GET_CUSTOMER_INFO = 'customerProfile/GET_CUSTOMER_INFO';

export interface ISetCustomerInfoAction {
  payload: ICustomerInfo,
  type: typeof SET_CUSTOMER_INFO,
}

export type ICustomerProfileAction = (
  ISetCustomerInfoAction
);

export const setCustomerInfo = (payload: ICustomerInfo): ISetCustomerInfoAction => ({
  payload,
  type: SET_CUSTOMER_INFO,
});
