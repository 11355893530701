import { ICustomerProfileAction, SET_CUSTOMER_INFO } from './actions';
import { ICustomerProfileState } from './models';

const INITIAL_STATE: ICustomerProfileState = {
  accessInstructions: '',
  support: {
    email: '',
    URL: '',
  },
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

const customerProfileReducer = (state: ICustomerProfileState = INITIAL_STATE, action: ICustomerProfileAction) => {
  switch (action.type) {
    case SET_CUSTOMER_INFO: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default customerProfileReducer;
