import ISubscription from 'models/ISubscription';
import defaultLogo from 'assets/default-logo.png';
import defaultBackground from 'assets/access_control_default_bg.jpg';
import { MAESTRO_BLACK, MAESTRO_WHITE } from 'style/constants';
import { DEFAULT_GATE_TITLE, DEFAULT_GATE_SUBTITLE } from 'shared/constants';
import { getDefaultRichTextEditorData } from 'utils';
import IBundle from './IBundle';
import { injectT } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';
import { AmazonBenefitData } from 'components/admin2/AccessControlModal/AmazonBenefitGateSettings';
import { getDefaultEditorState, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';

export enum GateKind {
  LOGIN = 'login',
  PASSWORD = 'password',
  SUBSCRIPTION = 'subscription',
  ACCESSCODE = 'access_code',
  AMAZONBENEFIT = 'amazon_benefit',
  NONE = 'none',
}

export enum GateBackgroundEnum {
  image = 'image',
  color = 'color',
}

export interface IAccessGateBackground {
  color?: string;
  desktopImage?: string;
  mobileImage?: string;
  opacity?: number;
  type: GateBackgroundEnum;
  useGradientMask?: boolean;
}

export interface INavigationLegacyFromGate {
  gateSpecific?: boolean;
  items?: {
    children?: {
      link?: string | undefined;
      text?: string | undefined;
    }[] | undefined;
    hasMenu?: string | undefined;
    image?: string | undefined;
    link?: string | undefined;
    text?: string;
  }[] | undefined;
  state: string;
}

export interface ISubscriptionData {
  background?: IAccessGateBackground;
  bundles: IBundle[];
  hiddenBundles: string[];
  hiddenEntitlements: string[];
  logoLink?: string;
  logoTitle?: string;
  logoUrl?: string;
  navigation: INavigationLegacyFromGate;
  subscriptions: ISubscription[];
  subtitle: string;
  subtitleRawData: string;
  subtitleRawDataV2: string;
  timestamp: number | null;
  title: string;
  titleRawData: string;
  titleRawDataV2: string;
}

export interface IMaxPreviewDuration {
  previewDurationSeconds: number;
}

export interface ILogoData {
  logoLink?: string;
  logoTitle?: string;
  logoUrl?: string;
}

export default interface IGate {
  active: boolean;
  gate: {
    amazonBenefitData?: AmazonBenefitData;
    background?: IAccessGateBackground;
    bundles: IBundle[];
    hiddenBundles: string[];
    hiddenEntitlements: string[];
    loginMessage: string;
    logoLink?: string;
    logoTitle?: string;
    logoUrl?: string;
    name: string;
    navigation: INavigationLegacyFromGate;
    password?: string;
    subscriptions?: ISubscription[];
    subtitleRawData: string;
    subtitleRawDataV2?: string;
    titleRawData: string;
    titleRawDataV2?: string;
    } & {
      /* Information deprecated in Channels 2.0 */
      subscriptionGateBackgroundMobile?: string;
      subscriptionGateBackgroundWeb?: string;
      subscriptionSubtitle: string;
      subscriptionTimestamp: number | null;
      subscriptionTitle: string;
      /**
       * Fields title and subtitle were deprecated on NS-6418.
       * We just read html or rawData stored in titleRawData or subtitleRawData
       */
      subtitle: string;
      title: string;
    }
  kind: GateKind;
}

export const defaultGateHeaderData = injectT(
  (t) => lazy(
    () => ({
      logoUrl: defaultLogo,
      logoTitle: t('ADMIN_PLACEHOLDER_YOUR_BRAND'),
      navigation: {
        items: [],
        state: 'on',
      },
    }),
  ),
);

export const defaultGateBackgroundData = {
  type: GateBackgroundEnum.image,
  color: MAESTRO_BLACK,
  opacity: 1,
  useGradientMask: false,
  desktopImage: defaultBackground,
  mobileImage: defaultBackground,
} as IGate['gate']['background'];

export const defaultGateInformation = injectT(
  (t) => lazy(
    (): IGate['gate'] => ({
      logoUrl: defaultGateHeaderData(t).logoUrl,
      logoTitle: defaultGateHeaderData(t).logoTitle,
      logoLink: '',
      navigation: {
        ...defaultGateHeaderData(t).navigation,
        items: [
          ...defaultGateHeaderData(t).navigation.items,
        ],
      },
      background: {
        ...defaultGateBackgroundData as IAccessGateBackground,
      },
      title: DEFAULT_GATE_TITLE(t),
      titleRawData: getDefaultRichTextEditorData({
        text: DEFAULT_GATE_TITLE(t),
        color: MAESTRO_WHITE,
        fontSize: 30,
      }),
      titleRawDataV2: getDefaultEditorState([
        getDefaultTextNode({
          text: DEFAULT_GATE_TITLE(t),
          fontSize: 30,
        }),
      ]),
      subtitle: DEFAULT_GATE_SUBTITLE(t),
      subtitleRawData: getDefaultRichTextEditorData({
        text: DEFAULT_GATE_SUBTITLE(t),
        fontSize: 14,
      }),
      subtitleRawDataV2: getDefaultEditorState([
        getDefaultTextNode({
          text: DEFAULT_GATE_SUBTITLE(t),
          fontSize: 14,
        }),
      ]),
      hiddenEntitlements: [],
      hiddenBundles: [],
      subscriptions: [],
      bundles: [],
      loginMessage: '',
      name: '',
      subscriptionSubtitle: '',
      subscriptionTitle: '',
      subscriptionTimestamp: null,
    }),
  ),
);

export const defaultGateData = {
  active: false,
  gate: {
    ...defaultGateInformation,
  },
  kind: GateKind.NONE,
} as IGate;
