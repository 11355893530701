import {
  UPDATE_SAVED,
  CREATE_API_KEY,
  CREATED_API_KEY,
  STOP_IS_GENERATING_API_KEY,
  DELETE_API_KEY,
  DELETE_API_KEY_FAILED,
  DELETED_API_KEY,
  FAILED_TO_FIND_API_KEY,
  FOUND_API_KEY,
  FIND_API_KEY,
  FOUND_WEBHOOKS,
  INSERTED_WEBHOOKS,
  UPDATED_WEBHOOKS,
  WEBHOOK_URL_CHANGED,
  RESET_WEBHOOKS,
  FAILED_TO_FIND_WEBHOOKS,
  SET_CODE_INJECTION,
} from './actions';
import { IApiKey, ICodeInjection, IWebhooks } from './models';

export interface IDeveloperState {
  apiKey: {
    data: IApiKey | null
    hasApiKeyData: boolean,
    isDeleting: boolean,
    isGenerating: boolean,
    loadingApiKeyData: boolean,
  };
  codeInjection?: ICodeInjection;
  saved: boolean;
  webhooks: {
    data: IWebhooks,
    webhookUrlHasChanged: boolean,
  };
}

const INITIAL_STATE: IDeveloperState = {
  saved: false,
  codeInjection: {
    body: '',
    header: '',
  },
  apiKey: {
    data: null,
    hasApiKeyData: false,
    isDeleting: false,
    isGenerating: false,
    loadingApiKeyData: false,
  },
  webhooks: {
    data: {
      enabled: false,
      webhookSubscriptions: [],
      webhookUrl: '',
    },
    webhookUrlHasChanged: false,
  },
};

export const getInitialState = () => ({
  ...INITIAL_STATE,
});

export default function developerSettingReducer(state: IDeveloperState = INITIAL_STATE, action: any): IDeveloperState {
  const { payload, type } = action;
  switch (type) {
    case UPDATE_SAVED: {
      return {
        ...state,
        saved: payload,
      };
    }
    case FOUND_WEBHOOKS: {
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          data: payload,
        },
      };
    }
    case INSERTED_WEBHOOKS: {
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          data: payload,
        },
      };
    }
    case UPDATED_WEBHOOKS: {
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          data: payload,
        },
      };
    }
    case WEBHOOK_URL_CHANGED: {
      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          webhookUrlHasChanged: payload,
        },
      };
    }
    case FAILED_TO_FIND_WEBHOOKS:
    case RESET_WEBHOOKS: {
      return {
        ...state,
        webhooks: {
          data: {
            enabled: false,
            webhookSubscriptions: [],
            webhookUrl: '',
          },
          webhookUrlHasChanged: false,
        },
      };
    }
    case FIND_API_KEY: {
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          data: null,
          loadingApiKeyData: true,
        },
      };
    }
    case FOUND_API_KEY: {
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          data: payload,
          loadingApiKeyData: false,
        },
      };
    }
    case FAILED_TO_FIND_API_KEY: {
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          data: null,
          loadingApiKeyData: false,
        },
      };
    }
    case CREATE_API_KEY: {
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          isGenerating: true,
        },
      };
    }
    case CREATED_API_KEY: {
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          data: payload,
          isGenerating: false,
        },
      };
    }
    case STOP_IS_GENERATING_API_KEY: {
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          data: null,
          isGenerating: false,
        },
      };
    }
    case DELETE_API_KEY: {
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          isDeleting: true,
        },
      };
    }
    case DELETED_API_KEY: {
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          data: null,
          hasApiKeyData: false,
          isDeleting: false,
        },
      };
    }
    case DELETE_API_KEY_FAILED: {
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          isDeleting: false,
        },
      };
    }
    case SET_CODE_INJECTION: {
      const { type: injectionType, value } = payload;
      return {
        ...state,
        codeInjection: {
          ...state.codeInjection,
          [injectionType]: value,
        },
      };
    }
    default:
      return state;
  }
}
