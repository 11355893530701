import { prefix } from 'redux-utils';

const action = prefix('card');

export const SET_CARD_STATE = action('SET_CARD_STATE');

export const setCardState = (cardKey, cardState) => ({
  payload: {
    cardKey,
    cardState,
  },
  type: SET_CARD_STATE,
});
