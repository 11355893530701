import ISubscription from 'models/ISubscription';
import IModel from 'models/IModel';

export enum PlaylistTypes {
  youtubePlaylist = 'youtube_playlist',
}

export interface IVideoMetadata extends IModel {
  description?: string;
  /**
   * default 0 seconds
   */
  durationSeconds: number | null;
  player: PlayerTypes;
  publishedDate?: string;
  thumbnail?: string;
  title?: string;
  type: VideoTypes | PlaylistTypes;
  url: string;
}

export default interface IVideo extends IModel {
  _id?: string;
  description?: string;
  durationSeconds: number;
  liveBroadcastId?: string;
  player?: PlayerTypes;
  siteId: string;
  subscriptions?: ISubscription[];
  thumbnail?: string | null;
  title: string;
  type: VideoTypes;
  /**
   * this is actually the **TRANSCODING** status
   */
  uploadStatus?: TranscodingStatuses;
  url?: string;
}

export enum TranscodingStatuses {
  pending = 'pending',
  complete = 'complete',
}

export enum VideoTypes {
  vod = 'vod',
  livestream = 'livestream',
}

export enum PlayerTypes {
  dailymotion = 'dailymotion',
  facebook = 'facebook',
  file = 'file',
  iframe = 'iframe',
  mixcloud = 'mixcloud',
  livestream = 'livestream', // MaestroFilePlayer
  soundcloud = 'soundcloud',
  streamable = 'streamable',
  twitch = 'twitch',
  vimeo = 'vimeo',
  wistia = 'wistia',
  youtube = 'youtube',
}

export const isUploadedVod = (obj: any): boolean => {
  return obj &&
    'uploadStatus' in obj &&
    'type' in obj && obj.type === 'vod' &&
    'player' in obj && obj.player === 'file';
};

export const DEFAULT_VIDEO_PLAYER = PlayerTypes.file;
