import { ExportStatus, FilterModalTypes, IExportFile, IEntitlementItem, Views, IFilter, SearchByTypes } from './reducer';

export const SET_VIEW = 'community/SET_VIEW';
export const SET_USER_SEARCH_QUERY = 'community/SET_USER_SEARCH_QUERY';
export const SET_USER_SORT_QUERY = 'community/SET_USER_SORT_QUERY';
export const SET_USER_FILTER_MODAL_OPTION = 'community/SET_USER_FILTER_MODAL_OPTION';
export const SET_USER_FILTER = 'community/SET_USER_FILTER';
export const SET_USER_SEARCH_BY = 'community/SET_USER_SEARCH_BY';
export const SET_ACTIVE_PAGE = 'community/UPDATE_USER_TABLE_OPTIONS';
export const UPDATE_USER_PROFILE = 'communinty/UPDATE_USER_PROFILE';
export const SHOW_MODAL = 'community/SHOW_MODAL';
export const CLOSE_MODAL = 'community/CLOSE_MODAL';
export const SET_GRANT = 'community/SET_GRANT';
export const UPDATE_ENTITLEMENT = 'community/UPDATE_ENTITLEMENT';
export const EXPORT_COMMUNITY = 'community/EXPORT_COMMUNITY';
export const SET_EXPORT_STATUS = 'community/SET_EXPORT_STATUS';
export const SET_EXPORT_FILE = 'community/SET_EXPORT_FILE';
export const SET_EXPORT_FILE_LINK = 'community/SET_EXPORT_FILE_LINK';
export const DOWNLOAD_EXPORT_FILE = 'community/DOWNLOAD_EXPORT_FILE';
export const RESET = 'community/RESET';

export type ICommunityAction = (
  ISetViewAction |
  ISetSearchQueryAction |
  ISetSortAction |
  ISetFilterModalOptionAction |
  ISetFilterAction |
  ISetSearchByAction |
  ISetActivePageAction |
  ISetExportStatusAction |
  ISetExportFileAction |
  ISetExportFileLinkAction |
  IUpdateUserProfileAction |
  IShowModalAction |
  ICloseModalAction |
  IExportCommunityAction |
  IResetAction
);
export interface ISetViewAction {
  payload: Views;
  type: typeof SET_VIEW;
}

interface SearchQueryPayload {
  queryDescription?: string;
  searchQuery: string;
}

export interface ISetSearchQueryAction {
  payload: SearchQueryPayload;
  type: typeof SET_USER_SEARCH_QUERY;
}

interface SetSortPayload {
  sortBy: string,
  sortDirection: number
}
export interface ISetSortAction {
  payload: SetSortPayload;
  type: typeof SET_USER_SORT_QUERY;
}
export interface ISetFilterModalOptionAction {
  payload: FilterModalTypes;
  type: typeof SET_USER_FILTER_MODAL_OPTION;
}

export interface ISetFilterAction {
  payload: IFilter;
  type: typeof SET_USER_FILTER;
}

export interface ISetSearchByAction {
  payload: SearchByTypes;
  type: typeof SET_USER_SEARCH_BY;
}

export interface ISetActivePageAction {
  payload: number;
  type: typeof SET_ACTIVE_PAGE;
}

interface UserProfileOptionsPayload {
  accountId?: string;
  subscription?: Partial<IEntitlementItem>;
}
export interface IUpdateUserProfileAction {
  payload: UserProfileOptionsPayload;
  type: typeof UPDATE_USER_PROFILE;
}

export interface IShowModalAction {
  payload: string;
  type: typeof SHOW_MODAL;
}

export interface ICloseModalAction {
  type: typeof CLOSE_MODAL;
}
export interface IExportCommunityAction {
  type: typeof EXPORT_COMMUNITY;
}

export interface ISetExportStatusAction {
  payload: ExportStatus;
  type: typeof SET_EXPORT_STATUS;
}

export interface ISetExportFileAction {
  payload: IExportFile | null;
  type: typeof SET_EXPORT_FILE;
}

export interface ISetExportFileLinkAction {
  payload: string;
  type: typeof SET_EXPORT_FILE_LINK;
}

export interface ISetGrantAction {
  payload: string;
  type: typeof SET_GRANT;
}

export interface UpdateEntitlementPayload {
  action: 'remove' | 'cancel' | 'scheduleCancel' | 'justRefund',
  grantRefund?: boolean,
  id: string;
  reason?: string,
  refundAmount?: number,
  refundDetails?: string,
}
export interface IUpdateEntitlementAction {
  payload: UpdateEntitlementPayload;
  type: typeof UPDATE_ENTITLEMENT;
}

export interface IResetAction {
  type: typeof RESET;
}

export const setView = (view: Views): ISetViewAction => ({
  payload: view,
  type: SET_VIEW,
});

export const setSortQuery = (sortBy: string, sortDirection: number): ISetSortAction => ({
  payload: { sortBy, sortDirection },
  type: SET_USER_SORT_QUERY,
});

export const setSearchQuery = (query: string, queryDescription?: string): ISetSearchQueryAction => ({
  payload: { searchQuery: query, queryDescription },
  type: SET_USER_SEARCH_QUERY,
});

export const setFilterModalOption = (filterModal: FilterModalTypes): ISetFilterModalOptionAction => ({
  payload: filterModal,
  type: SET_USER_FILTER_MODAL_OPTION,
});

export const setSearchBy = (searchBy: SearchByTypes): ISetSearchByAction => ({
  payload: searchBy,
  type: SET_USER_SEARCH_BY,
});

export const setFilter = (filter: IFilter): ISetFilterAction => ({
  payload: filter,
  type: SET_USER_FILTER,
});

export const setActivePage = (payload: number): ISetActivePageAction => ({
  payload,
  type: SET_ACTIVE_PAGE,
});

export const updateUserProfile = (payload: UserProfileOptionsPayload): IUpdateUserProfileAction => ({
  payload,
  type: UPDATE_USER_PROFILE,
});

export const showModal = (modalKind: string): IShowModalAction => ({
  payload: modalKind,
  type: SHOW_MODAL,
});

export const closeModal = (): ICloseModalAction => ({
  type: CLOSE_MODAL,
});

export const setGrant = (subscriptionId: string): ISetGrantAction => ({
  payload: subscriptionId,
  type: SET_GRANT,
});

export const updateEntitlement = (payload: UpdateEntitlementPayload): IUpdateEntitlementAction => ({
  payload,
  type: UPDATE_ENTITLEMENT,
});

export const exportCommunity = (): IExportCommunityAction => ({
  type: EXPORT_COMMUNITY,
});

export const setExportStatus = (payload: ExportStatus): ISetExportStatusAction => ({
  payload,
  type: SET_EXPORT_STATUS,
});

export const setExportFile = (payload: IExportFile | null): ISetExportFileAction => ({
  payload,
  type: SET_EXPORT_FILE,
});

export const setExportFileLink = (payload: string): ISetExportFileLinkAction => ({
  payload,
  type: SET_EXPORT_FILE_LINK,
});

export const reset = (): IResetAction => ({
  type: RESET,
});
