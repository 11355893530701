import type IStream from 'models/IStream';
import { decryptProviderAccountData } from 'services/livestream';
import { flattenCalls } from 'utils';
import { makeCached } from 'utils/cache';

const { cachedFn: cachedDecryptProviderAccountData } = makeCached(
  flattenCalls(
    decryptProviderAccountData,
  ),
);

export const streamProviderAccountMapper = async (
  rawAccDoc: any,
  authToken: string,
): Promise<IStream> => {
  if (!rawAccDoc?.credentials?.maestroEncryptedData || !rawAccDoc._id)
    throw new Error(`StreamProviderAccount must have a 'credentials' prop and '_id'`);

  const credentials = await cachedDecryptProviderAccountData(
    rawAccDoc._id,
    authToken,
    rawAccDoc.credentials,
  );

  return {
    ...rawAccDoc,
    credentials,
  };
};
