import { SubMenuKey } from 'components/admin-bridge/AdminBar/constants';
import ISubscription from 'models/ISubscription';

export enum RoleScope {
  SiteAdmin = '*',
  ChatModerator = 'Chat_Moderator',
  Producer = 'Producer',
  Page = 'Page',
  Quest = 'Quest',
}

export interface IAccountRole {
  pageId?: string[];
  scope: RoleScope;
  write: boolean;
}

export interface ISuperAdminResponse {
  accountId: string;
  isSuperAdmin: boolean;
  siteId: string;
}

export interface IThirdPartyAuthProvider {
  accountId: string;
  provider: string;
}

export interface IAccount {
  _id: string;
  adminBarSubMenuQueue?: SubMenuKey[];
  created: number;
  email?: string;
  lastLogin?: number;
  name?: string;
  normalizedName?: string;
  roles: IAccountRole[];
  siteId?: string;
  subscriptions?: ISubscription[];
  tags: string[];
  thirdPartyAuthProviders?: IThirdPartyAuthProvider[];
  uid: string;
}

// TODO: Verify at runtime (maybe using io-ts)
export interface IAccessTokenData extends IAccount {
  exp: number;
  iat: number;
  service: string;
}

export interface IAccountLookupResponse {
  email?: string;
  message?: string;
  name?: string;
  success: boolean;
}

export interface IFoundAccount {
  email?: string;
  isAccountFound: boolean;
  userName?: string;
}

export enum AuthPostMessageActions {
  LOGIN = 'login',
  LOGOUT = 'logout',
  LOGIN_COMPLETE = 'loginComplete',
  UBISOFT_LOGIN = 'ubisoftLogin',
  IFRAME_LOGIN_COMPLETE = 'iFrameLoginSuccessful',
  IFRAME_LOGOUT_COMPLETE = 'iFrameLogoutSuccessful',
}

export type IAuthPostMessagePayload =
  | { action: AuthPostMessageActions.IFRAME_LOGOUT_COMPLETE }
  | { action: AuthPostMessageActions.LOGIN }
  | { action: AuthPostMessageActions.LOGOUT }
  | { action: AuthPostMessageActions.LOGIN_COMPLETE, token: string }
  | { action: AuthPostMessageActions.UBISOFT_LOGIN }
  | { action: AuthPostMessageActions.IFRAME_LOGIN_COMPLETE };

export interface IGlobalAccountAdminSites {
  accountId: string;
  siteId: string;
  slug: string;
}
