import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getSiteId } from 'services/app';
import { getPrimaryToken } from 'services/auth';
import IState from 'services/state';
import IBundle from 'models/IBundle';
import {
  setBundle,
  CREATE_BUNDLE,
  GET_BUNDLE,
  IGetBundleAction,
  ICreateBundleAction,
  CHARGE_BUNDLE,
  IChargeBundleAction,
} from './actions';
import { insertBundle, getBundleById } from './api';
import {
  getBillingInfo,
  getShopifyPaymentMethods,
  getStripeCustomerId,
  submitPayment,
  submitPaymentMethod,
} from 'services/billing';
import { payBundleItemSaga } from 'services/shopify/saga';
import { getStripeCardToken, getStripePaymentMethod } from 'services/billing/api';
import { fetchTokenFromSingleUseSource, fetchTokenFromReusableSource } from 'services/shopify/api';
import { getShopifyPaymentsAccountId } from 'services/shopify';
import { PaymentMethod, StripeError, Token } from '@stripe/stripe-js';
import { setBundleError, setBundleLoading } from 'services/shopify/actions';
import { getBundleError } from 'services/shopify/selectors';
import { getAccountAddresses, saveAddress } from 'services/account-address';
import { getShippingAddress } from 'services/shipping-address';

export function* createBundleSaga({ payload }: ICreateBundleAction) {
  const state: IState = yield select();
  const primaryToken = getPrimaryToken(state);
  const siteId = getSiteId(state);
  yield call(insertBundle, {
    primaryToken: primaryToken!,
    siteId,
    bundle: payload.bundle,
  });
}

export function* getBundleByIdSaga({ payload }: IGetBundleAction) {
  const state: IState = yield select();
  const primaryToken = getPrimaryToken(state);
  const siteId = getSiteId(state);

  const bundle: IBundle = yield call(getBundleById, {
    primaryToken: primaryToken!,
    siteId,
    id: payload.id,
  });

  if (!bundle) {
    return;
  }

  yield put(setBundle({ bundle }));
}

export function* checkoutBundleSaga({ payload }: IChargeBundleAction) {
  const {
    bundle,
    savePaymentMethod,
    stripe,
    tickets,
    makePrimaryPaymentMethod,
    paymentMethod: payloadPaymentMethod,
    element,
  } = payload;

  let state: IState = yield select();
  const billingInfo = getBillingInfo(state);

  if (savePaymentMethod && element) {
    yield put(submitPaymentMethod({
      element,
      makePrimaryPaymentMethod,
      stripe,
    }));
  }

  const shopifyPaymentsAccountId: string = yield select(getShopifyPaymentsAccountId);
  const stripeCustomerId: string = yield select(getStripeCustomerId);
  yield put(setBundleLoading(true));
  yield put(setBundleError(''));

  // Entitlements use payment methods, whereas Shopify uses payment tokens
  let paymentMethod: PaymentMethod | null = payloadPaymentMethod || null;
  if (!payloadPaymentMethod && element && stripe) {
    const result: { error: StripeError, paymentMethod: PaymentMethod } = yield call(
      getStripePaymentMethod,
      element,
      stripe,
      billingInfo,
    );
    if (result.error) {
      setBundleError(result.error.message || 'Error adding payment method');
      return;
    }
    if (result?.paymentMethod) {
      paymentMethod = result.paymentMethod;
    }
  }

  let stripeCardToken: Token | null = null;
  if (element && stripe) {
    const result: { error: StripeError, token: Token } = yield call(
      getStripeCardToken,
      element,
      stripe,
      billingInfo,
    );
    if (result.error) {
      setBundleError(result.error.message || 'Error adding payment method');
      return;
    }
    stripeCardToken = result?.token;
  }

  let paymentToken: string;

  if (!paymentMethod) {
    setBundleError('Error setting payment method');
    return;
  }

  // 1. Purchase entitlements of bundle
  for (const entitlement of tickets) {
    yield put(submitPayment({
        bundleId: bundle._id,
        paymentMethod,
        presentmentCurrency: payload.presentmentCurrency.toLowerCase(),
        savePaymentMethod: false,
        sku: entitlement.sku,
        stripe: payload.stripe,
        usingPaymentRequestButtonForm: payload.usingPaymentRequestButtonForm,
    }));
  }
  const accessToken = getPrimaryToken(state);
  // 2. Purchase shippable items of bundle
  if (payloadPaymentMethod) {
    const shopifyPaymentMethods = getShopifyPaymentMethods(state);
    const shopifyCardId = shopifyPaymentMethods.find(card => (
      card.card?.fingerprint === payloadPaymentMethod.card?.fingerprint
    )) || shopifyPaymentMethods.pop();

    if (!shopifyCardId) {
      throw new Error('missing payment method for shopify');
    }

    // @ts-ignore
    const response = yield call(fetchTokenFromReusableSource, {
      accessToken,
      account: shopifyPaymentsAccountId,
      cardId: shopifyCardId?.id,
      customer: stripeCustomerId,
    });
    paymentToken = response.token.id;
  } else {
    // @ts-ignore
    const response = yield call(fetchTokenFromSingleUseSource, {
      account: shopifyPaymentsAccountId,
      customer: stripeCustomerId,
      tokenId: stripeCardToken?.id,
    });
    if (!response.token) {
      setBundleError(response.message || 'Error adding payment');
      return;
    }
    paymentToken = response.token.id;
  }

  yield call(payBundleItemSaga, paymentToken);

  state = yield select();
  const bundleError = getBundleError(state);

  if (bundleError) {
    setBundleError(bundleError);
    // show error to bundle checkout modal
    return;
  }

  const accountAddresses = getAccountAddresses(state);
  const shippingAddress = getShippingAddress(state);
  const addressExist = accountAddresses.some(address => address.address.address1 === shippingAddress.address1);

  if (!addressExist && accessToken) {
    yield put(saveAddress({
      address: { ...shippingAddress, complete: true },
      name: shippingAddress.firstName || '',
      primaryToken: accessToken,
    }));
  }

  yield put(setBundleLoading(false));
}

export default function* bundleSaga() {
  yield takeEvery(GET_BUNDLE, getBundleByIdSaga);
  yield takeEvery(CREATE_BUNDLE, createBundleSaga);
  yield takeLatest(CHARGE_BUNDLE, checkoutBundleSaga);
}

