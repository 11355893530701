import axios from 'axios';
import { COMMUNITY_SERVICE_BASE_URL } from 'config';
import { ISearch, ExportStatus } from './reducer';
import { UpdateEntitlementPayload } from './actions';

type IExportFileStatusMap = {
  [key: string]: ExportStatus;
};

export const exportFileStatusMap: IExportFileStatusMap = {
  'done': ExportStatus.download,
  'error': ExportStatus.ready,
  'pending': ExportStatus.loading,
};

export interface IExportCommunityResponse {
  exportId: string;
  fileName: string;
  status: ExportStatus;
}

export const exportCommunity = async (accessToken: string, siteId: string, search: ISearch): Promise<IExportCommunityResponse> => {
  const { data } = await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
    method: 'GET',
    params: {
      search,
    },
    url: `${COMMUNITY_SERVICE_BASE_URL}/export`,
  });

  const { exportId, fileName, status } = data;

  return {
    exportId,
    fileName,
    status: exportFileStatusMap[status],
  };
};

export const setGrantCommunity = async (accessToken: string, siteId: string, accountId: string, subscriptionId: string) => {
  const { data } = await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
    method: 'POST',
    params: {
      accountId,
      subscriptionId,
    },
    url: `${COMMUNITY_SERVICE_BASE_URL}/grant-entitlement`,
  });

  return data;
};

export const updateEntitlementCommunity = async (accessToken: string, siteId: string, accountId: string, payload: UpdateEntitlementPayload) => {
  const { data } = await axios({
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
    method: 'POST',
    params: {
      accountId,
      ...payload,
    },
    url: `${COMMUNITY_SERVICE_BASE_URL}/update-entitlement`,
  });

  return data;
};
