import { PaymentMethod } from '@stripe/stripe-js';
import IState from '../state';
import {
  IWallet,
  IAmountBreakdown,
  IBillingInfo,
  IBillingInfoError,
  IContract,
  ContractStatus,
  RegionRenderingStripeCreditCardEnum,
} from './models';
import { toFixed } from 'utils';
import { createSelector } from 'reselect';
import Plan, { PLAN_TYPE, UNIT_TYPE } from 'models/IPlan';

export const getStripePaymentMethods = createSelector(
  ({ billing }: IState): PaymentMethod[] => billing.payments, payments => payments.filter((pm) => pm.id?.startsWith('pm_')));

export const getShopifyPaymentMethods = ({ billing }: IState): PaymentMethod[] => billing.payments.filter((pm) => pm.id?.startsWith('card_') && pm.metadata?.usage !== 'single_use');

export const getStripeAndShopifyPaymentMethods = createSelector([getStripePaymentMethods, getShopifyPaymentMethods], (stripePms, shopifyPms) => {
  return [...stripePms, ...shopifyPms];
});

export const getDefaultPayment = ({ billing }: IState): string | null => billing.defaultPayment;
export const getDefaultPaymentMethod = createSelector(
  [getDefaultPayment, getStripeAndShopifyPaymentMethods],
  (defaultPayment, payments) => payments.find((pm) => pm.id === defaultPayment),
);

export const getDefaultCardSource = ({ billing }: IState): string | null => {
  if (billing.wallet?.defaultCardSource) {
    return billing.wallet.defaultCardSource;
  }
  return null;
};

export const getIdempotencyKey = ({ billing }: IState): string => billing.idempotencyKey;

export const getWallet = createSelector(
  ({ billing }: IState): IWallet | null => billing.wallet,
  wallet => wallet,
);

export const getPaymentMethodSuccess = createSelector(({ billing }: IState): boolean => billing.addPaymentSuccess, addPaymentSuccess => addPaymentSuccess);

export const getPrimaryPaymentSuccess = createSelector(({ billing }: IState): boolean => billing.setPrimaryPaymentSuccess, setPrimaryPaymentSuccess => setPrimaryPaymentSuccess);

export const getRemovePaymentSuccess = createSelector(({ billing }: IState): boolean => billing.removePaymentSuccess, removePaymentSuccess => removePaymentSuccess);

export const getAmountBreakdown = createSelector(
  ({ billing }: IState): IAmountBreakdown => billing.amountBreakdown,
  amountBreakdown => amountBreakdown,
);

export const getTaxRate = createSelector(
  getAmountBreakdown,
  amountBreakdown => amountBreakdown.taxRate,
);

export const getBillingInfo = createSelector(
  ({ billing }: IState): IBillingInfo => billing.billingInfo,
  billingInfo => billingInfo,
);

export const getErrorsBillingInfo = createSelector(
  ({ billing }: IState): IBillingInfoError => billing.billingInfoError,
  billingInfoError => billingInfoError,
);

export const getBillingInfoError = createSelector(
  ({ billing }: IState): string | null => billing.billingInfo.error,
  billingInfoError => billingInfoError,
);

export const getServiceChargeRate = ({ billing }: IState): number => {
  return billing.viewerServiceChargeRate || 0;
};

export const getTipViewerServiceCharge = ({ billing }: IState) => {
  return billing.plan?.payoutTerms.tips.viewerServiceCharge || 0;
};

export const getServiceChargeAmount = createSelector(
  [getAmountBreakdown, getServiceChargeRate],
  ({ entitlementPrice }, rate) => {
    const serviceChargeAmount = entitlementPrice.value * rate;
    return parseFloat(toFixed(serviceChargeAmount, 2));
  },
);

export const getTaxAmount = createSelector(
  [getAmountBreakdown, getServiceChargeAmount],
  ({ entitlementPrice, taxRate }, serviceChargeAmount) => {
    const taxAmount = (entitlementPrice.value + serviceChargeAmount) * taxRate;
    return parseFloat(toFixed(taxAmount, 2));
  },
);

export const getTotalAmount = createSelector(
  [getAmountBreakdown, getTaxAmount, getServiceChargeAmount],
  ({ entitlementPrice }, taxAmount, serviceChargeAmount) => entitlementPrice.value + taxAmount + serviceChargeAmount,
);

export const getIsUsingPaymentRequestButtonForEntitlementPurchase = ({ billing }: IState): boolean => billing.usingPaymentRequestButtonForEntitlementPurchase;

export const getTaxLoading = ({ billing }: IState): boolean => billing.taxLoading;

export const getTaxLoaded = ({ billing }: IState): boolean => billing.taxLoaded;

export const getPaymentPending = ({ billing }: IState): boolean => billing.paymentPending;

export const getEditPaymentMethodSuccess = createSelector(({ billing }: IState): boolean => billing.editPaymentMethodSuccess, editPaymentMethodSuccess => editPaymentMethodSuccess);

export const getEditPaymentMethodPending = ({ billing }: IState): boolean => billing.editPaymentMethodPending;

export const getStripeAccount = createSelector(
  getWallet,
  wallet => wallet?.stripeAccountId || null,
);

export const getStripeCustomerId = ({ billing }: IState): string | null => {
  if (billing.wallet?.stripeCustomerId) {
    return billing.wallet.stripeCustomerId;
  }
  return null;
};

export const getPaymentsLoading = createSelector(({ billing }: IState): boolean => billing.paymentsLoading, paymentsLoading => paymentsLoading);

export const getWatchedEntitlementIds = createSelector(
  ({ billing }: IState): string[] => billing.watchedEntitlementIds,
  watchedEntitlementIds => watchedEntitlementIds,
);

export const getEntitlementLoadingId = createSelector(({ billing }: IState): string | null => billing.entitlementLoadingId, entitlementLoadingId => entitlementLoadingId);


export const getPlan = ({ billing }: IState): Plan | null => billing.plan;
export const getPlanFeatures = ({ billing }: IState) => billing.plan?.planFeatures;
export const getPlanUnitPrice = ({ billing }: IState): number | null => {
  const plan = billing.plan;
  if (!plan) {
    return null;
  }
  const saasFee = plan.planItems.find(planItem => planItem.unit === UNIT_TYPE.baseSaasFee);
  if (!saasFee) {
    return null;
  }
  return saasFee.unitPrice;
};

export const getContract = ({ billing }: IState): IContract | null => billing.contract;
export const getContractStatus = ({ billing }: IState): ContractStatus | undefined => billing.contract?.status;

export const getIsPlanTypeSelfServiceOrProPlan = ({ billing }: IState): boolean => billing.plan?.type === PLAN_TYPE.SelfService || billing.plan?.name === 'Pro';
export const getIsLegacyPlan = ({ billing }: IState): boolean => billing.plan?.name === 'Plus' || billing.plan?.name === 'Pro';
export const getIsEnterprisePlan = ({ billing }: IState): boolean => billing.plan?.type === PLAN_TYPE.Enterprise;
export const getIsUnlimitedPlan = ({ billing }: IState): boolean => billing.plan?.type === PLAN_TYPE.Unlimited;
export const getIsFreePlan = ({ billing }: IState): boolean => billing.plan?.name === 'Start';

export const getIsFreeTrialExpired = createSelector(
  getIsUnlimitedPlan,
  getContractStatus,
  (isUnlimitedPlan, contractStatus) => !isUnlimitedPlan &&
  (contractStatus === ContractStatus.cancelled ||
    contractStatus === ContractStatus.freeTrialExpired),
);

export const getIsPastDue = createSelector(
  getIsUnlimitedPlan,
  getContractStatus,
  (isUnlimitedPlan, contractStatus) => !isUnlimitedPlan &&
    contractStatus === ContractStatus.pastDue,
);

export const getPurchasePlanLoading = ({ billing }: IState): boolean => billing.purchasePlanLoading;
export const getPurchasePlanSuccess = ({ billing }: IState): boolean => billing.purchasePlanSuccess;
export const getPurchasePlanErrorMessage = ({ billing }: IState): string | null => billing.purchasePlanErrorMessage;

export const getUpgradePlanLoading = ({ billing }: IState): boolean => billing.upgradePlanLoading;
export const getUpgradePlanSuccess = ({ billing }: IState): boolean => billing.upgradePlanSuccess;
export const getUpgradePlanErrorMessage = ({ billing }: IState): string | null => billing.upgradePlanErrorMessage;

export const getRegionRenderingStripeCreditCard = ({ billing }: IState): RegionRenderingStripeCreditCardEnum | null => billing.regionRenderingStripeCreditCard[billing.regionRenderingStripeCreditCard.length - 1] || null;
export const getNativeCurrency = ({ billing }: IState): string | null => billing.nativeCurrencyCode;

