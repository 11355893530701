import { asElementTypes } from 'shared/type-utils';
import {
  ADMIN_TEXT_500,
  ADMIN_ALERT_CONFIRMATION,
  ADMIN_SURFACE_5,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_ALERT_ERROR,
  ADMIN_TEXT_100,
  ADMIN_ALERT_WARNING,
} from 'style/constants';
import { RequiredNotification } from './models';

const defaultAction = (close: () => void) => close();

export const DEFAULT_LOWP_NOTIFICATION: RequiredNotification = {
  action: defaultAction,
  borderColor: ADMIN_ALERT_CONFIRMATION,
  buttons: [{
    background: ADMIN_ALERT_CONFIRMATION,
    text: 'Ok',
    textColor: ADMIN_TEXT_500,
  }],
  icon: {
    color: ADMIN_ALERT_CONFIRMATION,
    name: 'checkmarkCircle',
  },
  priority: 'low',
  message: {
    background: 'transparent',
    textColor: ADMIN_TEXT_100,
    text: 'Success!',
  },
};

export const DEFAULT_MEDIUMP_NOTIFICATION: RequiredNotification = {
  action: defaultAction,
  borderColor: ADMIN_ALERT_WARNING,
  buttons: [{
    background: ADMIN_ALERT_WARNING,
    text: 'Ok',
    textColor: ADMIN_TEXT_500,
  }],
  icon: {
    color: ADMIN_ALERT_WARNING,
    name: 'warning',
  },
  priority: 'medium',
  message: {
    background: 'transparent',
    textColor: ADMIN_TEXT_100,
    text: 'Warning!',
  },
};

export const DEFAULT_HIGHP_NOTIFICATION: RequiredNotification = {
  action: defaultAction,
  borderColor: ADMIN_ALERT_ERROR,
  buttons: [{
    background: ADMIN_ALERT_ERROR,
    text: 'Ok',
    textColor: ADMIN_TEXT_100,
  }],
  icon: {
    color: ADMIN_ALERT_ERROR,
    name: 'warning',
  },
  priority: 'high',
  message: {
    background: 'transparent',
    textColor: ADMIN_TEXT_100,
    text: 'Error!',
  },
};

export const DEFAULT_CUSTOM_NOTIFICATION: RequiredNotification = {
  action: defaultAction,
  borderColor: ADMIN_SURFACE_5,
  buttons: [{
    background: ADMIN_ACCENT_PRIMARY,
    text: 'Ok',
    textColor: ADMIN_TEXT_100,
  }],
  icon: {
    color: ADMIN_ACCENT_PRIMARY,
    name: 'checkmarkCircle',
  },
  priority: 'low',
  message: {
    background: 'transparent',
    textColor: ADMIN_TEXT_100,
    text: 'Empty notification',
  },
};

export const DEFAULT_NOTIFICATION_MAP = asElementTypes<RequiredNotification>()({
  custom: DEFAULT_CUSTOM_NOTIFICATION,
  high: DEFAULT_HIGHP_NOTIFICATION,
  medium: DEFAULT_MEDIUMP_NOTIFICATION,
  low: DEFAULT_LOWP_NOTIFICATION,
}) as { [key in 'custom' | 'high' | 'medium' | 'low']: RequiredNotification };
