import { call, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { getSiteId } from 'services/app/selectors';
import {
  getAdobeParamsFromQuery,
  getBowlTvParamsFromQuery,
  getCornellParamsFromQuery,
  getXboxParamsFromQuery,
} from 'services/app-router/selectors';
import {
  removeKeys,
} from 'services/app-router/actions';
import {
  isUserLoggedIn,
} from 'services/auth/selectors';
import {
  logIn,
  logOut,
  OauthProvider,
} from 'services/auth/actions';
import {
  setStoredBowlTvId,
} from 'services/auth/api';
import { showTextModal } from 'services/modals/actions';
import IState from 'services/state';
import { RECEIVED_FACEBOOK_TOKEN, RECEIVED_TWITCH_TOKEN } from './actions';
import {
  IAuthWithFacebookTokenAction,
  IAuthWithTwitchTokenAction,
} from './actions';
import {
  IAdobeRequestPayload,
  IBowlTvRequestPayload,
  ICornellRequestPayload,
  IFacebookRequestPayload,
  ILegacyAuthResponse,
  IRequestPayload,
  ITwitchRequestPayload,
  IXboxRequestPayload,
  oauthRequest,
} from './api';

export const triggerLogin = function*<T extends IRequestPayload>(provider: OauthProvider, payload: T) {
  try {
    const {
      accountCollectionTokens,
    }: ILegacyAuthResponse = yield call(oauthRequest, provider, payload);

    if (!accountCollectionTokens) {
      yield put(showTextModal('Invalid response from legacy auth.'));
      return;
    }

    const state: IState = yield select();
    if (isUserLoggedIn(state)) {
      yield put(logOut());
    }
    yield put(logIn({
      response: accountCollectionTokens,
      strategy: 'legacy',
    }));
  } catch (e) {
    console.error(e); // tslint:disable-line no-console
    yield put(showTextModal('An error occurred.  Please try logging in again'));
  }
};

export const adobeSaga = function* () {
  const state: IState = yield select();
  const params = getAdobeParamsFromQuery(state);
  const siteId = getSiteId(state);

  if (!params.adobe_access_token && !params.adobe_error) {
    return;
  }

  if (params.adobe_error) {
    yield put(showTextModal(`Error: ${params.adobe_error}`));
    yield put(removeKeys(Object.keys(params)));
    return;
  }

  yield triggerLogin<IAdobeRequestPayload>(OauthProvider.ADOBE, {
    access_token: params.adobe_access_token,
    site_id: siteId,
  });

  yield put(removeKeys(Object.keys(params)));
};

export const bowlTvSaga = function* () {
  const state: IState = yield select();
  const siteId = getSiteId(state);
  const params = getBowlTvParamsFromQuery(state);
  if (!(params.bowltv_access_token && params.id_token)) {
    return;
  }

  // set bowl_id_token
  setStoredBowlTvId(params.id_token);

  yield triggerLogin<IBowlTvRequestPayload>(OauthProvider.BOWLTV, {
    access_token: params.bowltv_access_token,
    site_id: siteId,
  });

  yield put(removeKeys(Object.keys(params)));
};

export const cornellSaga = function* () {
  const state: IState = yield select();
  const params = getCornellParamsFromQuery(state);
  const siteId = getSiteId(state);

  if (!params.saml_response) {
    return;
  }

  if (params.email === 'undefined' || !params.email) {
    yield put(showTextModal('Your Cornell email is not defined. Please update or use another service.'));
    return;
  }

  yield triggerLogin<ICornellRequestPayload>(OauthProvider.CORNELL, {
    email: params.email,
    name: params.name,
    site_id: siteId,
    username: params.username,
  });

  yield put(removeKeys(Object.keys(params)));
};

export const facebookSaga = function* (action: IAuthWithFacebookTokenAction) {
  const state: IState = yield select();
  const { accessToken } = action.payload;
  const siteId = getSiteId(state);
  if (!accessToken) {
    return;
  }

  yield triggerLogin<IFacebookRequestPayload>(OauthProvider.FACEBOOK, {
    access_token: accessToken,
    site_id: siteId,
  });
};

export const twitchSaga = function* (action: IAuthWithTwitchTokenAction) {
  const state: IState = yield select();
  const { access_token: accessToken } = action.payload;
  const siteId = getSiteId(state);
  if (!accessToken) {
    return;
  }

  yield triggerLogin<ITwitchRequestPayload>(OauthProvider.TWITCH, {
    access_token: accessToken,
    site_id: siteId,
  });
};

export const xboxSaga = function* () {
  const state: IState = yield select();
  const siteId = getSiteId(state);
  const params = getXboxParamsFromQuery(state);
  if (!params.xbox_access_token) {
    return;
  }

  yield triggerLogin<IXboxRequestPayload>(OauthProvider.XBOX, {
    access_token: params.xbox_access_token,
    site_id: siteId,
  });

  yield put(removeKeys(Object.keys(params)));
};

const legacyAuthSaga = function* () {
  yield spawn(adobeSaga);
  yield spawn(bowlTvSaga);
  yield spawn(cornellSaga);
  yield spawn(xboxSaga);
  yield takeLatest(RECEIVED_FACEBOOK_TOKEN, facebookSaga);
  yield takeLatest(RECEIVED_TWITCH_TOKEN, twitchSaga);
};

export default legacyAuthSaga;
