export const UPDATE_DEVICE_CREATED = 'device/SET_DEVICE_CREATED';
export const UPDATE_DIMENSIONS = 'device/UPDATE_DIMENSIONS';
export const ORIENTATION = 'device/ORIENTATION';
export const ORIENTATION_DEGREES = 'device/ORIENTATION_DEGREES';

interface IUpdateDeviceCreatedAction {
  payload: { created: number, newDevice: boolean};
  type: typeof UPDATE_DEVICE_CREATED;
}

interface IUpdateDimensionsAction {
  payload: { height: number, width: number};
  type: typeof UPDATE_DIMENSIONS;
}

interface IUpdateOrientationAction {
  payload: boolean;
  type: typeof ORIENTATION;
}

interface IUpdateOrientationDegreesAction {
  payload: { orientationDegrees: number };
  type: typeof ORIENTATION_DEGREES;
}

export type IDeviceAction = IUpdateDeviceCreatedAction |
  IUpdateDimensionsAction |
  IUpdateOrientationAction |
  IUpdateOrientationDegreesAction;

export const updateDeviceCreated = ({ created, newDevice }: {
  created: number;
  newDevice: boolean;
}): IUpdateDeviceCreatedAction => ({
  payload: { created, newDevice },
  type: UPDATE_DEVICE_CREATED,
});

export const updateDimensions = ({ height, width }: {
  height: number;
  width: number;
}): IUpdateDimensionsAction => ({
  payload: { height, width },
  type: UPDATE_DIMENSIONS,
});

export const updateOrientation = (isLandscape: boolean): IUpdateOrientationAction => ({
  payload: isLandscape,
  type: ORIENTATION,
});

export const updateOrientationDegrees = (orientationDegrees: number): IUpdateOrientationDegreesAction => ({
  payload: { orientationDegrees },
  type: ORIENTATION_DEGREES,
});
