import { ISilencedUser } from './models';
export const SEND_MESSAGE = 'chat/SEND_MESSAGE';
export const SEND_ADMIN_MESSAGE = 'chat/SEND_ADMIN_MESSAGE';
export const SET_ANON_USERNAME = 'chat/SET_ANON_USERNAME';
export const SET_EXPORT_GENERATING = 'chat/SET_EXPORT_GENERATING';
export const EXPORT_CHAT = 'chat/EXPORT_CHAT';
export const SET_SILENCED_USERS = 'chat/SET_SILENCED_USERS';
export const FETCH_SILENCED_USERS = 'chat/INIT_CHAT_PANEL_EDITOR';
export const TOGGLE_USER_SILENCE = 'chat/TOGGLE_USER_SILENCE';
export const CLEAR_ALL_MESSAGES = 'chat/CLEAR_ALL_MESSAGES';
export const REMOVE_MESSAGE = 'chat/REMOVE_MESSAGE';
export const INIT_CHAT_CHANNEL = 'chat/INIT_CHAT_CHANNEL';

interface IChannelPayload {
  channel: string;
}

interface IChannelIdPayload {
  channelId: string;
}

interface IExportChatPayload {
  channelId: string;
  endTime: number;
  startTime: number;
}

export interface IExportChatAction {
  payload: IExportChatPayload;
  type: typeof EXPORT_CHAT;
}

interface ISetExportGeneratingAction {
  payload: boolean;
  type: typeof SET_EXPORT_GENERATING;
}

interface ISendMessagePayload extends IChannelPayload {
  key: string;
  text: string;
}

export interface ISendMessageAction {
  payload: ISendMessagePayload;
  type: typeof SEND_MESSAGE;
}

interface IToggleUserSilencePayload extends IChannelPayload {
  isSilenced: boolean;
  userId: string;
  /**
   * `anonymous` is deprecated
   */
  userType: 'account' | 'anonymous'
}

export interface IToggleUserSilenceAction {
  payload: IToggleUserSilencePayload;
  type: typeof TOGGLE_USER_SILENCE
}

export interface IClearAllMessagesAction {
  payload: IChannelPayload;
  type: typeof CLEAR_ALL_MESSAGES;
}

export interface IInitChatChannelAction {
  payload: IChannelPayload;
  type: typeof INIT_CHAT_CHANNEL;
}

interface IRemoveMessagePayload extends IChannelPayload {
  messageKey: string;
}

export interface IRemoveMessageAction {
  payload: IRemoveMessagePayload;
  type: typeof REMOVE_MESSAGE;
}

export interface IFetchSilencedUsersAction {
  payload: IChannelIdPayload;
  type: typeof FETCH_SILENCED_USERS;
}

interface ISetSilencedUsersPayload {
  silencedUsers: ISilencedUser[];
}

interface ISetSilencedUsersAction {
  payload: ISetSilencedUsersPayload;
  type: typeof SET_SILENCED_USERS;
}

interface ISetAnonUsernameAction {
  payload: string;
  type: typeof SET_ANON_USERNAME;
}

interface ISendAdminMessagePayload {
  channel: string;
  data: any;
}

export interface ISendAdminMessageAction {
  payload: ISendAdminMessagePayload;
  type: typeof SEND_ADMIN_MESSAGE;
}

export type IChatActions = IExportChatAction |
  ISetExportGeneratingAction |
  ISendMessageAction |
  IToggleUserSilenceAction |
  IClearAllMessagesAction |
  IInitChatChannelAction |
  IRemoveMessageAction |
  IFetchSilencedUsersAction |
  ISetSilencedUsersAction |
  ISetAnonUsernameAction |
  ISendAdminMessageAction;


export const exportChat = (payload: IExportChatPayload): IExportChatAction => ({
  payload,
  type: EXPORT_CHAT,
});

export const setExportGenerating = (payload: boolean): ISetExportGeneratingAction => ({
  payload,
  type: SET_EXPORT_GENERATING,
});

export const sendMessage = (payload: ISendMessagePayload): ISendMessageAction => ({
  payload,
  type: SEND_MESSAGE,
});

export const toggleUserSilence = (payload: IToggleUserSilencePayload): IToggleUserSilenceAction => ({
  payload,
  type: TOGGLE_USER_SILENCE,
});

export const sendAdminMessage = (payload: ISendAdminMessagePayload): ISendAdminMessageAction => ({
  payload,
  type: SEND_ADMIN_MESSAGE,
});

export const clearAllMessages = (payload: IChannelPayload): IClearAllMessagesAction => ({
  payload,
  type: CLEAR_ALL_MESSAGES,
});

export const initChatChannel = (payload: IChannelPayload): IInitChatChannelAction => ({
  payload,
  type: INIT_CHAT_CHANNEL,
});

export const removeMessage = (payload: IRemoveMessagePayload): IRemoveMessageAction => ({
  payload,
  type: REMOVE_MESSAGE,
});

export const setAnonUsername = (payload: string): ISetAnonUsernameAction => ({
  payload,
  type: SET_ANON_USERNAME,
});

export const fetchSilencedUsers = (payload: IChannelIdPayload): IFetchSilencedUsersAction => ({
  payload,
  type: FETCH_SILENCED_USERS,
});

export const setSilencedUsers = (payload: ISetSilencedUsersPayload): ISetSilencedUsersAction => ({
  payload,
  type: SET_SILENCED_USERS,
});
