import { call, put, select, takeEvery } from 'redux-saga/effects';
import IState from 'services/state';
import { setCustomerInfo } from './actions';
import { ICustomerInfo } from './models';
import { ISetAccountAction, SET_ACCOUNT_DATA } from 'services/auth/actions';
import { isListening } from 'services/realtime/selectors';
import { getSiteSettingsId } from './selectors';
import { joinKeySegments } from 'firebase-utils';
import { IUpdateDocumentAction, subscribe, UPDATE_DOCUMENT } from 'services/realtime/actions';


export const getCustomerInfoSaga = function* ({ payload }: IUpdateDocumentAction) {
  if (!/site_setting/.test(payload.path)) {
    return;
  }
  const siteSettings = payload.value;
  if (!siteSettings) {
    return;
  }
  const { accessInstructions, support } = siteSettings;
  if (!support || typeof accessInstructions !== 'string') {
    return;
  }
  const customerInfo: ICustomerInfo = { accessInstructions, support };
  yield put(setCustomerInfo(customerInfo));
};

export const getCustomerProfileData = function* ({ payload }: ISetAccountAction) {
  if (!payload) {
    return;
  }
  const { roles } = payload;
  const isAdmin = roles.some(role => role.scope === '*' && role.write);
  if (isAdmin) {
    const state: IState = yield select();
    const siteSettingsId = getSiteSettingsId(state);
    if (!siteSettingsId) {
      return;
    }
    const path: string = yield call(joinKeySegments, ['site_setting', siteSettingsId]);
    if (!isListening(state, 'site_setting', siteSettingsId)) {
      yield put(subscribe(path));
    }
  }
};

export const customerProfileSaga = function* () {
  yield takeEvery(SET_ACCOUNT_DATA, getCustomerProfileData);
  yield takeEvery(UPDATE_DOCUMENT, getCustomerInfoSaga);
};
