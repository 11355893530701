import IBundle from 'models/IBundle';
import maestroApiRequest from 'services/maestro-api-client';
import { BUNDLE_SERVICE_SERVICE_BASE_URL } from 'config';

interface IBaseAPIParams {
  primaryToken: string;
  siteId: string;
}

export interface IInsertBundleParams extends IBaseAPIParams {
  bundle: Omit<IBundle, 'siteId'>;
}

export interface IGetBundleByIdParams extends IBaseAPIParams {
  id: string;
}

export const insertBundle = async ({ bundle, primaryToken, siteId }: IInsertBundleParams): Promise<IBundle> => {
  const result = await maestroApiRequest({
    primaryToken,
    siteId,
  }).post<IBundle>(`${BUNDLE_SERVICE_SERVICE_BASE_URL}/`, bundle);
  return result.data;
};

export const getBundleById = async ({ primaryToken, siteId, id }: IGetBundleByIdParams): Promise<IBundle> => {
  const result = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<IBundle>(`${BUNDLE_SERVICE_SERVICE_BASE_URL}/${id}`);
  return result.data;
};
