import { call, delay, put, select } from 'redux-saga/effects';
import { ICheckoutInput, ICheckoutLineItem, ICheckoutStorefront, IGetCheckoutGraphQLResponse, IPaymentStorefront, IPaymentStorefrontInput, IProductVariant, ITokenResponse, IUpdateCheckoutShippingLineResponse } from 'services/shopify/models';
import IState from 'services/state';
import { getCartItems, getShippingMethod, getShopifyCheckout } from '../selectors';
import { getShopState } from 'services/shopify';
import { getPrimaryToken, isLoggedIn, isUserAdmin, isUserLoggedIn } from 'services/auth';
import { getIsShippingAddressComplete, getShippingAddress } from 'services/shipping-address';
import { getProducts, getShopifyPaymentsAccountId } from 'services/shopify/selectors';
import { createCheckoutIdSaga, updateCheckoutSaga } from 'services/shopify/saga';
import { completeCheckoutWithTokenGraphQL, createCommission, fetchTokenFromReusableSource, fetchTokenFromSingleUseSource, getCheckoutGraphQL, getPaymentGraphQL, updateCheckoutShippingLine } from 'services/shopify/api';
import { IUpdateShopifyShippingLine, ISubmitShopifyCheckout, clearCart, setShopifyCheckout, setShopifyErrorMessage, shopifyPaymentSucceeded, ITriggerShopifyCheckout, resetEcommerceViews } from '../actions';
import { getBillingInfo, getShopifyPaymentMethods, getStripeCustomerId, removePayment, setPaymentMethods } from 'services/billing';
import { PaymentMethod } from '@stripe/stripe-js';
import uuid from 'uuid/v4';
import { getSiteId } from 'services/app';
import { closePanel } from 'services/custom-panels';
import { CheckoutCompleteWithTokenizedPaymentV3Mutation } from 'services/shopify/graphql/types';
import { TransactionStatus } from 'services/shopify/graphql/types';
import { getAccountAddresses, saveAddress } from 'services/account-address';
import { getCustomPanels } from 'services/custom-panels/selectors';
import { DataDoc } from 'services/custom-panels/models';
import { SHOPIFY_BLOCK_PANEL_ID } from 'components/objects/PanelV2/constants';
import { hasMatchingShippingAddress } from '../utils';

/**
 * Helper function to get the shopify product variants from the cart
 */
export function* getProductVariantsFromCart() {
  const state: IState = yield select();
  const cart = getCartItems(state);
  const shopifyProducts = getProducts(state);
  const productVariants: IProductVariant['node'][] = [];

  for (const product of shopifyProducts) {
    const index = cart.findIndex(cartItem => cartItem.productId === product.id);
    if (index === -1) {
      continue;
    }

    const variant = product.variants.edges.find(v => v.node.id === cart[index].variantId);
    if (!variant) {
      continue;
    }

    productVariants.push(variant.node);
  }

  return productVariants;
}

/**
 * Helper function to get the checkout input data for Shopify
 */
export function* getCheckoutInputData(email: string) {
  const state = yield select();
  const shippingAddress = getShippingAddress(state);
  const cart = getCartItems(state);

  const checkoutInputData: ICheckoutInput = {
    email,
    lineItems: cart.map((item) => ({
      quantity: item.quantity,
      variantId: item.variantId,
    })),
    shippingAddress: {
      address1: shippingAddress.address1,
      address2: shippingAddress.address2 || '',
      city: shippingAddress.city,
      country: shippingAddress.countryCode,
      firstName: shippingAddress.firstName,
      lastName: shippingAddress.lastName,
      phone: shippingAddress.phoneNumber,
      province: shippingAddress.state,
      zip: shippingAddress.postalCode,
    },
  };

  return checkoutInputData;
}

/**
 * Create a simple Shopify checkout with the items in the cart.
 * DO NOT USE THIS FUNCTION DIRECTLY. USE THE TRIGGER_SAGA INSTEAD.
 */
export function* createShopifyCheckout(email: string) {
  const state: IState = yield select();
  const shopInfoData = getShopState(state);
  const { shop, shopAccessToken: storefrontAccessToken } = shopInfoData;
  const variants: IProductVariant['node'][] = yield call(getProductVariantsFromCart);
  const hasShippableItems = variants.some((variant) => variant.requiresShipping);
  const checkoutInputData: ICheckoutInput = yield call(getCheckoutInputData, email);
  const newCheckout = yield call(createCheckoutIdSaga, { checkoutInputData, hasShippableItems, shop, storefrontAccessToken });
  if (newCheckout.error) {
    throw new Error(newCheckout.error);
  }

  return newCheckout.checkout;
}

/**
 * Shopify creates the checkout but sometime its not ready to be used. We need to poll the checkout until it's ready.
 */
export function* pollShopifyCheckoutReady(checkout: ICheckoutStorefront) {
  const state: IState = yield select();
  const shopInfoData = getShopState(state);
  const { shop, shopAccessToken: storefrontAccessToken } = shopInfoData;
  const variants: IProductVariant['node'][] = yield call(getProductVariantsFromCart);
  const hasShippableItems = variants.some((variant) => variant.requiresShipping);
  let checkoutResponse: IGetCheckoutGraphQLResponse | null = null;
  let newCheckout = checkout;
  const currentCheckout = getShopifyCheckout(state);
  // some times newCheckout returns ready when shipping method is changed and it's causing shipping rate to not be updated
  if (currentCheckout?.shippingLine?.handle !== newCheckout.shippingLine?.handle) {
    newCheckout.ready = false;
  }
  while (!newCheckout.ready) {
    checkoutResponse = yield call(getCheckoutGraphQL, {
      accessToken: storefrontAccessToken,
      checkoutId: newCheckout.id || '',
      shop,
      requiresShipping: hasShippableItems,
    });

    // Handle checkout GraphQL errors
    if (checkoutResponse?.errors && checkoutResponse.errors.length > 0) {
      throw new Error(checkoutResponse.errors[0].message);
    }

    if (checkoutResponse?.data?.node) {
      newCheckout = checkoutResponse?.data?.node;
    }

    yield delay(1000);
  }

  return newCheckout;
}

export function* updateShopifyCheckout(email: string) {
  const state = yield select();
  const shopInfoData = getShopState(state);
  const { shop, shopAccessToken: storefrontAccessToken } = shopInfoData;
  const currentCheckout = getShopifyCheckout(state);

  const variants: IProductVariant['node'][] = yield call(getProductVariantsFromCart);
  const hasShippableItems = variants.some((variant) => variant.requiresShipping);
  const checkoutInputData: ICheckoutInput = yield call(getCheckoutInputData, email);

  if (!currentCheckout) {
    return null;
  }

  if (!currentCheckout.requiresShipping && hasShippableItems) {
    return yield call(createShopifyCheckout, email);
  }

  if (currentCheckout.requiresShipping && !hasShippableItems) {
    return yield call(createShopifyCheckout, email);
  }

  if (currentCheckout.email !== email) {
    return yield call(createShopifyCheckout, email);
  }

  const updatedCheckout = yield call(updateCheckoutSaga, {
    checkoutId: currentCheckout.id,
    checkoutInputData,
    email,
    hasShippableItems,
    shop,
    storefrontAccessToken,
  });
  if (updatedCheckout.error) {
    throw new Error(updatedCheckout.error);
  }

  return yield call(pollShopifyCheckoutReady, currentCheckout);
}

/**
 * Saga to be triggered whenever the shipping address or items change. We try to be always in sync with Shopify.
 */
export function* triggerShopifyCheckoutSaga({ payload }: ITriggerShopifyCheckout) {
  const { email } = payload;
  const state: IState = yield select();
  const currentCheckout = getShopifyCheckout(state);
  const cart = getCartItems(state);
  const shippingAddress = getShippingAddress(state);
  // a new checkout needs to be created when the currentCheckout.shippingAddress differs from the maestroShippingAddress inputs
  const hasSameShippingAddress = shippingAddress && hasMatchingShippingAddress(currentCheckout, shippingAddress);
  const shouldCreateShopifyCheckout = !currentCheckout || !hasSameShippingAddress;

  try {
    if (!email) {
      throw new Error('Email can\'t be empty.');
    }

    // Check if the cart is empty
    if (!cart.length) {
      throw new Error('Cart is empty.');
    }

    // Create or update the Shopify checkout
    let checkout: ICheckoutStorefront = shouldCreateShopifyCheckout ? yield call(createShopifyCheckout, email) : yield call(updateShopifyCheckout, email);
    checkout = yield call(pollShopifyCheckoutReady, checkout);

    // Set the final checkout state
    yield put(setShopifyCheckout(checkout));
  } catch(err) {
    yield put(setShopifyErrorMessage(err.message));
  }
}

/** Get shopify token from a stripe payment method */
export function* getShopifyToken(paymentMethod: PaymentMethod, stripeTokenId: string) {
  const state: IState = yield select();
  const shopifyPaymentsAccountId = getShopifyPaymentsAccountId(state);
  const stripeCustomerId = getStripeCustomerId(state);
  const accessToken = getPrimaryToken(state) || '';
  const isLogIn = isLoggedIn(state);

  if (!isLogIn) {
    const singleSourceTokenResponse = yield call(fetchTokenFromSingleUseSource, {
      account: shopifyPaymentsAccountId,
      customer: stripeCustomerId || '',
      tokenId: stripeTokenId,
    });
    if (!singleSourceTokenResponse.token) {
      yield put(removePayment(paymentMethod.id));
      throw new Error(singleSourceTokenResponse.message || 'Error adding payment');
    }
    return singleSourceTokenResponse.token.id;
  }

  const shopifyPaymentMethods = getShopifyPaymentMethods(state);
  const shopifyPaymentMethod = shopifyPaymentMethods.find(card => (
    card.card?.fingerprint === paymentMethod.card?.fingerprint
  )) || shopifyPaymentMethods.pop();

  if (!shopifyPaymentMethod) {
    throw new Error('Please add a payment method');
  }

  const response: ITokenResponse = yield call(fetchTokenFromReusableSource, {
    accessToken,
    account: shopifyPaymentsAccountId,
    cardId: shopifyPaymentMethod?.id || '',
    customer: stripeCustomerId || '',
  });

  return response.token.id;
}

/**
 * Helper function to get the payment data input for Shopify
 * @param paymentToken Token from Stripe
 * @param billingAddressSameAsShipping  If the billing address is the same as the shipping address
 * @returns PaymentStorefrontInput
 */
export function* getPaymentStoreFrontInput(paymentToken: string, billingAddressSameAsShipping: boolean) {
  const state = yield select();
  const checkout = getShopifyCheckout(state);
  const shippingAddress = getShippingAddress(state);
  const billingInfo = getBillingInfo(state);
  const isShippingAddressComplete = getIsShippingAddressComplete(state);

  if (!checkout) {
    throw new Error('Checkout not found');
  }

  const getBillingAddressInfo = (): IPaymentStorefrontInput['billingAddress'] => {
    if (!billingInfo.complete) {
      throw new Error('Billing info is incomplete');
    }

    const nameParts = billingInfo.name.split(' ');

    return {
      address1: billingInfo.address || '',
      address2: '',
      city: billingInfo.city || '',
      country: billingInfo.countryCode,
      firstName: nameParts[0],
      lastName: nameParts[nameParts.length - 1],
      phone: '',
      province: billingInfo.stateCode || '',
      zip: billingInfo.postalCode,
    };
  };

  const getShippingAddressInfo = (): IPaymentStorefrontInput['billingAddress'] => {
    if (!isShippingAddressComplete) {
      throw new Error('Shipping address is incomplete');
    }

    return {
      address1: shippingAddress.address1,
      address2: shippingAddress.address2 || '',
      city: shippingAddress.city,
      country: shippingAddress.countryCode,
      firstName: shippingAddress.firstName,
      lastName: shippingAddress.lastName,
      phone: shippingAddress.phoneNumber,
      province: shippingAddress.state,
      zip: shippingAddress.postalCode,
    };
  };

  const billingAddress = billingAddressSameAsShipping ? getShippingAddressInfo() : getBillingAddressInfo();

  const paymentData: IPaymentStorefrontInput = {
    paymentAmount: {
      amount: checkout.paymentDueV2.amount,
      currencyCode: checkout.paymentDueV2.currencyCode,
    },
    idempotencyKey: uuid(),
    billingAddress,
    paymentData: paymentToken,
    type: 'STRIPE_VAULT_TOKEN',
  };

  return paymentData;
}

/**
 * When the user changes the shipping method, we need to update the checkout with the new shipping line.
 */
export function* updateShopifyShippingLinesSaga({ payload }: IUpdateShopifyShippingLine) {
  const state = yield select();
  const currentCheckout = getShopifyCheckout(state);
  const shopInfoData = getShopState(state);
  const { shop, shopAccessToken: storefrontAccessToken } = shopInfoData;

  if (!currentCheckout) {
    yield put(setShopifyErrorMessage('Checkout not found'));
    return;
  }

  // Update the shipping line
  const updateCheckoutResponse: IUpdateCheckoutShippingLineResponse = yield call(updateCheckoutShippingLine, {
    accessToken: storefrontAccessToken,
    checkoutId: currentCheckout.id || '',
    handle: payload,
    shop,
  });

  // Handle shipping line update errors
  if (updateCheckoutResponse.checkoutShippingLineUpdate?.checkoutUserErrors?.length) {
    throw new Error(`Error updating shipping lines: ${updateCheckoutResponse.checkoutShippingLineUpdate?.checkoutUserErrors[0].message}`);
  }

  const newCheckout = {
    ...currentCheckout,
    ...updateCheckoutResponse?.checkoutShippingLineUpdate?.checkout,
  };

  // Poll the checkout until it's ready
  const checkout: ICheckoutStorefront = yield call(pollShopifyCheckoutReady, newCheckout);
  yield put(setShopifyCheckout(checkout));
}

/**
 * Just a helper function to get the errors from the payment response
 */
export function* getErrorsFromPayment(response: CheckoutCompleteWithTokenizedPaymentV3Mutation) {
  const errors = response.checkoutCompleteWithTokenizedPaymentV3?.checkoutUserErrors || [];
  const checkoutLineItems: ICheckoutLineItem[] = response.checkoutCompleteWithTokenizedPaymentV3?.checkout?.lineItems?.edges.map((lineItem: { node: ICheckoutLineItem }) => {
    return lineItem.node;
  }) || [];

  return errors.map(error => {
    const fields = error.field || [];
    switch (error.code) {
      case 'NOT_ENOUGH_IN_STOCK': {
        return `Not enough ${checkoutLineItems[+fields[1]].title || ''} available`;
      }
      default:
        return error.message;
    }
  });
}

/**
 * @description This saga is called when the user has submitted the Shopify checkout form. Checkout needs to be ready.
 */
export function* submitShopifyCheckoutSaga({ payload }: ISubmitShopifyCheckout) {
  const { paymentMethod, billingAddressSameAsShipping, stripeTokenId, email } = payload;
  const state = yield select();
  const checkout = getShopifyCheckout(state);
  const shopInfoData = getShopState(state);
  const siteId = getSiteId(state);
  const accountAddresses = getAccountAddresses(state);
  const primaryToken = getPrimaryToken(state);
  const cartItems = getCartItems(state);
  const isShippingAddressComplete = getIsShippingAddressComplete(state);
  const isAdmin = isUserAdmin(state);
  const selectedShippingMethod = getShippingMethod(state);
  // ensuring the selectedShippingMethod and the checkout shippingLine handle exist and match
  const hasShippingMethod = selectedShippingMethod === checkout?.shippingLine?.handle;
  if (!email) {
    yield put(setShopifyErrorMessage('Email can\'t be empty.'));
    return;
  }

  if (!cartItems.length) {
    yield put(setShopifyErrorMessage('Your cart is empty'));
    return;
  }

  if (!isShippingAddressComplete) {
    yield put(setShopifyErrorMessage('Please complete your shipping address'));
    return;
  }

  if (!checkout) {
    yield put(setShopifyErrorMessage('Checkout not found'));
    return;
  }

  if (!checkout.ready) {
    yield put(setShopifyErrorMessage('Checkout not ready'));
    return;
  }

  if (checkout.requiresShipping && !hasShippingMethod) {
    yield put(setShopifyErrorMessage('Please select a shipping method'));
    return;
  }

  if (isAdmin) {
    yield put(setShopifyErrorMessage('Cannot purchase as an admin'));
    return;
  }

  if (!paymentMethod) {
    yield put(setShopifyErrorMessage('Please add a payment method'));
    return;
  }

  try {
    const token: string = yield call(getShopifyToken, paymentMethod, stripeTokenId || '');

    const { shop, shopAccessToken: storefrontAccessToken } = shopInfoData;
    const paymentData: IPaymentStorefrontInput = yield call(getPaymentStoreFrontInput, token, billingAddressSameAsShipping);
    const paymentResponse: CheckoutCompleteWithTokenizedPaymentV3Mutation = yield call(completeCheckoutWithTokenGraphQL, {
      accessToken: storefrontAccessToken,
      checkoutId: checkout.id || '',
      payment: paymentData,
      shop,
    });
    const errors: string[] = yield call(getErrorsFromPayment, paymentResponse);
    if (errors.length) {
      const errorString = errors.join(', ');
      if (!primaryToken) {
        yield put(removePayment(paymentMethod.id));
      }
      throw new Error(errorString);
    }

    let payment = paymentResponse?.checkoutCompleteWithTokenizedPaymentV3?.payment as IPaymentStorefront;
    while (!payment?.ready) {
      const paymentPollResponse = yield call(getPaymentGraphQL, {
        accessToken: storefrontAccessToken,
        paymentId: payment?.id || '',
        shop,
      });
      payment = paymentPollResponse.node as IPaymentStorefront;
      yield delay(1000);
    }

    if (payment.transaction?.statusV2 !== TransactionStatus.Success) {
      if (!primaryToken) {
        yield put(removePayment(paymentMethod.id));
      }
      throw new Error(payment.errorMessage || 'There was an error with your transaction. Please try again.');
    }

    yield put(shopifyPaymentSucceeded(payment));
    yield call(createCommission, {
      orderId: payment.checkout?.order?.name || '',
      shop,
      siteId,
      total: parseFloat(payment.checkout?.subtotalPriceV2?.amount || '0'),
    });

    const shippingAddress = checkout.shippingAddress.address1;
    const addressExist = accountAddresses.some(address => address.address.address1 === shippingAddress);

    if (!addressExist && primaryToken) {
      yield put(saveAddress({
        address: {
          address1: checkout.shippingAddress.address1 || '',
          address2: checkout.shippingAddress.address2 || '',
          city: checkout.shippingAddress.city || '',
          complete: true,
          countryCode: checkout.shippingAddress.countryCodeV2 || checkout.shippingAddress.country || '',
          firstName: checkout.shippingAddress.firstName || '',
          lastName: checkout.shippingAddress.lastName || '',
          phoneNumber: checkout.shippingAddress.phone || '',
          postalCode: checkout.shippingAddress.zip || '',
          state: checkout.shippingAddress.provinceCode || checkout.shippingAddress.province || '',
          name: checkout.shippingAddress.address1 || '',
        },
        name: checkout.shippingAddress.address1 || '',
        primaryToken,
      }));
    }
  } catch (err) {
    // tslint:disable-next-line no-console
    console.error(err);
    yield put(setShopifyErrorMessage(err.message));
  }
}

/**
 * This saga is called when the user has completed the Shopify payment process.
 */
export function* completeShopifyPaymentProcessSaga() {
  const state = yield select();
  const customPanels = getCustomPanels(state);
  const isLogIn = isUserLoggedIn(state);

  const panel = customPanels[customPanels.length - 1] as DataDoc;
  if (panel?.doc?.data?.kind === SHOPIFY_BLOCK_PANEL_ID) {
    yield put(closePanel());
  } else {
    yield put(resetEcommerceViews());
  }

  yield put(clearCart({ clearDbCart: true }));

  if (!isLogIn) {
    yield put(setPaymentMethods([]));
  }

}
