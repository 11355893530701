export interface ISilencedUser {
  displayName: string;
  userId: string;
  userType: 'anonymous' | 'account';
}

export interface IChatPayload {
  author: {
    accountId?: string;
    loggedIn: boolean;
  }
  data: {
    image: string;
    isAdmin: boolean;
    isChatMod: boolean;
    text: string;
    username: string;
  }
  time: number;
}

export enum SYSTEM_MESSAGE {
  CHAT_DUPLICATE_MESSAGE = 'CHAT_DUPLICATE_MESSAGE',
  CHAT_LONG_MESSAGE = 'CHAT_LONG_MESSAGE',
  CHAT_TOO_FAST = 'CHAT_TOO_FAST',
  CHAT_WELCOME_BACK = 'CHAT_WELCOME_BACK',
}
