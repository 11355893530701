import { SET_GRANT, ISetGrantAction, setView, UPDATE_ENTITLEMENT, IUpdateEntitlementAction, EXPORT_COMMUNITY, setExportFile, setExportStatus, closeModal, updateUserProfile } from './actions';
import IState from 'services/state';
import { getPrimaryToken } from 'services/auth/selectors';
import { put, select, call, takeEvery } from 'redux-saga/effects';
import { setGrantCommunity, updateEntitlementCommunity, exportCommunity, IExportCommunityResponse } from './api';
import { SITE_ID } from 'config';
import { dismissModal, showAdminErrorModal } from 'services/modals/actions';
import { ExportStatus, Views } from './reducer';
import { getUserAccountId, getSearchQuery, getSearch } from './selectors';

export const messageError = {
  granted: 'Entitlement could not be granted',
  remove: 'Entitlement could not be removed',
  cancel: 'Entitlement could not be canceled',
  scheduleCancel: 'Entitlement could not be canceled',
  justRefund: 'Entitlement could not be refunded',
};

export const exportCommunitySaga = function* () {
  const state: IState = yield select();
  const accessToken: string | null = getPrimaryToken(state);
  const search = getSearch(state);
  const searchQuery: string = getSearchQuery(state);
  if (!accessToken) {
    return;
  }
  if (!search.searchBy || (!searchQuery && searchQuery !== '')) {
    return;
  }

  try {
    yield put(setExportStatus(ExportStatus.loading));
    const response: IExportCommunityResponse = yield call(exportCommunity, accessToken, SITE_ID, search);
    const { exportId, fileName, status } = response;
    const exportFile = {
      exportId,
      fileName,
    };
    if (status === ExportStatus.ready) {
      yield put(showAdminErrorModal('Error exporting data. Please try again.'));
    }

    yield put(setExportStatus(status));
    yield put(setExportFile(exportFile));
  } catch (e) {
    const error = e.response?.statusText || e.message || 'Unknown error';
    const message = e.response?.data?.message || error;
    yield put(setExportStatus(ExportStatus.ready));
    yield put(showAdminErrorModal(message));
  }
};

export const setGrantSaga = function* ({ payload: subscriptionId }: ISetGrantAction) {
  const state: IState = yield select();
  const accountId = getUserAccountId(state);
  const accessToken: string | null = getPrimaryToken(state);
  if (!accessToken) {
    return;
  }
  try {
    const response = yield call(setGrantCommunity, accessToken, SITE_ID, accountId, subscriptionId);
    if (response?.success) {
      yield put(setView(Views.default));
      yield put(setView(Views.userProfile));
      yield put(closeModal());
    } else {
      yield put(showAdminErrorModal(messageError.granted));
      yield put(closeModal());
    }
    yield put(updateUserProfile({ subscription: {} }));
  } catch (e) {
    const error = e.response?.statusText || e.message || 'Unknown error';
    const message = e.response?.data?.message || error;
    yield put(showAdminErrorModal(message));
    yield put(closeModal());
    yield put(updateUserProfile({ subscription: {} }));
  }
};

export const updateEntitlementCommunitySaga = function* ({ payload }: IUpdateEntitlementAction) {
  const state: IState = yield select();
  const accountId = getUserAccountId(state);
  const accessToken: string | null = getPrimaryToken(state);
  if (!accessToken) {
    return;
  }
  try {
    const response = yield call(updateEntitlementCommunity, accessToken, SITE_ID, accountId, payload);
    if (response?.success) {
      yield put(setView(Views.default));
      yield put(setView(Views.userProfile));
    } else {
      yield put(showAdminErrorModal(messageError[payload.action]));
    }
    yield put(dismissModal('adminConfirmation'));
    yield put(closeModal());
  } catch (e) {
    const error = e.response?.statusText || e.message || 'Unknown error';
    const message = e.response?.data?.message || error;
    yield put(showAdminErrorModal(message));
    yield put(dismissModal('adminConfirmation'));
    yield put(closeModal());
  }
};

const communitySaga = function* () {
  yield takeEvery(EXPORT_COMMUNITY, exportCommunitySaga);
  yield takeEvery(SET_GRANT, setGrantSaga);
  yield takeEvery(UPDATE_ENTITLEMENT, updateEntitlementCommunitySaga);
};

export default communitySaga;
