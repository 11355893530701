export enum ContentType {
  video = 'video', // videos and twitch streams
  playlist = 'playlist', // if active member
  stream = 'stream', // maestro & twitch livestreams
}

export interface IEPGAsset {
  contentId: string | null; // null if theres nothing broadcasting
  contentType: ContentType;
  duration: number | null; // null for unschedule livestreams e.g. phenix
  live: boolean; // true for livestreams / twitch channels / scheduled video
  startTime: number | null; // based on the server time, null if unschedule livestream
}

// electronic program guide
export interface IEPGResponse {
  asset: IEPGAsset | null;
  channelId: string;
  /**
   * The back-end will send this field when scheduled content is live and
   * the channel has `tv` offline mode with at least one video or playlist.
   */
  nextAsset: IEPGAsset | undefined | null
}
