export enum AdminActionEvents {
    CHANNEL_VIEWED = 'admin.ChannelViewed',
    PAGE_VIEWED = 'admin.PageViewed',
    STREAM = 'stream',
    PANEL_ACTIVATED = 'admin.PanelActivated',
    CARD_ACTIVATED = 'admin.CardActivated',
    SHOPIFY_STORE_CONNECTED = 'admin.ShopifyStoreConnected',
    CHANNEL_PUBLISHED = 'admin.ChannelPublished',
    PAGE_PUBLISHED = 'admin.PagePublished',
    NAVBAR = 'admin.NavigationClicked',
    ADMIN_AUTO_LOGIN = 'admin.AutoLogin',
    ADMIN_LOGIN = 'admin.Login',
    ADMIN_LOGOUT = 'admin.Logout',
    ACCESS_TICKET_CREATED = 'admin.AccessTicketCreated',
    ACCESS_SUBSCRIPTION_CREATED = 'admin.AccessSubscriptionCreated',
    PURCHASE_PLAN_CLICKED = 'admin.PurchasePlanClicked',
    PLAN_PURCHASED = 'admin.PlanPurchased',
    LOGO_SAVED = 'admin.LogoSaved',
    NAVIGATION_SAVED = 'admin.NavigationSaved',
    DESIGN_SAVED = 'admin.DesignSaved',
    WIZARD_STARTED = 'admin.WizardStarted',
    WIZARD_FLOW_CHOSEN = 'admin.WizardFlowChosen',
    WIZARD_FORWARD_STEP = 'admin.WizardForwardStep',
    WIZARD_BACK_STEP = 'admin.WizardBackStep',
    WIZARD_COMPLETED = 'admin.WizardCompleted',
    CREATOR_TYPE_SELECTED = 'admin.CreatorTypeSelected',
    ADMIN_WENT_LIVE = 'admin.livestreamStarted',
}

export interface ITrackChannelPublishedEventData {
    channelId: string;
    channelName: string;
    newPage: boolean;
}

export type ITrackPagePublishedEventData = ITrackChannelPublishedEventData;
