import { SITE_ID, SHOPIFY_SERVICE_BASE_URL } from 'config';
import axios from 'axios';
import { ISaveAddressParams, IAccountAddress } from './models';

export const saveAddress = async ({
  primaryToken,
  address,
  name,
}: ISaveAddressParams): Promise<void> => {
  await axios.post(
    `${SHOPIFY_SERVICE_BASE_URL}/address`,
    {
      name,
      address,
    },
    {
      headers: {
        Authorization: `Bearer ${primaryToken}`,
        'Content-Type': 'application/json',
        'x-maestro-client-id': SITE_ID,
      },
    },
  );
};

export const fetchAccountAddresses = async (primaryToken: string): Promise<IAccountAddress[]> => {
  const { data } = await axios.get(
    `${SHOPIFY_SERVICE_BASE_URL}/address`,
    {
      headers: {
        Authorization: `Bearer ${primaryToken}`,
        'Content-Type': 'application/json',
        'x-maestro-client-id': SITE_ID,
      },
    },
  );

  return data;
};
