import maestroApiRequest from 'services/maestro-api-client';
import { SHOPIFY_SERVICE_BASE_URL } from 'config';
import { IEcommerceCart, ICartItem } from './models';
import { removeCartOrShopifyItemDuplicates } from './utils';

export interface IGetCartsByAccountAndProviderParams {
  primaryToken: string;
  siteId: string;
}

export interface IUpdateCartParams {
  cart: IEcommerceCart,
  primaryToken: string,
  siteId: string,
  type?: string,
}

export const getCartsByAccountId = async ({ primaryToken, siteId }: IGetCartsByAccountAndProviderParams): Promise<IEcommerceCart[]> => {
  const result = await maestroApiRequest({
    primaryToken,
    siteId,
  }).get<IEcommerceCart[]>(`${SHOPIFY_SERVICE_BASE_URL}/cart`);
  return result.data;
};

export const updateCart = async ({ cart, primaryToken, siteId, type = 'shopify' }: IUpdateCartParams): Promise<void> => {
  const cartItems = cart.cartItems.filter((item: ICartItem) => item.quantity > 0);
  cart.cartItems = removeCartOrShopifyItemDuplicates(cartItems) as ICartItem[];
  const body = {
    type,
    ...cart,
  };
  await maestroApiRequest({
    primaryToken,
    siteId,
  }).put<ICartItem>(`${SHOPIFY_SERVICE_BASE_URL}/cart/${cart.cartId}`, body);
};

export const deleteCart = async ({ cart, primaryToken, siteId }: IUpdateCartParams): Promise<void> => {
  await maestroApiRequest({
    primaryToken,
    siteId,
  }).delete(`${SHOPIFY_SERVICE_BASE_URL}/cart/${cart.cartId}`);
};
