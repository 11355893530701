import isEqual from 'lodash/isEqual';
import { CLOSE_PANEL, ICustomPanelsAction, OPEN_PANEL } from './actions';
import { ICustomPanel } from './models';

export type ICustomPanelsState = ICustomPanel[];
const INITIAL_STATE: ICustomPanelsState = [];

export const getInitialState = () => ([
  ...INITIAL_STATE,
]);

const customPanelsReducer = (state: ICustomPanelsState = INITIAL_STATE, action: ICustomPanelsAction) => {
  switch (action.type) {
    case CLOSE_PANEL:
      return state.slice(0, -1);
    case OPEN_PANEL: {
      const lastPanel = state[state.length - 1];
      const panelsCopy = [...state];
      const existingPanelIndex = state.findIndex((panel) => isEqual(action.payload, panel));
      if (existingPanelIndex >= 0) {
        panelsCopy.splice(existingPanelIndex, 1);
        return [...panelsCopy, action.payload];
      }
      if (!isEqual(action.payload, lastPanel)) {
        return [...state, action.payload];
      }
      return state;
    }
    default:
      return state;
  }
};

export default customPanelsReducer;
