import IModel from 'models/IModel';
import { ObjectValues } from 'utils';

export interface IBasePlan extends IModel {
  description: string;
  name: string;
  payoutTerms: PayoutTerms;
  planFeatures: IPlanFeatures;
  planItems: IPlanItem[];
  productType?: ProductType;
  term: PlanTerm;
}

export interface ISelfServicePlan extends IBasePlan {
  colors?: IPlanColors;
  type: typeof PLAN_TYPE.SelfService;
}

export interface IEnterprisePlan extends IBasePlan {
  type: typeof PLAN_TYPE.Enterprise;
}

export interface IUnlimitedPlan extends IBasePlan {
  type: typeof PLAN_TYPE.Unlimited;
}

type Plan = ISelfServicePlan | IEnterprisePlan | IUnlimitedPlan;

export const BILLING_TERM_NAME = {
  entitlements: 'entitlements',
  shopify: 'shopify',
  tips: 'tips',
} as const;
export type PayoutTermName = ObjectValues<typeof BILLING_TERM_NAME>;

type PayoutTermFee = {
  revenueShare: number;
  viewerServiceCharge: number;
};

export type PayoutTerms = Record<PayoutTermName, PayoutTermFee>;

interface IPlanFeatures {
  cloudDataExport: boolean; // support for maestro cloud data export
  customDomain: boolean; // support for maestro custom domains
  customerSuccessManager: boolean; // if a site has a customer success manager
  developerTools: boolean; // support for maestro developer tools
  eventSupport: boolean; // if a site has event support
  freeEvents: boolean; // dictates if the site can have free events
  maxAmountOfAdmins: number; // maximum amount of admins a site can have
  maxAmountOfChannels: number; // maximum amount of channels a site can have
  maxAmountOfPages: number; // maximum amount of pages a site can have
  removeMaestroBranding: boolean; // dictates if the site can remove maestro branding
  serviceLevelAgreement: boolean; // support for maestro service level agreement
  viewerSupport: boolean;
}

export const PLAN_TYPE = {
  SelfService: 'selfservice',
  Enterprise: 'enterprise',
  Unlimited: 'unlimited',
} as const;
export type PlanType = ObjectValues<typeof PLAN_TYPE>;

interface IPlanItem {
  allottedQuantity?: number;
  name: string;
  note?: string;
  stripePriceId: string;
  type: PlanItemType;
  unit: UnitType;
  unitPrice: number;
}

export const PLAN_ITEM_TYPE = {
  general: 'general',
  overages: 'overages',
  addons: 'addons',
} as const;
export type PlanItemType = ObjectValues<typeof PLAN_ITEM_TYPE>;

export const UNIT_TYPE = {
  baseSaasFee: 'saas',
  broadcastHours: 'broadcastHours',
  eventSupportHours: 'eventSupportHours',
  uniqueViewers: 'uniqueViewers',
  viewerHours: 'viewerHours',
  viewerSupportTransactions: 'viewerSupportTransactions',
} as const;
export type UnitType = ObjectValues<typeof UNIT_TYPE>;

export const PRODUCT_TYPE = {
  Maestro: 'maestro',
} as const;
export type ProductType = ObjectValues<typeof PRODUCT_TYPE>;

export const PLAN_TERM = {
  Monthly: 'monthly',
  Annually: 'annually',
} as const;
export type PlanTerm = ObjectValues<typeof PLAN_TERM>;

interface IPlanColors {
  background: string;
  baseColor: string;
  mobileExpand: string;
}

export const MAX_AMOUNT_OF_ADMINS = 200;
export const MAX_AMOUNT_OF_PAGES = 200;
export const MAX_AMOUNT_OF_CHANNELS = 200;

export default Plan;
