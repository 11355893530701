import { ICheckoutStorefront, IPaymentStorefront } from 'services/shopify/models';
import {
  ADD_CART_ITEM,
  REMOVE_CART_ITEM,
  INCREASE_CART_ITEM,
  DECREASE_CART_ITEM,
  UPDATE_CART_ITEM_VARIANT,
  IEcommerceActions,
  LOAD_CART,
  CLEAR_CART,
  SET_SHOPIFY_CHECKOUT,
  TRIGGER_SHOPIFY_CHECKOUT,
  SET_SHOPIFY_ERROR_MESSAGE,
  SHOPIFY_PAYMENT_SUCCEEDED,
  UPDATE_SHOPIFY_SHIPPING_LINE,
  COMPLETE_SHOPIFY_PAYMENT_PROCESS,
  PUSH_ECOMMERCE_VIEW,
  DISMISS_ECOMMERCE_VIEW,
  RESET_ECOMMERCE_VIEWS,
  SUBMIT_SHOPIFY_CHECKOUT,
  SET_PRODUCT_DETAIL,
  REPLACE_ECOMMERCE_VIEW,
  SET_SHIPPING_METHOD,
  SET_LOGGED_OUT_USER_EMAIL,
} from './actions';

import { EcommerceView, ICartItem } from './models';
import { IProductComponentProps } from 'components/Ecommerce/ProductDetail';
import { removeCartOrShopifyItemDuplicates } from './utils';

export interface IEcommerceState {
  cartId: string;
  cartItems: ICartItem[],
  loggedOutUserEmail: string;
  productDetail: IProductComponentProps | null;
  shopify: {
    checkout: ICheckoutStorefront | null;
    error?: string;
    loading: boolean;
    paymentProcessing: boolean;
    shippingMethod: string;
    transaction: IPaymentStorefront['transaction'] | null;
  };
  toggleFloatMenuCounter: number;
  views: EcommerceView[];
}

const INITIAL_STATE: IEcommerceState = {
  cartItems: [],
  loggedOutUserEmail: '',
  productDetail: null,
  shopify: {
    loading: false,
    checkout: null,
    transaction: null,
    paymentProcessing: false,
    shippingMethod: '',
  },
  cartId: '',
  views: [],
  toggleFloatMenuCounter: 0,
};

export const getInitialState = (): IEcommerceState => ({
  ...INITIAL_STATE,
});

const ecommerceReducer = (state: IEcommerceState = INITIAL_STATE, action: IEcommerceActions): IEcommerceState => {
  switch (action.type) {
    case REPLACE_ECOMMERCE_VIEW:
      return {
        ...state,
        views: action.payload,
      };
    case PUSH_ECOMMERCE_VIEW:
      const lastView = state.views[state.views.length - 1];
      if (lastView === action.payload) {
        return state;
      }
      return {
        ...state,
        views: [...state.views, action.payload],
      };
    case DISMISS_ECOMMERCE_VIEW:
      return {
        ...state,
        views: state.views.slice(0, state.views.length - 1),
      };
    case RESET_ECOMMERCE_VIEWS:
      return {
        ...state,
        views: [],
      };
    case COMPLETE_SHOPIFY_PAYMENT_PROCESS:
      return {
        ...state,
        shopify: {
          ...state.shopify,
          checkout: null,
          transaction: null,
          error: '',
          loading: false,
          paymentProcessing: false,
        },
      };
    case SUBMIT_SHOPIFY_CHECKOUT:
      return {
        ...state,
        shopify: {
          ...state.shopify,
          paymentProcessing: true,
        },
      };
    case UPDATE_SHOPIFY_SHIPPING_LINE:
      return {
        ...state,
        shopify: {
          ...state.shopify,
          error: '',
          loading: true,
        },
      };
    case SHOPIFY_PAYMENT_SUCCEEDED:
      return {
        ...state,
        shopify: {
          ...state.shopify,
          checkout: action.payload.checkout!,
          error: '',
          loading: false,
          transaction: action.payload.transaction,
          paymentProcessing: false,
        },
        views: ['success'],
      };
    case SET_SHOPIFY_ERROR_MESSAGE:
      return {
        ...state,
        shopify: {
          ...state.shopify,
          error: action.payload,
          loading: false,
          paymentProcessing: false,
        },
      };
    case TRIGGER_SHOPIFY_CHECKOUT:
      return {
        ...state,
        shopify: {
          ...state.shopify,
          error: '',
          loading: true,
        },
      };
    case SET_SHOPIFY_CHECKOUT:
      return {
        ...state,
        shopify: {
          ...state.shopify,
          checkout: action.payload,
          loading: false,
        },
      };
    case SET_PRODUCT_DETAIL:
      return {
        ...state,
        productDetail: action.payload,
      };
    case REMOVE_CART_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => !(item.productId === action.payload.productId && item.variantId === action.payload.variantId)),
      };
    case ADD_CART_ITEM:
      return {
        ...state,
        cartItems: removeCartOrShopifyItemDuplicates([...state.cartItems, action.payload]) as ICartItem[],
      };
    case INCREASE_CART_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.map((item) => {
          if (item.productId === action.payload.productId && item.variantId === action.payload.variantId) {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          }
          return item;
        }),
      };
    case DECREASE_CART_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.map((item) => {
          if (item.productId === action.payload.productId && item.variantId === action.payload.variantId) {
            return {
              ...item,
              quantity: Math.max(0, item.quantity - 1),
            };
          }
          return item;
        }),
      };
    case UPDATE_CART_ITEM_VARIANT:
      const updatedCartItems = state.cartItems.map((item) => {
        if (
          item.productId === action.payload.productId &&
          item.variantId === action.payload.variantId
        ) {
          return {
            ...item,
            variantId: action.payload.updatedVariantId,
          };
        }
        return item;
      });
      return {
        ...state,
        cartItems: removeCartOrShopifyItemDuplicates(updatedCartItems) as ICartItem[],
      };
    case SET_SHIPPING_METHOD:
      return {
        ...state,
        shopify: {
          ...state.shopify,
          shippingMethod: action.payload,
        },
      };
    case SET_LOGGED_OUT_USER_EMAIL:
      return {
        ...state,
        loggedOutUserEmail: action.payload,
      };
    case LOAD_CART:
      return {
        ...state,
        cartItems: action.payload.cartItems,
        cartId: action.payload.cartId,
      };
    case CLEAR_CART:
      return {
        ...state,
        cartItems: [],
      };
    default:
      return state;
  }
};

export default ecommerceReducer;
