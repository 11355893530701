import axios from 'axios';
import { API_KEY_BASE_URL, SITE_SERVICE_BASE_URL, WEBHOOKS_BASE_URL } from 'config';
import maestroApiRequest from 'services/maestro-api-client';
import { IWebhooks } from './models';

interface IUpdatePlainTextCodeInjectionParams {
  body: string;
  header: string;
  primaryToken: string;
  siteId: string;
}

export const postWebhooks = (primaryToken: string | null, siteId: string, webhooks: IWebhooks) => (
  axios({
    data: {
      webhooks,
    },
    headers: {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    },
    method: 'POST',
    url: WEBHOOKS_BASE_URL,
  })
);

export const putWebhooks = (primaryToken: string | null, siteId: string, webhooks: IWebhooks) => {
  return (
    axios({
      data: {
        webhooks,
      },
      headers: {
        Authorization: `Bearer ${primaryToken}`,
        'x-maestro-client-id': siteId,
      },
      method: 'PUT',
      url: WEBHOOKS_BASE_URL,
    })
  );
};

export const getApiKey = (primaryToken: any, siteId: any) => (
  axios({
    headers: {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    },
    method: 'GET',
    url: `${API_KEY_BASE_URL}/siteid`,
  })
);

export const createApiKey = (clientName: any, generatedBy: any, siteId: any, roles: any, primaryToken: any) => (
  axios({
    data: {
      clientName,
      roles,
      siteId,
      generatedBy,
    },
    headers: {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    },
    method: 'POST',
    url: API_KEY_BASE_URL,
  }));

export const deleteApiKey = (
  apiKeyId: any,
  primaryToken: any,
  siteId: any,
) => (
  axios({
    headers: {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    },
    method: 'DELETE',
    url: `${API_KEY_BASE_URL}/${apiKeyId}`,
  }));

export const testEvent = (
  webhookUrl: string,
  developerApiKey: string,
) => {
  return (
    axios({
      headers: {
        'Content-Type': 'text/plain',
      },
      data: {
        eventData: null,
        method: 'POST',
        service: 'test v1',
        signature: developerApiKey,
        webhookUrl,
      },
      method: 'POST',
      url: webhookUrl,
    })
  );
};

export const getCodeInjectionCode = (url:string) => (
  axios({
    headers: {
      // needed to bypass GCP CDN cache.
      // https://cloud.google.com/cdn/docs/caching#bypassing-cache
      'Pragma': 'no-cache',
    },
    method: 'GET',
    url,
  })
);

/**
 *
 * @param params.header - Code injection as plain text to be added to bucket
 * @param params.body - Code injection as plain text to be added to bucket
 */
export const updatePlainTextCodeInjection = async ({ header, body, primaryToken, siteId }: IUpdatePlainTextCodeInjectionParams) => {
  await maestroApiRequest({
    primaryToken,
    siteId,
  }).post(`${SITE_SERVICE_BASE_URL}/code_injection`, {
    header,
    body,
  });
};
