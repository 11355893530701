import { SubMenuKey } from 'components/admin-bridge/AdminBar/constants';
import { IAccessTokenData, IFoundAccount, IGlobalAccountAdminSites } from './models';

export const DISMISS_LOGIN_ERROR = 'auth/DISMISS_LOGIN_ERROR';
export const DISMISS_REGISTER_ERROR = 'auth/DISMISS_REGISTER_ERROR';
export const LOG_IN = 'auth/LOG_IN';
export const LOG_IN_SUCCESS = 'auth/LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'auth/LOG_IN_FAILURE';
export const LOG_OUT = 'auth/LOG_OUT';
export const AMAZON_LOG_IN = 'auth/AMAZON_LOG_IN';
export const UBISOFT_LOG_IN = 'auth/UBISOFT_LOG_IN';
export const ANON_SESSION_INIT = 'auth/ANON_SESSION_INIT';
export const REFRESH_TOKENS = 'auth/REFRESH_TOKENS';
export const REGISTER_USER_FAILURE = 'auth/REGISTER_USER_FAILURE';
export const REGISTER_USER = 'auth/REGISTER_USER';
export const REFRESH_USER = 'auth/REFRESH_USER';
export const UPDATE_USER = 'auth/UPDATE_USER';
export const TRACKING_ACCEPTED = 'auth/TRACKING_ACCEPTED';
export const SET_ACCOUNT_DATA = 'auth/SET_ACCOUNT_DATA';
export const SET_SUPER_ADMIN = 'auth/SET_SUPER_ADMIN';
export const CHECK_GATE = 'auth/CHECK_GATE';
export const SET_ACCOUNT_NAME = 'auth/SET_ACCOUNT_NAME';
export const ACCOUNT_LOOKUP = 'auth/ACCOUNT_LOOKUP';
export const SET_FOUND_ACCOUNT = 'auth/SET_FOUND_ACCOUNT';
export const SET_ACCOUNT_TAGS = 'auth/SET_ACCOUNT_TAGS';
export const GLOBAL_ACCOUNTS_LOG_IN_SUCCESS = 'auth/GLOBAL_ACCOUNTS_LOG_IN_SUCCESS';
export const SET_GLOBAL_ACCOUNT_ADMIN_SITES = 'auth/SET_GLOBAL_ACCOUNT_ADMIN_SITES';
export const REDIRECT_GLOBAL_ACCOUNT_TO_SITE = 'auth/REDIRECT_GLOBAL_ACCOUNT_TO_SITE';
export const SET_GLOBAL_ACCOUNT = 'auth/SET_GLOBAL_ACCOUNT';
export const RESET_GLOBAL_ACCOUNT = 'auth/RESET_GLOBAL_ACCOUNT';

export type IAuthAction = ILogInAction |
  ILogInSuccessAction |
  ILogInFailureAction |
  ILogOutAction |
  IAnonSessionInitAction |
  IRefreshTokensAction |
  IRegisterUserErrorAction |
  ISetAccountAction |
  ITrackingAcceptedAction |
  IUbisoftLogInAction |
  ISetSuperAdminAction |
  ICheckGateAction |
  IDismissLoginError |
  IDismissRegisterError |
  ISetAccountName |
  ISetAccountFoundAction |
  ISetAccountTags |
  IGlobalAccountsLogInSuccessAction |
  ISetGlobalAccountAdminSites |
  IRedirectGlobalAccountToSiteAction |
  ISetGlobalAccountAction |
  IResetGlobalAccountAction;

export interface ISetAccountName {
  payload: string,
  type: typeof SET_ACCOUNT_NAME
}

export interface ILoginResponse {
  deviceId: string;
  inviteId?: string;
  jwt: string;
  provider?: string;
  refreshToken: string;
  service: string;
}

export interface IEmailPasswordInfo {
  email: string;
  firstLogin?: boolean;
  globalAccountAuth?: boolean;
  inviteId?: string;
  password: string;
  provider?: string;
  strategy: 'email';
  subscriptionCheckoutData?: object;
}

export interface IAccountLookupPayload {
  email: string;
  globalAccountAuth?: boolean;
}

export interface IRegisterUserInfo {
  email: string;
  name: string;
  password: string;
  provider?: string;
  subscriptionCheckoutData?: object;
}

export interface IUpdateUserInfo {
  adminBarSubMenuQueue?: SubMenuKey[];
}

export enum OauthProvider {
  AMAZON = 'amazon',
  ADOBE = 'adobe',
  BATTLENET = 'battlenet',
  BOWLTV = 'bowltv',
  CORNELL = 'cornell',
  EPIC = 'epic',
  GETTR = 'gettr',
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  FACEBOOK = 'facebook',
  ONE_FC = 'onefc',
  TWITCH = 'twitch',
  UBISOFT = 'ubisoft',
  XBOX = 'xbox',
  UMG = 'umg1',
  PLAYSTATION = 'playstation',
}

interface IOauthInfo {
  provider: OauthProvider;
  strategy: 'oauth';
}

interface ILegacyInfo {
  response: ILoginResponse;
  strategy: 'legacy';
}

type LoginInfo = IEmailPasswordInfo | IOauthInfo | ILegacyInfo;

export interface ILogInAction {
  payload: LoginInfo;
  type: typeof LOG_IN;
}

interface ILogInFailureAction {
  payload: {
    message: string;
  };
  type: typeof LOG_IN_FAILURE;
}

export interface ILogOutAction {
  payload: {};
  type: typeof LOG_OUT;
}

interface IAnonSessionInitAction {
  payload: null;
  type: typeof ANON_SESSION_INIT;
}

export interface ILogInSuccessAction {
  payload: ILoginSuccessPayload;
  type: typeof LOG_IN_SUCCESS;
}

export interface IGlobalAccountsLogInSuccessAction {
  payload: ILoginSuccessPayload;
  type: typeof GLOBAL_ACCOUNTS_LOG_IN_SUCCESS;
}

interface ILoginSuccessPayload {
  accessToken: string;
  auto: boolean;
  firstLogin?: boolean;
  refreshToken: string;
  service: string;
  subscriptionCheckoutData?: object;
}

export interface IRegisterUserAction {
  payload: IRegisterUserInfo;
  type: typeof REGISTER_USER;
}

export interface IRedirectGlobalAccountToSiteAction {
  payload: IRedirectGlobalAccountToSitePayload;
  type: typeof REDIRECT_GLOBAL_ACCOUNT_TO_SITE;
}

export interface IRedirectGlobalAccountToSitePayload {
  siteId: string;
  siteSlug: string;
}

interface IRegisterUserErrorAction {
  payload: {
    message: string,
  };
  type: typeof REGISTER_USER_FAILURE;
}

interface IRefreshTokensPayload {
  accessToken: string;
  previousRefreshToken: string;
  refreshToken: string;
}

export interface ISetGlobalAccountAdminSites {
  payload: IGlobalAccountAdminSites[];
  type: typeof SET_GLOBAL_ACCOUNT_ADMIN_SITES;
}

export interface IRefreshTokensAction {
  payload: IRefreshTokensPayload;
  type: typeof REFRESH_TOKENS;
}

export interface IRefreshUserAction {
  type: typeof REFRESH_USER;
}

export interface IUpdateUserAction {
  payload: IUpdateUserInfo;
  type: typeof UPDATE_USER;
}

interface ITrackingAcceptedAction {
  payload: null;
  type: typeof TRACKING_ACCEPTED;
}

 interface IAmazonLogInAction {
  type: typeof AMAZON_LOG_IN;
}

interface IUbisoftLogInAction {
  type: typeof UBISOFT_LOG_IN;
}

interface ISetGlobalAccountAction {
  payload: IAccessTokenData | null;
  type: typeof SET_GLOBAL_ACCOUNT;
}

interface IResetGlobalAccountAction {
  type: typeof RESET_GLOBAL_ACCOUNT;
}

export interface IAccountLookupAction {
  payload: IAccountLookupPayload;
  type: typeof ACCOUNT_LOOKUP;
}

export interface ISetAccountFoundAction {
  payload: IFoundAccount | null;
  type: typeof SET_FOUND_ACCOUNT;
}

export interface ISetAccountTags {
  payload: string[];
  type: typeof SET_ACCOUNT_TAGS;
}

export const amazonLogIn = (): IAmazonLogInAction => ({
  type: AMAZON_LOG_IN,
});

export const ubisoftLogIn = (): IUbisoftLogInAction => ({
  type: UBISOFT_LOG_IN,
});

export const refreshUser = (): IRefreshUserAction => ({
  type: REFRESH_USER,
});

export const updateUser = (payload: IUpdateUserInfo): IUpdateUserAction => ({
  payload,
  type: UPDATE_USER,
});

export function logIn(info: LoginInfo): ILogInAction {
  return {
    payload: info,
    type: LOG_IN,
  };
}

export function logInSuccess(payload: ILoginSuccessPayload): ILogInSuccessAction {
  return {
    payload,
    type: LOG_IN_SUCCESS,
  };
}

export function globalAccountLoginSuccess(payload: ILoginSuccessPayload): IGlobalAccountsLogInSuccessAction {
  return {
    payload,
    type: GLOBAL_ACCOUNTS_LOG_IN_SUCCESS,
  };
}

export function logInFailure(message: string): ILogInFailureAction {
  return {
    payload: {
      message,
    },
    type: LOG_IN_FAILURE,
  };
}

export function registerUser(payload: IRegisterUserInfo): IRegisterUserAction {
  return {
    payload,
    type: REGISTER_USER,
  };
}

export function registerUserError(message: string): IRegisterUserErrorAction {
  return {
    payload: {
      message,
    },
    type: REGISTER_USER_FAILURE,
  };
}

export function logOut(): ILogOutAction {
  return {
    payload: {},
    type: LOG_OUT,
  };
}

export function anonSessionInit(): IAnonSessionInitAction {
  return {
    payload: null,
    type: ANON_SESSION_INIT,
  };
}

export function refreshTokens(payload: IRefreshTokensPayload): IRefreshTokensAction {
  return {
    payload,
    type: REFRESH_TOKENS,
  };
}

export function setGlobalAccountAdminSites(payload: IGlobalAccountAdminSites[]): ISetGlobalAccountAdminSites {
  return {
    payload,
    type: SET_GLOBAL_ACCOUNT_ADMIN_SITES,
  };
}

export function trackingAccepted(): ITrackingAcceptedAction {
  return {
    payload: null,
    type: TRACKING_ACCEPTED,
  };
}

export interface ISetAccountAction {
  payload: IAccessTokenData | null;
  type: typeof SET_ACCOUNT_DATA;
}
export const setAccountData = (accountDoc: IAccessTokenData | null): ISetAccountAction => ({
  payload: accountDoc,
  type: SET_ACCOUNT_DATA,
});

interface ISetSuperAdminAction {
  payload: boolean;
  type: typeof SET_SUPER_ADMIN;
}

export const setSuperAdmin = (value: boolean): ISetSuperAdminAction => ({
  payload: value,
  type: SET_SUPER_ADMIN,
});

export interface ICheckGateAction {
  type: typeof CHECK_GATE
}

export const checkGate = (): ICheckGateAction => ({
  type: CHECK_GATE,
});


interface IDismissLoginError {
  type: typeof DISMISS_LOGIN_ERROR;
}
export const dismissLoginError = (): IDismissLoginError => ({
  type: DISMISS_LOGIN_ERROR,
});

interface IDismissRegisterError {
  type: typeof DISMISS_REGISTER_ERROR;
}
export const dismissRegisterError = (): IDismissRegisterError => ({
  type: DISMISS_REGISTER_ERROR,
});

export const setAccountName = (name: string) => ({
  type: SET_ACCOUNT_NAME,
  payload: name,
});

export function accountLookup(payload: IAccountLookupPayload): IAccountLookupAction {
  return {
    payload,
    type: ACCOUNT_LOOKUP,
  };
}

export function setAccountFound(payload: IFoundAccount | null): ISetAccountFoundAction {
  return {
    payload,
    type: SET_FOUND_ACCOUNT,
  };
}

export const setAccountTags = (payload: string[]): ISetAccountTags => ({
  payload,
  type: SET_ACCOUNT_TAGS,
});

export const redirectGlobalAccountToSite = (payload: IRedirectGlobalAccountToSitePayload): IRedirectGlobalAccountToSiteAction => ({
  payload,
  type: REDIRECT_GLOBAL_ACCOUNT_TO_SITE,
});

export const setGlobalAccount = (payload: IAccessTokenData | null): ISetGlobalAccountAction => ({
  payload,
  type: SET_GLOBAL_ACCOUNT,
});

export const resetGlobalAccount = (): IResetGlobalAccountAction => ({
  type: RESET_GLOBAL_ACCOUNT,
});
