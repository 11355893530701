import { SET_ANON_USERNAME, SET_EXPORT_GENERATING, SET_SILENCED_USERS, IChatActions } from './actions';
import { ISilencedUser } from './models';

export interface IChatState {
  anonUsername: null | string;
  exportGenerating: boolean;
  silencedUsers: ISilencedUser[];
}

export const getInitialState = (): IChatState => ({
  anonUsername: null,
  exportGenerating: false,
  silencedUsers: [],
});

const chatReducer = (state = getInitialState(), action: IChatActions) => {
  switch (action.type) {
    case SET_ANON_USERNAME: {
      const anonUsername = action.payload;
      return {
        ...state,
        anonUsername,
      };
    }
    case SET_EXPORT_GENERATING: {
      const exportGenerating = action.payload;
      return {
        ...state,
        exportGenerating,
      };
    }
    case SET_SILENCED_USERS: {
      const { silencedUsers } = action.payload;
      return {
        ...state,
        silencedUsers,
      };
    }
    default:
      return state;
  }
};

export default chatReducer;
