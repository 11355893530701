import IState from 'services/state';
import { getDevicePermanentId } from 'services/device';
import { persistenceService } from 'services/persistence';
import { ICartItem, IEcommerceCart } from '../models';
import { call, put, select } from 'redux-saga/effects';
import { IAddCartItem, IClearCart, IDecreaseCartItem, IIncreaseCartItem, IRemoveCartItem, IUpdateCartItemVariant, loadCart, resetEcommerceViews } from '../actions';
import { updateCart, getCartsByAccountId } from '../api';
import { getPrimaryToken } from 'services/auth';
import { getSiteId } from 'services/app';
import { getEcommerceView } from '../selectors';
import { removeCartOrShopifyItemDuplicates } from '.././utils';

const getDefaultCart = (): IEcommerceCart => ({
  cartId: '',
  cartItems: [],
});

export function* getCartKey() {
  const type = 'shopify'; // default is shopify for now
  const state: IState = yield select();
  const deviceId = getDevicePermanentId(state);
  return `ecommerce_cart_${type}_${deviceId}`;
}

export function* addEcommerceCartItemSaga({ payload }: IAddCartItem) {
  const key = yield call(getCartKey);
  const persistence = persistenceService();
  const cart: IEcommerceCart = (yield call(persistence.read, key)) || getDefaultCart();
  const itemIndex = cart.cartItems.findIndex(item => item.productId === payload.productId && item.variantId === payload.variantId);
  if (itemIndex === -1) {
    cart.cartItems.push(payload);
  } else {
    cart.cartItems[itemIndex].quantity += payload.quantity;
  }

  yield call(persistence.write, key, cart);

  // we update backend cart data in case user wants to continue checkout on another browser
  const state: IState = yield select();
  const primaryToken = getPrimaryToken(state);
  if (primaryToken && cart.cartId) {
    const siteId = getSiteId(state);
    try {
      yield call(updateCart, {
          cart,
          primaryToken,
          siteId,
      });
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(`Error adding cart to database: ${e.message}`);
    }
  }
}

export function* updateEcommerceCartItemVariantSaga({ payload }: IUpdateCartItemVariant) {
  if (payload.updatedVariantId === payload.variantId) {
    return;
  }

  const key = yield call(getCartKey);
  const persistence = persistenceService();
  const cart: IEcommerceCart = (yield call(persistence.read, key)) || getDefaultCart();
  const itemIndex = cart.cartItems.findIndex(item => item.productId === payload.productId && item.variantId === payload.variantId);
  if (itemIndex === -1) {
    return;
  } else {
    cart.cartItems[itemIndex].variantId = payload.updatedVariantId;
  }

  yield call(persistence.write, key, cart);

  // we update backend cart data in case user wants to continue checkout on another browser
  const state: IState = yield select();
  const primaryToken = getPrimaryToken(state);
  if (primaryToken && cart.cartId) {
    const siteId = getSiteId(state);
    try {
      yield call(updateCart, {
          cart,
          primaryToken,
          siteId,
      });
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(`Error adding cart to database: ${e.message}`);
    }
  }
}

export function* decreaseEcommerceCartItemSaga({ payload }: IDecreaseCartItem) {
  const key = yield call(getCartKey);
  const persistence = persistenceService();
  const cart: IEcommerceCart = (yield call(persistence.read, key)) || getDefaultCart();

  const itemIndex = cart.cartItems.findIndex(item => item.productId === payload.productId && item.variantId === payload.variantId);
  if (itemIndex === -1) {
    return;
  }

  cart.cartItems[itemIndex].quantity = Math.max(0, cart.cartItems[itemIndex].quantity - 1);
  yield call(persistence.write, key, cart);

  // we update backend cart data in case user wants to continue checkout on another browser
  const state: IState = yield select();
  const primaryToken = getPrimaryToken(state);
  if (primaryToken && cart.cartId) {
    const siteId = getSiteId(state);
    try {
      yield call(updateCart, {
        cart,
        primaryToken,
        siteId,
      });
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(`Error updating cart in database: ${e.message}`);
    }
  }
}

export function* increaseEcommerceCartItemSaga({ payload }: IIncreaseCartItem) {
  const key = yield call(getCartKey);
  const persistence = persistenceService();
  const cart: IEcommerceCart = (yield call(persistence.read, key)) || getDefaultCart();

  const itemIndex = cart.cartItems.findIndex(item => item.productId === payload.productId && item.variantId === payload.variantId);
  if (itemIndex === -1) {
    return;
  }

  cart.cartItems[itemIndex].quantity += 1;
  yield call(persistence.write, key, cart);

  // we update backend cart data in case user wants to continue checkout on another browser
  const state: IState = yield select();
  const primaryToken = getPrimaryToken(state);
  if (primaryToken && cart.cartId) {
    const siteId = getSiteId(state);
    try {
      yield call(updateCart, {
        cart,
        primaryToken,
        siteId,
      });
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(`Error updating cart in database: ${e.message}`);
    }
  }
}

export function* removeEcommerceCartItemSaga({ payload }: IRemoveCartItem) {
  const key = yield call(getCartKey);
  const persistence = persistenceService();
  const cart: IEcommerceCart = (yield call(persistence.read, key)) || getDefaultCart();
  const currentView = yield select(getEcommerceView);

  const itemIndex = cart.cartItems.findIndex(item => item.productId === payload.productId && item.variantId === payload.variantId);
  if (itemIndex === -1) {
    return;
  }

  cart.cartItems.splice(itemIndex, 1);

  // Redirect user back to store if cart is empty
  if (cart.cartItems.length === 0 && ['checkout', 'cart'].includes(currentView)) {
    yield put(resetEcommerceViews());
  }

  yield call(persistence.write, key, cart);

  // we update backend cart data in case user wants to continue checkout on another browser
  const state: IState = yield select();
  const primaryToken = getPrimaryToken(state);
  if (primaryToken && cart.cartId) {
    const siteId = getSiteId(state);
    try {
      yield call(updateCart, {
        cart,
        primaryToken,
        siteId,
      });
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(`Error updating cart in database: ${e.message}`);
    }
  }
}

export function* loadEcommerceCartSaga() {
  const state: IState = yield select();
  const primaryToken = getPrimaryToken(state);
  const key = yield call(getCartKey);
  const localStorageCart: IEcommerceCart = (yield call(persistenceService().read, key)) || getDefaultCart();
  if (!primaryToken) {
    yield put(loadCart(localStorageCart));
  } else {
    try {
      const siteId = getSiteId(state);
      const carts = yield call(getCartsByAccountId,
        {
          primaryToken,
          siteId,
        });
      const cart = carts.length ? carts[0] : null;
      // sync cart in db
      if (cart) {
        // Create a set of productIds from the database cart
        const databaseProductIds = new Set(cart.cartItems.map((item: ICartItem) => item.productId));

        // Filter out items from the local storage cart that already exist in the database cart
        const filteredLocalCartItems = localStorageCart.cartItems.filter(item => !databaseProductIds.has(item.productId));

        // Remove locally stored cart item duplicates
        const localCartItems = removeCartOrShopifyItemDuplicates(filteredLocalCartItems);

        // Merge the two arrays
        const cartItems = [...localCartItems, ...cart.cartItems];
        yield put(loadCart({ cartId: cart._id, cartItems }));
        yield call(persistenceService().write, key, { cartId: cart._id, cartItems });
      }
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.error(e, 'error loading cart in saga');
    }
  }
}

export function* clearCartSaga({ payload }: IClearCart) {
  const { clearDbCart } = payload;
  const key = yield call(getCartKey);
  const persistence = persistenceService();
  const localStorageCart: IEcommerceCart = (yield call(persistence.read, key)) || getDefaultCart();
  const newCart: IEcommerceCart = { cartId: localStorageCart.cartId, cartItems: [] };
  yield call(persistenceService().write, key, newCart);

  if (localStorageCart.cartId && clearDbCart) {
    const state: IState = yield select();
    const primaryToken = getPrimaryToken(state);
    if (primaryToken) {
      const siteId = getSiteId(state);
      try {
        yield call(updateCart, {
          cart: newCart,
          primaryToken,
          siteId,
      });
      } catch (e) {
        // tslint:disable-next-line: no-console
        console.error(`Error deleting cart from database: ${e.message}`);
      }
    }
  }
}
