import axios from 'axios';
import invariant from 'invariant';

import {
  ADMIN_SERVICE_BASE_URL,
  QUEST_BASE_URL,
  ACCOUNTS_SERVICE_BASE_URL,
} from 'config';
import { joinKeySegments } from 'firebase-utils';
import { snakeify } from 'shared/string-utils';
import maestroApiRequest from 'services/maestro-api-client';

const ADMIN_SAVE_URL = `${ADMIN_SERVICE_BASE_URL}/legacy/save`;
const ADMIN_UNARCHIVE_URL = `${ADMIN_SERVICE_BASE_URL}/unarchive`;
const ADMIN_ARCHIVE_URL = `${ADMIN_SERVICE_BASE_URL}/objects`;
const ADMIN_PAGE_ITEM_VALIDATION_URL = `${ADMIN_SERVICE_BASE_URL}/validate-cross-channel-object`;

const isNonEmptyString = s => s && typeof s === 'string';

const writeKey = (config) => {
  const {
    key,
    primaryToken,
    siteId,
    value,
  } = config;
  invariant(
    (
      isNonEmptyString(key) &&
      isNonEmptyString(siteId) &&
      isNonEmptyString(primaryToken) &&
      value // TODO: We should theoretically accept null/false, but admin save endpoint barfs
    ),
    `writeKey: invalid config: ${JSON.stringify(config, null, 2)}`,
  );
  const pathLength = key.split(/\/+/).filter(part => part).length;
  invariant(
    pathLength >= 2,
    `writeKey: invalid key: ${JSON.stringify(key)}`,
  );
  const postBody = {
    key,
    site_id: siteId,
    token: primaryToken,
    value: JSON.stringify(snakeify(value)),
  };
  return axios({
    data: postBody,
    method: 'POST',
    url: ADMIN_SAVE_URL,
  });
};

export const setDocument = ({
  collection,
  id,
  primaryToken,
  siteId,
  value,
}) => {
  const key = joinKeySegments([collection, id]);
  if (value !== null) {
    invariant(
      id === value._id,
      'setDocument: id must match document\'s _id field',
    );
    if (collection !== 'sites') { // TODO: Better validation
      invariant(
        siteId === value.siteId,
        `setDocument: siteId param is ${siteId} but value.siteId is ${value.siteId}`,
      );
    }
  }
  return writeKey({
    key,
    primaryToken,
    siteId,
    value,
  });
};

// TODO: Remove this function in BE
export const unarchiveDocument = ({ collection, id, primaryToken, siteId }) => {
  const headers = { Authorization: `Bearer ${primaryToken}` };
  const postBody = {
    collection,
    site_id: siteId,
  };

  return axios({
    data: postBody,
    headers,
    method: 'POST',
    url: `${ADMIN_UNARCHIVE_URL}/${id}`,
  });
};

export const archiveDocument = ({ id, primaryToken, siteId }) => {
  const headers = { Authorization: `Bearer ${primaryToken}` };
  const postBody = {
    site_id: siteId,
  };

  return axios({
    data: postBody,
    headers,
    method: 'DELETE',
    url: `${ADMIN_ARCHIVE_URL}/${id}`,
  });
};

export const validateCrossChannelObject = ({ channelId, id, primaryToken }) => (
  axios({
    headers: { Authorization: `Bearer ${primaryToken}` },
    method: 'GET',
    url: `${ADMIN_PAGE_ITEM_VALIDATION_URL}/${channelId}/${id}`,
  })
);

export const createQuest = ({ questDoc, primaryToken, siteId }) => (
  axios({
    data: questDoc,
    headers: {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    },
    method: 'POST',
    url: QUEST_BASE_URL,
  })
);

export const updateQuest = ({ questDoc, primaryToken, siteId }) => (
  axios({
    data: questDoc,
    headers: {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    },
    method: 'PUT',
    url: `${QUEST_BASE_URL}/${questDoc._id}`,
  })
);

export const putTag = async (accessToken, siteId, accountId, tags) => {
  const { data } = await axios.put(`${ACCOUNTS_SERVICE_BASE_URL}/update-tag/${accountId}`, {
    tags,
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-maestro-client-id': siteId,
    },
  });
  return data;
};

// return 204 on success and 401 on failure
export const siteCanUseEntitlementGate = async (primaryToken, siteId) => {
  await maestroApiRequest({
    primaryToken,
    siteId,
  }).get(`${ADMIN_SERVICE_BASE_URL}/check-entitlement-gate`);
};
