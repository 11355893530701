import { createSelector } from 'reselect';
import { DataDoc } from './models';
import IState from '../state';
import { EMPTY_ARRAY } from '../utils';

export const getCustomPanels = createSelector((state: IState) => state.customPanels, (panels) => panels || EMPTY_ARRAY);

export const isProfilePanelOpen = createSelector(getCustomPanels, (panels) => (
  panels.some(panel => (panel as DataDoc).doc?.data?.kind === 'profile')
));

export const getIsCardPanelOpen = createSelector(
  [getCustomPanels], (panels) => panels.some(panel => Boolean((panel as DataDoc).doc?.data?.cardDoc)),
);
