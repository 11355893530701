import {
  takeEvery as _takeEvery,
  takeLatest as _takeLatest,
  takeLeading as _takeLeading,
} from '@redux-saga/core/effects';

export const prefix = <Prefix extends string>(label: Prefix) => (
  <Action extends string>(actionType: Action): `${Prefix}/${Action}` => `${label}/${actionType}`
);

/**
 * This utility type returns a new type that is obtained by filtering
 * out of the source type `T` all the properties that are not a subtype of `U`.
 * One can think of `U` as the criteria that has to be met for a property of `T`
 * to remain on the resulting type.
 */
type FilteredKeys<T, U> = { [P in keyof T]: T[P] extends U ? P : never }[keyof T];

/**
 * If type `T` is a function, then this utility type returns the return type
 * of that function. Otherwise, it returns `never`.
 */
type ReturnTypeIfFnOrNothingOtherwise<T> = T extends (...args: any) => any ? ReturnType<T> : never;

/**
 * Given some array type `T`, `Tail<T> `returns a type that has every single element
 * of `T`, except for the first element.
 * Here is how it works:
 * - It gets the type `T` and puts it in a function type declaration
 *   like `(arg1: T[0], arg2: T[0]) => void`, but it uses the spread operator because
 *   we don't know the length of T, thus the dummy fn is `(...args: T) => void`
 * - Now, it'll try to see if the dummy function can be rewritten as `(firstArg: any, ...rest: X) => void`
 * - If the dummy function can be rewritten in such way, then the resulting type is X, and that's how we
 * capture all elements of `T`, excluding the first one.
 * So the dummy function was used as a hack, `Tail<T>` has nothing to do with functions themselves.
 */
type Tail<T extends readonly any[]> =
  ((...t: T) => void) extends ((h: any, ...r: infer R) => void) ? R : never;

/**
 * Given a object of type `T`, this util function we'll go through every property of `T`
 * to check if it's a function. If a prop is a function, then it returns the return type
 * of that function, otherwhise it returns nothing.
 *
 *    type InputObject = {
 *      someProp: string,
 *      otherProp: object,
 *      fnProp: () => { result: boolean }
 *    }
 *
 *    // TA<InputObject>
 *    type OutputType = {
 *      fnProp: {
 *        result: boolean;
 *      };
 *    }
 */
type TA<T> = { [K in FilteredKeys<T, (...args: any) => any>]: ReturnTypeIfFnOrNothingOtherwise<T[K]> };

/**
 *
 * @param originalTaker the original saga `takeEvery`, `takeLatest` or `takeLeading` effect
 * @returns basically the same function as the original, except now it
 * validates wether the recipient `sagaFn` is able to handle a given action type and **`INSTEAD
 * OF CONSIDERING THAT sagaFn EXPECTS THE ACTION AS THE LAST PARAMETER, IT IS NOW THE FIRST.
 * HAVING EXTRA PARAMETERS IS VERY RARE, THOUGH.`** For 99% of the cases, it will behave exactly the
 * same as the original redux-saga effect.
 */
function makeCustomTaker<TT extends (...args: any) => any>(originalTaker: TT) {
  /**
   * The reference type here is `T`, the `sagaFn`. We analyze the function type
   * and get the type of its first parameter (the action type[s] that `sagaFn` expects).
   * Now, we impose that the `pattern` argument has to be a value that is equal to the `type`
   * property of the action that the function expects.
   * It can also be a list of types if `sagaFn` accepts more than one action.
   */
  return <T extends (...args: any) => any>(
    pattern: Parameters<T>[0]['type'] | (Parameters<T>[0]['type'])[],
    sagaFn: T,
    ...extraWorkerParams: Tail<Parameters<T>>
  ) => originalTaker(
    pattern,
    (...argsWithAction: any) => sagaFn(
      argsWithAction[argsWithAction.length - 1],
      ...extraWorkerParams,
    ),
  );
}

export const takeEvery = makeCustomTaker(_takeEvery);
export const takeLeading = makeCustomTaker(_takeLeading);
export const takeLatest = makeCustomTaker(_takeLatest);

/**
 *
 * @param _actions the action generators for the actions `fn` wants to handle
 * @param fn the generator function itself
 *
 * ## Expect the action as the fisrt parameter!
 * If you need extra params, just declare them afterwards. The custom `take...` fns will take
 * care of matching that too.
 *
 * ## How it works
 * `T` is an object with the action generators
 *
 * `TPFn` is the type of all the params of the saga, excluding the actions.
 * Usually TPFn is empty because we only care about the action in most cases.
 *
 * `TFn` is the type of the generator function itself. This function needs to fit in the shape
 * `(action, ...watheverOtherArgs) => whatever return type too / usually a generator`.
 */
export function makeSaga<
  T extends { [key: string]: any },
  TPFn extends any[],
  TFn extends (
    /**
     * Here we're covering the case when the actions object is empty. If that's
     * the case, then action should be considered `any`. Otherwhise, whatever
     * matches the object.
     */
    action: TA<T>[keyof TA<T>] extends never ? any : TA<T>[keyof TA<T>],
    ...otherParams: TPFn
  ) => unknown,
>(_actions: T, fn: TFn) {
  /**
   * The `unknown` is needed because we are overwriting the inferred Generator type.
   * It's specially useful for tests, since it prevents us from having to do
   * `gen.next(someValue as any)` every time.
   */
  return fn as unknown as (...params: Parameters<TFn>) => Generator<any, any, any>;
}

/**
 *
 * @param _actions an object containing, but not limited to,
 * all the action generators to the functions we want to process. It's recommended to
 * `import * as actions from './actions'` and then pass `actions` here just so you
 * don't have to be repeating the action generator names
 *
 * @param getInitialState
 * @param pureFn the reducer fn itself, except the `state` param is garanteed to never
 * be `null` or `undefined`. getInitialState is called automatically for you when needed.
 */
export function makeReducer<
  TActions extends { [key: string]: any },
  TState,
  T = {
    /**
     * since we are doing `import * as actions`,
     * we have to ignore anything inside the actions object that is not an action generator
     */
    [K in FilteredKeys<TActions, (...args: any) => { type: string }>]: ReturnTypeIfFnOrNothingOtherwise<TActions[K]>
  }
>(
  _actions: TActions,
  getInitialState: () => TState,
  pureFn: (state: TState, action: T[keyof T]) => TState,
) {
  return (state = getInitialState(), action: T[keyof T]) => pureFn(state, action);
}

/**
 * @param type the action type constant
 * @param fn `(optional)` the action generator itself, except you don't have to return the action type,
 * it's automatically added for you. If your action doesn't have a payload, then `fn` can be omitted.
 *
 * `T` is not simply `string` because we want specific constant strings
 * `TFn` is the action generator itself
 */
export const makeAction = <T extends string, TFn extends (...args: any[]) => any>(
  type: T,
  fn = (() => ({})) as TFn,
) => (...args: Parameters<TFn>): Omit<ReturnType<TFn>, 'type'> & { type: T } =>
  ({
    ...fn(...args),
    type,
  });
