import { prefix } from 'redux-utils';
import { PartialNotification, NotificationQuery, StyleProps } from './models';

const action = prefix('admin-notifications');

export const SEND_LOWP_NOTIFICATION = action('SEND_LOWP_NOTIFICATION');
export const SEND_MEDIUMP_NOTIFICATION = action('SEND_MEDIUMP_NOTIFICATION');
export const SEND_HIGHP_NOTIFICATION = action('SEND_HIGHP_NOTIFICATION');
export const SEND_CUSTOM_NOTIFICATION = action('SEND_CUSTOM_NOTIFICATION');
export const REMOVE_NOTIFICATION = action('REMOVE_NOTIFICATION');
export const CLEAR_NOTIFICATIONS = action('CLEAR_NOTIFICATIONS');

export type Payload = (
  NotificationAction |
  CustomNotificationAction |
  RemoveNotificationAction
)['payload'];

interface NotificationAction {
  payload: MessagePayload;
  type: string;
}

interface CustomNotificationAction {
  payload: PartialNotification;
  type: string;
}

interface RemoveNotificationAction {
  payload: NotificationQuery;
  type: string;
}

export interface MessagePayload {
  action?: (close: () => void, index: number) => void;
  buttons?: Partial<StyleProps>[];
  notificationText?: string;
  notificationTextKey?: string;
}

type SendNotificationFn = (message: MessagePayload) => NotificationAction;

type SendCustomNotificationFn = (options: PartialNotification) => CustomNotificationAction;

type RemoveNotificationFn = (query: NotificationQuery) => RemoveNotificationAction;

export const sendLowPriorityNotification: SendNotificationFn = (
  message,
) => ({
  payload: message,
  type: SEND_LOWP_NOTIFICATION,
});

export const sendMediumPriorityNotification: SendNotificationFn = (
  message,
) => ({
  payload: message,
  type: SEND_MEDIUMP_NOTIFICATION,
});

export const sendHighPriorityNotification: SendNotificationFn = (
  message,
) => ({
  payload: message,
  type: SEND_HIGHP_NOTIFICATION,
});

export const sendCustomNotification: SendCustomNotificationFn = (
  options,
) => ({
  payload: options,
  type: SEND_CUSTOM_NOTIFICATION,
});

export const removeNotification: RemoveNotificationFn = (query) => ({
  payload: query,
  type: REMOVE_NOTIFICATION,
});

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
});
