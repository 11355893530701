import { createSelector } from 'reselect';
import IState from 'services/state';

import { MOBILE_MAX_WIDTH_PX } from 'style/constants';
import { IDeviceState } from './reducer';

const getDeviceState = createSelector(
  ({ device }: IState): IDeviceState => device,
  device => device,
);
export const getWidth = (state: IState) => getDeviceState(state).width;
export const getHeight = (state: IState) => getDeviceState(state).height;

// insights things
export const getDeviceCreated = ({ device }: IState) => device.created;
export const getDeviceNavigatorPlatform = ({ device }: IState) => device.navigatorPlatform;
export const getDeviceNewDevice = ({ device }: IState) => device.newDevice;
export const getDevicePermanentId = ({ device }: IState) => device.permanentId;
export const getDeviceScreenHeight = ({ device }: IState) => device.screenHeight;
export const getDeviceScreenWidth = ({ device }: IState) => device.screenWidth;
export const getDeviceTabId = ({ device }: IState) => device.tabId;
export const getDeviceUserAgent = ({ device }: IState) => device.userAgent;
export const getOrientation = ({ device }: IState) => device.orientation;
export const getOrientationDegrees = ({ device }: IState) => device.orientationDegrees;
export const getDeviceHeight = ({ device }: IState): number => device.height;

export const isLandscape = createSelector(
  [getWidth, getHeight, getOrientation],
  (width, height, orientation) => width >= height && orientation === 'landscape',
);

export const isPortrait = createSelector(
  [isLandscape],
  (landscape) => !landscape,
);

export const isMobileLayout = createSelector(
  [getWidth],
  (width) => width <= MOBILE_MAX_WIDTH_PX,
);

export const isDesktopLayout = createSelector(
  [isMobileLayout],
  (mobile) => !mobile,
);
