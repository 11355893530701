export type EmptyType = 'EMPTY';
export const EMPTY = 'EMPTY';

export interface IFooter {
  actions: Action[];
  background: string;
  footerLinks: FooterLink[] | EmptyType;
  hashtag: string;
  links: Link[];
  newsletter: string;
  sharing: Share[];
  socials: Social[] | EmptyType;
  sponsors: Sponsor[] | EmptyType;
  state: string;
  tweetMessage: string;
}

export interface Action {
  content: {
    html: string;
    image: string;
    kind: string;
    url: string;
  };
  display: {
    icon: string;
    image: string;
    kind: string;
    text: string;
  };
  icon: string;
  kind: string;
  name: string;
  url: string;
}

export interface FooterLink {
  arrayId: string;
  image?: string;
  kind: string;
  name: string;
  text: string;
  url: string;
}

export interface Link {
  icon: string;
  image: string;
  kind: string;
  name: string;
  text: string;
  url: string;
}

export interface Share {
  description: string;
  hashtag: string;
  image: string;
  kind: string;
  name: string;
  text: string;
  title: string;
  tweetText: string;
}

export interface Social {
  arrayId: string;
  icon: string;
  name?: string;
  title: string;
  url: string;
}

export interface Sponsor {
  arrayId?: string;
  image: string;
  link: string;
  name?: string;
}
