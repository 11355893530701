import { takeLatest } from 'redux-saga/effects';
import { ADD_CART_ITEM, CLEAR_CART, COMPLETE_SHOPIFY_PAYMENT_PROCESS, DECREASE_CART_ITEM, INCREASE_CART_ITEM, REMOVE_CART_ITEM, UPDATE_SHOPIFY_SHIPPING_LINE, SUBMIT_SHOPIFY_CHECKOUT, TRIGGER_SHOPIFY_CHECKOUT, UPDATE_CART_ITEM_VARIANT, OPEN_ECOMMERCE_CHANNEL_CHECKOUT_VIEW } from '../actions';
import { addEcommerceCartItemSaga, clearCartSaga, decreaseEcommerceCartItemSaga, increaseEcommerceCartItemSaga, removeEcommerceCartItemSaga, updateEcommerceCartItemVariantSaga } from './cart';
import { completeShopifyPaymentProcessSaga, submitShopifyCheckoutSaga, triggerShopifyCheckoutSaga, updateShopifyShippingLinesSaga } from './shopify';
import { openEcommerceChannelCheckoutSaga } from './ecommerce';

function* ecommerceSaga() {
  yield takeLatest(ADD_CART_ITEM, addEcommerceCartItemSaga);
  yield takeLatest(REMOVE_CART_ITEM, removeEcommerceCartItemSaga);
  yield takeLatest(INCREASE_CART_ITEM, increaseEcommerceCartItemSaga);
  yield takeLatest(DECREASE_CART_ITEM, decreaseEcommerceCartItemSaga);
  yield takeLatest(CLEAR_CART, clearCartSaga);
  yield takeLatest(TRIGGER_SHOPIFY_CHECKOUT, triggerShopifyCheckoutSaga);
  yield takeLatest(SUBMIT_SHOPIFY_CHECKOUT, submitShopifyCheckoutSaga);
  yield takeLatest(UPDATE_SHOPIFY_SHIPPING_LINE, updateShopifyShippingLinesSaga);
  yield takeLatest(COMPLETE_SHOPIFY_PAYMENT_PROCESS, completeShopifyPaymentProcessSaga);
  yield takeLatest(UPDATE_CART_ITEM_VARIANT, updateEcommerceCartItemVariantSaga);
  yield takeLatest(OPEN_ECOMMERCE_CHANNEL_CHECKOUT_VIEW, openEcommerceChannelCheckoutSaga);
}

export default ecommerceSaga;
