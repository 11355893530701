// tslint:disable:no-console
import { streamMapper } from './stream';
import { streamProviderAccountMapper } from './streamProviderAccount';

const mapError = {};

export const dataMapper = async (
  collection: string,
  rawData: any,
  authToken?: string | null,
) => {
  try {
    switch (collection) {
      case 'stream':
        if (!authToken)
          throw new Error('token is required by streamMapper()');
        return await streamMapper(rawData, authToken);
      case 'streamprovideraccount':
        if (!authToken)
          throw new Error('token is required by streamProviderAccountMapper()');
        return await streamProviderAccountMapper(rawData, authToken);
      default:
        return rawData;
    }
  } catch (err) {
    console.error(
      'Error found when mapping object:',
      {
        collection,
        rawData,
        authToken,
        error: err,
      },
    );
    return mapError;
  }
};

export const isMapError = (value: any) => value === mapError;
