import { makeEnum, MakeEnumType } from 'utils/makeEnum';
import IModel from './IModel';
import { SITE_ID } from 'config';

export const AdType = makeEnum('vast', 'display');
export type AdType = MakeEnumType<typeof AdType>;

export type VastAdConfig = {
  type: typeof AdType.vast;
  url: string;
};

export type DisplayAdConfig = {
  height: number;
  type: typeof AdType.display;
  width: number;
};

export interface IAdsConfig extends IModel {
  config: VastAdConfig | DisplayAdConfig;
  enabled: boolean;
  pageId: string | null;
  siteId: string;
  videoId: string | null;
}

export const DEFAULT_SITE_WIDE_ADS_CONFIG: IAdsConfig = {
  config: {
    type: AdType.vast,
    url: '',
  },
  enabled: false,
  pageId: null,
  videoId: null,
  siteId: SITE_ID,
};
