export interface Dict<T> {
  [k: string]: T
}
// Array.prototype.map, but for Dict
export function mapDict<T, S>(
  inputDict: Dict<T>,
  mapFunction: (original: T, key: string) => S,
): Dict<S> {
  const outDict: Dict<S> = {};
  for (const k of Object.keys(inputDict)) {
    const thisVal = inputDict[k];
    outDict[k] = mapFunction(thisVal, k);
  }
  return outDict;
}

// Array.prototype.filter, but for Dict
export function filterDict<T>(
  inputDict: Dict<T>,
  filterFunction: (value: T, key: string) => boolean,
): Dict<T> {
  const outDict: Dict<T> = {};
  for (const k of Object.keys(inputDict)) {
    const thisVal = inputDict[k];
    if (filterFunction(thisVal, k)) outDict[k] = thisVal;
  }
  return outDict;
}

// Array.prototype.reduce, but for Dict
export function reduceDict<T, S>(
  inputDict: Dict<T>,
  reducerFunction: (
    currentVal: S,
    dictItem: T,
    key: string,
  ) => S,
  initialValue: S,
): S {
  let value = initialValue;
  for (const k of Object.keys(inputDict)) {
    const thisVal = inputDict[k];
    value = reducerFunction(value, thisVal, k);
  }
  return value;
}
