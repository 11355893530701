import axios from 'axios';
import { SITE_ID, ADS_SERVICE_BASE_URL } from 'config';
import { IAdsConfig } from 'models/IAdsConfig';
import maestroApiRequest from 'services/maestro-api-client';

export const getAdsConfig = async ({
  videoId,
  pageId,
  captureCanceler,
}: {
  captureCanceler?: (cancelFn: () => unknown) => unknown;
  pageId: string | null;
  videoId: string | null;
}): Promise<IAdsConfig | null> => {
  const response = await maestroApiRequest({
    primaryToken: '',
    siteId: SITE_ID,
  }).get<IAdsConfig | null>(
    `${ADS_SERVICE_BASE_URL}/adsConfig`,
    {
      params: { videoId, pageId },
      cancelToken: new axios.CancelToken(
        (canceler) => captureCanceler?.(canceler),
      ),
    },
  );

  return response.data;
};

export const upsertAdsConfig = async ({
  token,
  adsConfig,
  captureCanceler,
}: {
  adsConfig: IAdsConfig;
  captureCanceler?: (cancelFn: () => unknown) => unknown,
  token: string;
}): Promise<void> => {
  try {
    await maestroApiRequest({
      primaryToken: token,
      siteId: SITE_ID,
    }).post(`${ADS_SERVICE_BASE_URL}/adsConfig`, adsConfig, {
      cancelToken: new axios.CancelToken(
        (canceler) => captureCanceler?.(canceler),
      ),
    });
  } catch (error) {
    if (error instanceof axios.Cancel) {
      return;
    }

    throw error;
  }
};
