import { ICheckoutStorefront, IPaymentStorefront } from 'services/shopify/models';
import { EcommerceView, ICartItem, IEcommerceCart } from './models';
import { PaymentMethod } from '@stripe/stripe-js';
import { IProductComponentProps } from 'components/Ecommerce/ProductDetail';

export const LOAD_CART = 'ecommerce/LOAD_CART';
export const CLEAR_CART = 'ecommerce/CLEAR_CART';
export const ADD_CART_ITEM = 'ecommerce/ADD_CART_ITEM';
export const UPDATE_CART_ITEM_VARIANT = 'ecommerce/UPDATE_CART_ITEM_VARIANT';
export const REMOVE_CART_ITEM = 'ecommerce/REMOVE_CART_ITEM';
export const INCREASE_CART_ITEM = 'ecommerce/INCREASE_CART_ITEM';
export const DECREASE_CART_ITEM = 'ecommerce/DECREASE_CART_ITEM';
export const SET_SHOPIFY_CHECKOUT = 'ecommerce/SET_SHOPIFY_CHECKOUT';
export const SET_PRODUCT_DETAIL = 'ecommerce/SET_PRODUCT_DETAIL';
export const SET_SHOPIFY_ERROR_MESSAGE = 'ecommerce/SET_SHOPIFY_ERROR_MESSAGE';
export const TRIGGER_SHOPIFY_CHECKOUT = 'ecommerce/TRIGGER_SHOPIFY_CHECKOUT';
export const SUBMIT_SHOPIFY_CHECKOUT = 'ecommerce/SUBMIT_SHOPIFY_CHECKOUT';
export const SHOPIFY_PAYMENT_SUCCEEDED = 'ecommerce/SHOPIFY_PAYMENT_SUCCEEDED';
export const COMPLETE_SHOPIFY_PAYMENT_PROCESS = 'ecommerce/COMPLETE_SHOPIFY_PAYMENT_PROCESS';
export const UPDATE_SHOPIFY_SHIPPING_LINE = 'ecommerce/UPDATE_SHOPIFY_SHIPPING_LINE';
export const PUSH_ECOMMERCE_VIEW = 'ecommerce/PUSH_ECOMMERCE_VIEW';
export const DISMISS_ECOMMERCE_VIEW = 'ecommerce/DISMISS_ECOMMERCE_VIEW';
export const RESET_ECOMMERCE_VIEWS = 'ecommerce/RESET_ECOMMERCE_VIEWS';
export const TOGGLE_FLOAT_MENU = 'ecommerce/TOGGLE_FLOAT_MENU';
export const REPLACE_ECOMMERCE_VIEW = 'ecommerce/REPLACE_ECOMMERCE_VIEW';
export const OPEN_ECOMMERCE_CHANNEL_CHECKOUT_VIEW = 'ecommerce/OPEN_ECOMMERCE_CHANNEL_CHECKOUT_VIEW';
export const SET_SHIPPING_METHOD = 'ecommerce/SET_SHIPPING_METHOD';
export const SET_LOGGED_OUT_USER_EMAIL = 'ecommerce/SET_LOGGED_OUT_USER_EMAIL';

export interface IOpenEcommerceChannelCheckoutView {
  type: typeof OPEN_ECOMMERCE_CHANNEL_CHECKOUT_VIEW;
}

export interface IDismissEcommerceView {
  type: typeof DISMISS_ECOMMERCE_VIEW;
}

export interface IResetEcommerceViews {
  type: typeof RESET_ECOMMERCE_VIEWS;
}

export interface IPushEcommerceView {
  payload: EcommerceView;
  type: typeof PUSH_ECOMMERCE_VIEW;
}

export interface IReplaceEcommerceView {
  payload: EcommerceView[];
  type: typeof REPLACE_ECOMMERCE_VIEW;
}

export interface ICompleteShopifyPaymentProcess {
  type: typeof COMPLETE_SHOPIFY_PAYMENT_PROCESS;
}

export interface IUpdateShopifyShippingLine {
  payload: string;
  type: typeof UPDATE_SHOPIFY_SHIPPING_LINE;
}

export interface IShopifyPaymentSucceeded {
  payload: IPaymentStorefront;
  type: typeof SHOPIFY_PAYMENT_SUCCEEDED;
}

export interface ISubmitShopifyCheckout {
  payload: ISubmitShopifyCheckoutPayload;
  type: typeof SUBMIT_SHOPIFY_CHECKOUT;
}
export interface ISetShopifyErrorMessage {
  payload: string;
  type: typeof SET_SHOPIFY_ERROR_MESSAGE;
}

export interface ITriggerShopifyCheckout {
  payload: {
    email: string;
  };
  type: typeof TRIGGER_SHOPIFY_CHECKOUT;
}

export interface ISetShopifyCheckout {
  payload: ICheckoutStorefront;
  type: typeof SET_SHOPIFY_CHECKOUT;
}

export interface ISetProductDetail {
  payload: IProductComponentProps;
  type: typeof SET_PRODUCT_DETAIL;
}

export interface ILoadCart {
  payload: IEcommerceCart;
  type: typeof LOAD_CART;
}

export interface IClearCartPayload {
  clearDbCart?: boolean,
}

export interface IAddCartItem {
  payload: ICartItem;
  type: typeof ADD_CART_ITEM;
}

interface IUpdateCartItemVariantPayload {
  productId: string;
  updatedVariantId: string;
  variantId: string;
}

export interface IUpdateCartItemVariant {
  payload: IUpdateCartItemVariantPayload;
  type: typeof UPDATE_CART_ITEM_VARIANT;
}

export interface IRemoveCartItem {
  payload: { productId: string; variantId: string; };
  type: typeof REMOVE_CART_ITEM;
}

export interface IIncreaseCartItem {
  payload: { productId: string; variantId: string; };
  type: typeof INCREASE_CART_ITEM;
}

export interface IDecreaseCartItem {
  payload: { productId: string; variantId: string; };
  type: typeof DECREASE_CART_ITEM;
}

export interface IClearCart {
  payload: IClearCartPayload;
  type: typeof CLEAR_CART;
}

export interface ISetShippingMethod {
  payload: string;
  type: typeof SET_SHIPPING_METHOD;
}

export interface ISetLoggedOutUserEmail {
  payload: string;
  type: typeof SET_LOGGED_OUT_USER_EMAIL;
}

export type IEcommerceActions = (
  IAddCartItem |
  IUpdateCartItemVariant |
  IRemoveCartItem |
  IIncreaseCartItem |
  IDecreaseCartItem |
  ILoadCart |
  IClearCart |
  ISetShopifyCheckout |
  ISetProductDetail |
  ITriggerShopifyCheckout |
  ISetShopifyErrorMessage |
  ISubmitShopifyCheckout |
  IShopifyPaymentSucceeded |
  IUpdateShopifyShippingLine |
  ICompleteShopifyPaymentProcess |
  IPushEcommerceView |
  IDismissEcommerceView |
  IResetEcommerceViews |
  IReplaceEcommerceView |
  IOpenEcommerceChannelCheckoutView |
  ISetShippingMethod |
  ISetLoggedOutUserEmail
);

export interface ISubmitShopifyCheckoutPayload {
  billingAddressSameAsShipping: boolean;
  email: string;
  paymentMethod: PaymentMethod;
  /**
   * Stripe token id. Used when anonymous user is checking out.
   */
  stripeTokenId: string | null;
}

export const openEcommerceChannelCheckoutView = (): IOpenEcommerceChannelCheckoutView => ({
  type: OPEN_ECOMMERCE_CHANNEL_CHECKOUT_VIEW,
});

export const dismissEcommerceView = (): IDismissEcommerceView => ({
  type: DISMISS_ECOMMERCE_VIEW,
});

export const replaceEcommerceViews = (payload: EcommerceView[]): IReplaceEcommerceView => ({
  payload,
  type: REPLACE_ECOMMERCE_VIEW,
});

export const resetEcommerceViews = (): IResetEcommerceViews => ({
  type: RESET_ECOMMERCE_VIEWS,
});

export const pushEcommerceView = (payload: EcommerceView): IPushEcommerceView => ({
  payload,
  type: PUSH_ECOMMERCE_VIEW,
});

export const completeShopifyPaymentProcess = (): ICompleteShopifyPaymentProcess => ({
  type: COMPLETE_SHOPIFY_PAYMENT_PROCESS,
});

export const updateShopifyShippingLine = (payload: string): IUpdateShopifyShippingLine => ({
  payload,
  type: UPDATE_SHOPIFY_SHIPPING_LINE,
});

export const shopifyPaymentSucceeded = (payload: IPaymentStorefront): IShopifyPaymentSucceeded => ({
  payload,
  type: SHOPIFY_PAYMENT_SUCCEEDED,
});

export const submitShopifyCheckout = (payload: ISubmitShopifyCheckoutPayload): ISubmitShopifyCheckout => ({
  payload,
  type: SUBMIT_SHOPIFY_CHECKOUT,
});

export const setShopifyErrorMessage = (payload: string): ISetShopifyErrorMessage => ({
  payload,
  type: SET_SHOPIFY_ERROR_MESSAGE,
});

export const triggerShopifyCheckout = (payload: { email: string; }): ITriggerShopifyCheckout => ({
  payload,
  type: TRIGGER_SHOPIFY_CHECKOUT,
});

export const setShopifyCheckout = (payload: ICheckoutStorefront): ISetShopifyCheckout => ({
  payload,
  type: SET_SHOPIFY_CHECKOUT,
});

export const setProductDetail = (payload: IProductComponentProps): ISetProductDetail => ({
  payload,
  type: SET_PRODUCT_DETAIL,
});

export const clearCart = (payload: IClearCartPayload): IClearCart => ({
  payload,
  type: CLEAR_CART,
});

export const loadCart = (payload: IEcommerceCart): ILoadCart => ({
  payload,
  type: LOAD_CART,
});

export const addCartItem = (payload: ICartItem): IAddCartItem => ({
  payload,
  type: ADD_CART_ITEM,
});

export const updateCartItemVariant = (payload: IUpdateCartItemVariantPayload): IUpdateCartItemVariant => ({
  payload,
  type: UPDATE_CART_ITEM_VARIANT,
});

export const removeCartItem = (payload: { productId: string; variantId: string }): IRemoveCartItem => ({
  payload,
  type: REMOVE_CART_ITEM,
});

export const increaseCartItem = (payload: { productId: string; variantId: string; }): IIncreaseCartItem => ({
  payload,
  type: INCREASE_CART_ITEM,
});

export const decreaseCartItem = (payload: { productId: string; variantId: string; }): IDecreaseCartItem => ({
  payload,
  type: DECREASE_CART_ITEM,
});

export const setShippingMethod = (payload: string): ISetShippingMethod => ({
  payload,
  type: SET_SHIPPING_METHOD,
});

export const setLoggedOutUserEmail = (payload: string): ISetLoggedOutUserEmail => ({
  payload,
  type: SET_LOGGED_OUT_USER_EMAIL,
});
