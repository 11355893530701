import { put, select } from 'redux-saga/effects';
import { getEcommerceView } from '../selectors';
import { SHOPIFY_BLOCK_PANEL_ID } from 'components/objects/PanelV2/constants';
import { pushEcommerceView, replaceEcommerceViews } from '..';
import { openPanel } from 'services/custom-panels';
import { getShopifyBlockPanel } from 'services/user-layout/selectors';
import { setActivePanel } from 'services/app';

export function* openEcommerceChannelCheckoutSaga() {
  const state = yield select();
  const ecommerceView = getEcommerceView(state);
  const shopifyBlockPanel = getShopifyBlockPanel(state);
  if (ecommerceView === 'checkoutOverlay') {
    return;
  }

  if (shopifyBlockPanel) {
    yield put(setActivePanel(shopifyBlockPanel._id));
    yield put(replaceEcommerceViews(['cart']));
  } else {
    yield put(pushEcommerceView('checkoutOverlay'));
    yield put(openPanel({ doc: { data: { kind: SHOPIFY_BLOCK_PANEL_ID } } }));
  }
}
