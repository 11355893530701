import { call, takeEvery, put } from 'redux-saga/effects';
import { saveAddress, fetchAccountAddresses } from './api';
import { LOG_OUT } from 'services/auth/actions';
import { SAVE_ADDRESS,
  FETCH_ACCOUNT_ADDRESSES,
  ISaveAddressAction,
  setAccountAddresses,
  setAccountAddressLoading,
  IFetchAccountAddressesAction } from './actions';
import { resetAccountAddressReducer } from './actions';

export const resetAccountAddressSaga = function* () {
  yield put(resetAccountAddressReducer());
};

export const saveAddressSaga = function* ({ payload }: ISaveAddressAction) {
  const { primaryToken, address, name } = payload;
  try {
    yield call(saveAddress, { primaryToken, address, name });
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e, 'error creating address in saga');
  }
};

export const fetchAccountAddressesSaga = function* ({ payload }: IFetchAccountAddressesAction ) {
  try {
    yield put(setAccountAddressLoading(true));
    const addresses = yield call(fetchAccountAddresses, payload);
    yield put(setAccountAddresses(addresses));
    yield put(setAccountAddressLoading(false));
  } catch (e) {
    yield put(setAccountAddressLoading(false));
    // tslint:disable-next-line: no-console
    console.error(e, 'error fetching addresses in saga');
  }
};

export default function* accountAddressSaga () {
  yield takeEvery(LOG_OUT, resetAccountAddressSaga);
  yield takeEvery(SAVE_ADDRESS, saveAddressSaga);
  yield takeEvery(FETCH_ACCOUNT_ADDRESSES, fetchAccountAddressesSaga);
}
