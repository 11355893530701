import IModel from 'models/IModel';
import { Card, StripeCardNumberElement, Stripe, PaymentMethod, Token } from '@stripe/stripe-js';
import { IBillingData } from 'services/shopify/models';
import ISubscription, { IPrice, SubscriptionType } from 'models/ISubscription';
import Plan from 'models/IPlan';

// Stripe only allow render one card element at a time (security reason)
// this enum in combine with StripeCreditCardSafeView will help to manage this
export enum RegionRenderingStripeCreditCardEnum {
  PAYMENT_METHODS_V1 = 'paymentMethodsV1',
  PAYMENT_METHODS_V2 = 'paymentMethodsV2',
  ENTITLEMENT_PURCHASE = 'entitlementPurchase',
  PURCHASE_PLAN_MODAL = 'purchasePlanModal',
  UPGRADE_PLAN_MODAL = 'upgradePlanModal',
  SHOPIFY_PANEL = 'shopifyPanel',
  ECOMMERCE_CHECKOUT = 'ecommerceCheckout',
  SELF_SERVICE_SIGNUP = 'selfServiceSignup',
}

export enum Frequency {
  Monthly = 'monthly',
  Weekly = 'weekly',
}

export enum OwnerType {
  Site = 'site',
  Account = 'account',
}

export enum ScheduleType {
  Immediate = 'immediate',
  EndOfBilling = 'endOfBilling',
}

export interface IBillingInfo {
  address?: string;
  city?: string;
  complete: boolean;
  countryCode: string;
  error: string | null;
  markDefaultPaymentMethod: boolean;
  name: string;
  phone?: string;
  postalCode: string;
  stateCode?: string;
}

export const isBillingInfo = (params: any): boolean => {
  if (
    'countryCode' in params &&
    'postalCode' in params &&
    params.countryCode !== '' &&
    params.postalCode !== '' &&
    params.stateCode !== ''
  ) {
    return true;
  }
  return false;
};

export interface IBillingInfoError {
  errorName: IBillingInfoErrorInput;
}

export interface IBillingInfoErrorInput {
  errorMsg: string;
  hasError: boolean;
}

export interface IAmountBreakdown {
  entitlementPrice: IPrice;
  subscriptionType?: SubscriptionType | null;
  taxRate: number;
}

export type ISetEntitlementDataPayload = Omit<IAmountBreakdown, 'taxRate'>;

export interface ITaxRate {
  taxRate: number | null;
}

export interface IAccountPayload {
  accountId: string;
  provider: string;
}

export interface IBillingState {
  addPaymentSuccess: boolean;
  amountBreakdown: IAmountBreakdown;
  billingInfo: IBillingInfo;
  billingInfoError: IBillingInfoError;
  contract: IContract | null;
  defaultPayment: string | null;
  editPaymentMethodPending: boolean;
  editPaymentMethodSuccess: boolean;
  entitlementLoadingId: string | null;
  idempotencyKey: string;
  nativeCurrencyCode: string | null;
  paymentPending: boolean;
  payments: PaymentMethod[];
  paymentsLoading: boolean;
  plan: Plan | null;
  purchasePlanErrorMessage: string | null;
  purchasePlanLoading: boolean;
  purchasePlanSuccess: boolean;
  regionRenderingStripeCreditCard: RegionRenderingStripeCreditCardEnum[];
  removePaymentSuccess: boolean;
  setPrimaryPaymentSuccess: boolean;
  taxLoaded: boolean;
  taxLoading: boolean;
  upgradePlanErrorMessage: string | null;
  upgradePlanLoading: boolean;
  upgradePlanSuccess: boolean;
  usingPaymentRequestButtonForEntitlementPurchase: boolean;
  viewerServiceChargeRate: number | null;
  wallet: IWallet | null;
  watchedEntitlementIds: string[];
}
export interface IChargeResult {
  errorMessage?: string;
  paymentMethod?: PaymentMethod;
  retry?: boolean;
  source?: Card;
  sourceId?: string;
  success: boolean;
}

export enum PaymentStatusOptions {
  COMPLETE = 'complete',
  DECLINED = 'declined',
  PENDING = 'pending',
}

export interface IEntitlementAccess {
  _id: string;
  accountId: string;
  errorMessage?: string;
  paymentStatus: PaymentStatusOptions;
  siteId: string;
  sku: string;
  subscriptionId: string;
  valid: boolean;
}

export interface IPurchaseRequest {
  billingInfo: IBillingInfo;
  bundleId?: string;
  idempotencyKey: string;
  paymentMethod: PaymentMethod;
  presentmentCurrency: string;
  referer?: IPurchaseReferer;
  savePaymentMethod: boolean;
  sku: string;
  token: Token | null;
}

export interface IPurchaseReferer {
  origin: string;
  pageSlug?: string;
}

export interface IPayment {
  element: StripeCardNumberElement;
  stripe: Stripe;
}

export interface ISubmitPaymentPayload {
  bundleId?: string;
  element?: StripeCardNumberElement;
  paymentMethod?: PaymentMethod;
  presentmentCurrency: IPrice['currency'];
  savePaymentMethod: boolean;
  sku: ISubscription['sku'];
  stripe?: Stripe;
  usingPaymentRequestButtonForm?: boolean;
}

export interface ISubmitPaymentMethodPayload extends IPayment {
  billingData?: IBillingData;
  makePrimaryPaymentMethod?: boolean;
}

export interface IPurchasePlanPayload extends IPayment {
  paymentMethod?: PaymentMethod;
}

export interface IUpgradePlanPayload extends IPayment {
  paymentMethod?: PaymentMethod;
  planId: string;
}

export interface ISubmitPaymentMethodAPI {
  markAsDefault: boolean;
  paymentMethod: PaymentMethod;
  shopifyBillingData: ISubmitPaymentMethodPayload['billingData'];
  token: Token | null;
}

export interface IWallet {
  _id: string;
  balance: number;
  defaultCardSource?: string | null;
  defaultPaymentMethod?: string | null;
  ownerId: string;
  ownerType: OwnerType;
  paymentMethodBySource: { [key: string]: string };
  siteId: string;
  stripeAccountId?: string;
  stripeCustomerId?: string;
}

export interface IWalletData {
  cards: PaymentMethod[],
  defaultCardId: string,
  wallet: IWallet,
}

export interface ICancelSubscription {
  accountEntitlementId: string;
  refund: boolean;
  scheduled: ScheduleType;
  subscriptionId: string;
}

export interface IEditPaymentMethodPayload {
  countryCode: string;
  expirationMonth: number;
  expirationYear: number;
  name: string;
  paymentMethodId: string;
  postalCode: string;
  state?: string;
}

export interface IEditPaymentMethodParams extends IEditPaymentMethodPayload {
  siteId: string;
  walletId: string;
}

export enum BillingFrequencyType {
  monthly = 'monthly',
  annual = 'annual',
}

export enum ContractStatus {
  pending = 'pending',
  active = 'active',
  cancelled = 'cancelled',
  suspended = 'suspended',
  trial = 'trial',
  freeTrialExpired = 'free-trial-expired',
  pastDue = 'past-due',
}

export interface ITerm {
  autoRenews: boolean,
  effectiveDate: number,
  nextBillingDate: number,
}

export interface IPromotion extends IModel {
  code: string,
  description: string,
  planId: string,
  stripeCouponId: string,
}

export interface IContract extends IModel {
  billingFrequency: BillingFrequencyType,
  customerHubspotId?: string,
  paymentMethod?: string,
  planId: string,
  promotion?: IPromotion[],
  siteId: string,
  status: ContractStatus,
  stripeAccountId?: string,
  stripeCustomerId?: string,
  term: ITerm,
}


export interface IDeleteSourcePayload {
  cardId: string;
  customer: string;
}

export interface IBasePurchaseSummary {
  amountBreakdown: {
    serviceCharge: number;
    serviceChargeRate: number;
    taxAmount: number;
    taxRate: number;
    total: number;
  }
  currency: string;
  description?: string;
  name: string;
  paymentMethod?: {
    cardLastDigits: string;
    type: string;
  },
  price: number;
  receiptNumber: string;
}

interface ITickerOrSubPurchaseSummary extends IBasePurchaseSummary {
  image?: never;
  nextBillDate?: null | number;
  type: SubscriptionType;
}

export interface IBundlePurchaseSummary extends IBasePurchaseSummary {
  bundleId: string;
  image?: string;
  nextBillDate?: never;
  type: 'bundle';
}

export type IPurchaseSummary = ITickerOrSubPurchaseSummary | IBundlePurchaseSummary;

export interface INativeCurrencyData {
  currencyCode: string;
}
