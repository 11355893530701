import invariant from 'invariant';
import shortid from 'shortid';
import { PageType } from 'models/IObject';
import {
  put,
  race,
  take,
  takeLatest,
  takeEvery,
  select,
  call,
  all,
} from 'redux-saga/effects';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import isNull from 'lodash/isNull';
import { createObjectId, hasOwn } from 'utils';
import { getRef } from 'firebase-db';
import { joinKeySegments } from 'firebase-utils';
import { GLOBAL_ACCOUNTS_LOG_IN_SUCCESS, LOG_IN_SUCCESS, LOG_OUT, setAccountTags } from 'services/auth/actions';
import {
  getPrimaryToken,
  getUserAccount,
  getUserRoles,
  isUserAdmin,
} from 'services/auth/selectors';
import {
  DISMISS_MODAL,
  dismissModal,
  showAdminErrorModal,
  showModal,
} from 'services/modals/actions';
import { ON_PAGE_CHANGE } from 'services/app/actions';
import {
  getPage,
  getSite,
  getSiteId,
  getPageId,
  getObject,
  getSavedSettingsDefaultThemeId,
  getCurrentPageId,
} from 'services/app/selectors';
import { logError } from 'services/error/actions';
import { ID_SIDEBAR } from 'services/renderer/ids';
import { camelify } from 'shared/string-utils';
import { replace } from 'services/app-router';
import { CROSS_CHANNEL_VALIDATION_MODAL } from 'shared/constants';
import { ModalKinds } from 'services/modals/types';
import { SCALE_ANNUAL_PLAN_ID } from 'components/modals/UpgradePlanModal/constants';
import { SITE_ID } from 'config';
import { trackEvent } from 'services/segment-analytics/actions';
import { getThemeById } from 'services/themes/api';
import {
  DARK_MODE_ACCENT_COLORS,
} from 'services/themes';
import { setChannelPassword } from 'services/gate/api';
import {
  setAdminMode,
  setPendingPageDoc,
  PUBLISH_PENDING_CHANGES,
  PUBLISH_PENDING_GATE_CHANGES,
  resetAllPendingAdminChanges,
  WRITE_TO_COLLECTION,
  writeLegacySuccess,
  EDIT_COLLECTION_ITEM,
  clearEditTarget,
  SAVE_SITE_SETTINGS,
  CONFIRM_CROSS_CHANNEL_VALIDATION_MODAL,
  setRegionRendererDraft,
  SAVE_QUEST,
  clearRegionRendererDraft,
  updateRefreshKey,
  SET_EDIT_TARGET,
  SET_ACCESS_CONTROL_FTUE,
  SET_PENDING_ADMIN_CHANGE,
  SET_REGION_RENDERER_DRAFT,
  OPEN_BILLING_TAB,
  navigateToAdminBarAction,
  SET_ACTIVE_ACTION,
  NAVIGATE_TO_ADMIN_BAR_ACTION,
  POP_ADMIN_BAR_SUB_MENU_KEY,
  PUSH_ADMIN_BAR_SUB_MENU_KEY,
  SET_HOME_PAGE,
  publishPendingChanges,
  PUBLISH_GATE_STATE,
  CREATE_CHANNEL_OR_PAGE,
  ARCHIVE_PAGE,
  setActiveAction,
  setAdminBarSubMenuQueue,
  clearPendingGateChange,
  adminBarNavigation,
  clearPendingPanelChange,
  PUBLISH_PENDING_PANEL_CHANGES,
  createPanelError,
  CREATE_PANEL,
  DELETE_PANEL,
  setActiveActionType,
  setPendingAdminChange,
  PUBLISH_PAGE_PENDING_CHANGES,
} from 'services/admin/actions';
import { getObjectSlug, SET_OBJECT, BEFORE_PAGE_CHANGE } from 'services/app';
import {
  ActionType,
  SCOPE_BLACKLIST_ROLE_MAP,
  SUB_MENU_ACTION_MAP,
} from 'components/admin-bridge/AdminBar/constants';
import mockPanelData from 'components/objects/PanelV2/PanelPreviewMockData';
import produce from 'immer';
import { isUndefined } from 'lodash';
import { CHAT_ID } from 'components/objects/PanelV2/constants';
import { getIsLegacyPlan, getIsUnlimitedPlan, getPlanFeatures } from 'services/billing';
import { MAX_AMOUNT_OF_CHANNELS, MAX_AMOUNT_OF_PAGES } from 'models/IPlan';
import { getAmountOfChannels } from 'services/wizard/api';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import { Feature } from 'services/feature-gate';
import { upsertPage } from 'services/page/api';
import { StreamSource } from 'services/livestream/constants';
import { updateNavigation } from 'services/navigationv2';
import {
  setDocument,
  validateCrossChannelObject,
  createQuest,
  updateQuest,
  putTag,
  archiveDocument,
} from './api';
import { ModeTypes, TargetTypes, settingsTypes, ActionKey } from './constants';
import {
  getPendingAdminChanges,
  getActiveAction,
  getCurrentSubMenuKey,
  getEditingPageDoc,
  getPendingPage,
  getPendingGateChange,
  getAdminBarSubMenuQueue,
  getPendingPagePanelsChanges,
  getPanelDrafts,
  isMaestroStreamMode,
  getEditTarget,
  getActiveActionType,
  getRegionRendererDrafts,
} from './selectors';
import { AdminActionEvents } from './models';
import publishSaga, { publishPendingPage } from './publishSaga';

const keys = (obj) => Object.keys(obj);

// eslint-disable-next-line arrow-body-style
export const shouldUpdateObject = (pathMap, unpublishedChanges) => keys(pathMap)
  .some((key) => Boolean(unpublishedChanges[key]?.pendingData));

export const CHANNEL_PATH_MAP = {
  [TargetTypes.COUNTDOWN]: 'data.regions.header.countdown',
  [TargetTypes.CHANNEL_NAVIGATION]: 'data.regions.channel-select',
  [TargetTypes.LOGO]: {
    logoLink: 'data.artwork.header.logoLink',
    logoTitle: 'data.artwork.header.logoTitle',
    logoUrl: 'data.artwork.header.logo',
  },
  [TargetTypes.LANDING]: 'data.landingContent',
  [TargetTypes.FOOTER_LINKS]: 'data.regions.footer.footerLinks',
  [TargetTypes.SOCIAL]: {
    hashtag: 'data.regions.footer.hashtag',
    socials: 'data.regions.footer.socials',
    tweetMessage: 'data.regions.footer.tweetMessage',
  },
  [TargetTypes.SPONSORS]: 'data.regions.footer.sponsors',
  [TargetTypes.NAVIGATION]: 'data.regions.navigation',
};

export const SITE_PATH_MAP = {
  [TargetTypes.HOME_ID]: 'settings.homeId',
  [TargetTypes.DEFAULT_THEME_ID]: 'settings.defaultThemeId',
};

export const clearPendingDataSaga = function* () {
  const state = yield select();
  const pendingChanges = getPendingAdminChanges(state);
  const editTarget = getEditTarget(state);
  const regionRendering = getRegionRendererDrafts(state);

  if (Object.keys(pendingChanges).length) {
    yield put(resetAllPendingAdminChanges());
  }

  if (editTarget) {
    yield put(clearEditTarget());
  }

  if (regionRendering[ID_SIDEBAR]) {
    yield put(clearRegionRendererDraft(ID_SIDEBAR));
  }
};

const exitEditModeOnLogoutSaga = function* () {
  yield put(setAdminMode(null));
};

export const mergeObject = (oldObject, rawChanges, pathMap) => {
  const oldObjectCopy = cloneDeep(oldObject);
  keys(rawChanges).forEach((key) => {
    if (typeof pathMap[key] === 'object') {
      const dataKeys = Object.keys(pathMap[key]);
      dataKeys.forEach((dataKey) => {
        const path = pathMap[key][dataKey];
        const { pendingData } = rawChanges[key];
        // TODO: Validity check or something - can't do straight invariant bc this
        //  gets called for both site & channel
        if (path && pendingData && pendingData[dataKey] !== undefined) {
          // need to update schedule items set from video region editor with a start time
          // so it coincides with PUBLISH time rather than SAVE time
          if (dataKey === 'schedule') {
            // this will only really happen with the 0th element of the scheudle arr but map JIC
            // TODO: Perf?
            const newSchedule = pendingData.schedule.map((scheduleItem) => {
              if (scheduleItem.syncStartTime) {
                // set the start time to 3 seconds in the future to account for save time
                const startTime = Date.now() + 3 * 1000;
                const updatedScheduleItem = {
                  ...scheduleItem,
                  endTime: new Date(
                    startTime + scheduleItem.duration * 1000,
                  ).toString(),
                  startTime: new Date(startTime).toString(),
                };
                delete updatedScheduleItem.syncStartTime;
                return updatedScheduleItem;
              }
              return scheduleItem;
            });
            set(oldObjectCopy, path, newSchedule);
          } else {
            set(oldObjectCopy, path, pendingData[dataKey]);
          }
        }
      });
    } else {
      const { pendingData } = rawChanges[key];
      const path = pathMap[key];
      // TODO: Validity check or something - can't do straight invariant bc this
      //  gets called for both site & channel
      if (path && pendingData !== undefined) {
        set(oldObjectCopy, path, pendingData);
      }
    }
  });
  return oldObjectCopy;
};

const fetchDocSaga = function* (collection, id) {
  invariant(collection, 'Missing/empty collection');
  invariant(id, 'Missing/empty id');
  const path = joinKeySegments([collection, id]);
  const ref = yield call(getRef, path);
  const snapshot = yield call([ref, 'once'], 'value');
  const doc = camelify(snapshot.val());
  return doc;
};

export const writeDocSaga = function* (collection, doc) {
  invariant(collection, 'missing/empty collection');
  const id = doc?._id;
  invariant(id, 'missing/empty doc id');

  const state = yield select();
  const primaryToken = getPrimaryToken(state);
  const siteId = getSiteId(state);
  const defaultThemeId = getSavedSettingsDefaultThemeId(state);
  const toPublishDoc = cloneDeep(doc);
  const features = getActiveSiteFeatures(state);

  // if new page and it doesn't have theme object then grab site's default theme and apply to page.
  if (doc.collection === 'pages' && !doc.data.theme) {
    const theme = yield call(getThemeById, {
      primaryToken,
      siteId,
      themeId: defaultThemeId,
    });
    toPublishDoc.data = {
      ...doc.data,
      theme: {
        classicThemeOptions: {
          accentPrimary: DARK_MODE_ACCENT_COLORS.accentPrimary,
          accentSecondary: DARK_MODE_ACCENT_COLORS.accentSecondary,
        },
        id: theme._id,
        type: theme.type,
      },
    };
  }

  if (doc.collection === 'pages' && features[Feature.PAGES_V3]) {
    const result = yield call(upsertPage, {
      doc: toPublishDoc,
      primaryToken,
      siteId,
    });
    return result;
  }
  const result = yield call(setDocument, {
    collection,
    id,
    primaryToken,
    siteId,
    value: toPublishDoc,
  });

  return result;
};

const overwriteObjectRendererSaga = function* (
  type,
  id,
  renderer,
  archivedTimestamp,
) {
  invariant(type, 'Missing/empty type');
  invariant(id, 'Missing/empty id');

  // Fetch & validate old doc from firebase
  const oldDoc = yield call(fetchDocSaga, 'objects', id);
  invariant(
    oldDoc,
    `Can't overwrite object ${id}; it apparently doesn't exist!`,
  );
  // const rendererField = `${type}Renderer`; // TODO: Ultra jank...

  if (oldDoc.renderer) {
    invariant(
      oldDoc.type === type,
      `Can't overwrite legacy object ${id}; got type ${oldDoc.type} but expected ${type}`,
    );
  }

  // Create new value
  const value = {
    ...oldDoc,
    renderer,
  };

  // delete doc flow
  if (archivedTimestamp) {
    value.archived = archivedTimestamp;
  }

  // Write to admin endpoint
  yield call(writeDocSaga, 'objects', value);
};

const isObjectOnChannel = (channel, objectId) => {
  const cardId = channel?.renderers?.sidebar.card_id;
  const panelsId = (channel?.renderers?.sidebar.items || []).map(
    (item) => item.id,
  );

  return objectId === cardId || panelsId.includes(objectId);
};

// checks if edited object (card or panel) exists as a reference on another page
const checkCrossChannelObject = function* (id, isDelete = false, objectType) {
  try {
    const state = yield select();
    const primaryToken = getPrimaryToken(state);
    const channel = getPage(state);
    const channelId = channel._id;
    const channelName = channel.data?.name || channel.seo?.title;

    const { data: conflicts = [] } = yield call(validateCrossChannelObject, {
      channelId,
      id,
      primaryToken,
    });

    const isOnChannel = isDelete && isObjectOnChannel(channel, id);

    const confirmedConflicts = isOnChannel ?
      [{ name: channelName }, ...conflicts] :
      conflicts;

    if (confirmedConflicts.length) {
      yield put(
        showModal({
          data: { conflicts: confirmedConflicts, isDelete, type: objectType },
          kind: CROSS_CHANNEL_VALIDATION_MODAL,
        }),
      );
      const { dismiss } = yield race({
        confirm: take(CONFIRM_CROSS_CHANNEL_VALIDATION_MODAL),
        dismiss: take(
          ({ type, payload }) => type === DISMISS_MODAL &&
            payload === CROSS_CHANNEL_VALIDATION_MODAL,
        ),
      });
      if (dismiss) {
        return false;
      }
    }
    return true;
  } catch (e) {
    return true;
  }
};

const editCollectionItemSaga = function* ({ payload }) {
  const {
    type,
    id,
    renderer,
    archivedTimestamp,
    validateCrossChannelObject: requiresValidation,
    isDelete,
  } = payload;

  const confirmed = requiresValidation ?
    yield checkCrossChannelObject(id, isDelete, type) :
    true;

  if (confirmed) {
    yield overwriteObjectRendererSaga(type, id, renderer, archivedTimestamp);
    yield put(writeLegacySuccess());
    return true;
  }
  return false;
};

const publishPendingPanelChangesSaga = function* () {
  const state = yield select();
  const pendingPanels = getPendingPagePanelsChanges(state);
  const pageId = getCurrentPageId(state);

  if (!pendingPanels) {
    return;
  }

  const pendingPanelChanges = Object.values(pendingPanels).map(
    (panelRenderer) => call(
      overwriteObjectRendererSaga,
      'panel',
      panelRenderer.id,
      panelRenderer,
      undefined,
    ),
  );
  yield all(pendingPanelChanges);

  yield put(clearPendingPanelChange(pageId));
};

export const saveErrorSaga = function* (error, customMessage = 'Admin save error') {
  yield put(logError(error, customMessage));
  const modalMessage = `${customMessage}: ${error.message}`;
  yield put(showAdminErrorModal(modalMessage));
};

const setEditTargetSaga = function* ({ payload }) {
  if (payload.targetName === TargetTypes.OFFLINE_IMAGE) {
    yield put(replace({ query: { admin: 'pages' } }));
  }
};

export const handlePageWriteError = function* (error) {
  const isSupportContactMissed = String(error.response?.data?.message).match(
    /forbidden to enable entitlement gate/,
  );
  if (isSupportContactMissed) {
    yield put(showModal({ kind: ModalKinds.supportContact }));
    return;
  }

  const isYoutubeOrTwitchContentGated = String(error.response?.data?.message).match(
    /forbidden to enable gate due to youtube and twitch terms of service/,
  );
  if (isYoutubeOrTwitchContentGated) {
    yield put(showModal({ kind: ModalKinds.haltGate }));
    return;
  }
  yield call(saveErrorSaga, error, 'Admin page write error');
}

const updatePendingDocGateState = function* (updatedPage) {
  // If there is pending changes, updated gate value there too
  const state = yield select();
  const pendingDoc = getPendingPage(state);

  if (pendingDoc) {
    const updatedGateState = updatedPage.data.gate.active;
    yield put(
      setPendingPageDoc(updatedPage._id, {
        ...pendingDoc,
        data: {
          ...pendingDoc.data,
          gate: {
            ...pendingDoc.data.gate,
            active: updatedGateState,
          },
        },
      }),
    );
  }
};

export const publishGateStateSaga = function* ({ payload }) {
  const { updatedPage } = payload;
  try {
    yield call(writeDocSaga, 'objects', updatedPage);
    yield call(updatePendingDocGateState, updatedPage);
  } catch (error) {
    yield call(handlePageWriteError, error);
  }
};

/**
 * This saga just handles changes related to gate password for now
 * We could use it to manage whole gate changes in the future
 */
export const publishPendingGateChangesSaga = function* () {
  const state = yield select();
  const primaryToken = getPrimaryToken(state);
  const pageId = getPageId(state);
  const siteId = getSiteId(state);
  const pendingGateChanges = getPendingGateChange(state);
  const password = pendingGateChanges?.gate?.password || null;

  if (primaryToken && !isNull(password)) {
    yield call(setChannelPassword, {
      channelId: pageId,
      password,
      primaryToken,
      siteId,
    });
  }

  if (pendingGateChanges) {
    yield put(clearPendingGateChange(pageId));
  }
};

function* archivePageSaga({ payload: { id } }) {
  const state = yield select();
  const siteId = getSiteId(state);
  const primaryToken = getPrimaryToken(state);
  yield call(archiveDocument, { id, primaryToken, siteId });
}

// TODO: This needs to be about 12 times less complicated. This logic doesn't belong here.
const writeToCollectionSaga = function* ({
  payload: { doc: sourceDoc },
}) {
  // Get everything we need
  const doc = cloneDeep(sourceDoc); // making my life easier > perf
  const state = yield select();
  const siteId = getSiteId(state);
  const primaryToken = getPrimaryToken(state);
  const now = Date.now();
  const { type } = doc;
  const features = getActiveSiteFeatures(state);
  invariant(type, 'need type plz');
  invariant(!hasOwn(doc, 'siteId') || doc.siteId === siteId, 'Invalid siteId');

  // Funky logic to ensure renderer has ID and doc ID matches renderer ID.
  // TODO: Just use `renderer` and `id`. This is totally my bad, sorry guys :( - Andy
  const objectRendererKey = 'renderer';
  // const docKeys = Object.keys(doc);
  // invariant( // Make sure e.g. a panel doesn't have cardRenderer defined
  //   docKeys
  //     .filter(key => key.endsWith('renderer'))
  //     .every(key => key === objectRendererKey),
  //   `found key for wrong renderer type in doc: ${JSON.stringify(doc, null, 2)}`,
  // );
  const rendererIdKey = 'id';
  const renderer = doc[objectRendererKey];
  const rendererId = renderer?.[rendererIdKey];
  if (doc._id && rendererId) {
    invariant(
      doc._id === rendererId,
      `ID mismatch: doc ID is ${doc._id}, but renderer ID is ${rendererId}`,
    );
  }
  const id = doc._id || rendererId || createObjectId();
  doc._id = id;
  if (renderer) {
    renderer[rendererIdKey] = id;
  }

  // Validate document metadata
  doc.created = doc.created || now;
  if (doc.collection !== 'pages' && doc.slug) {
    doc.slug = `${type}-${id}-generated`;
  } else {
    doc.slug = doc.slug || `${type}-${id}-generated`;
  }
  doc.lastModified = now;
  doc.siteId = siteId;

  // Perform the write
  // TODO: Error handling, mutha fucka!
  if (doc.collection === 'pages' && features[Feature.PAGES_V3]) {
    yield call(upsertPage, {
      doc,
      primaryToken,
      siteId,
    });
  } else {
    yield call(setDocument, {
      collection: 'objects', // mongo collection, not object collection. fuck our old shitty schema.
      id,
      primaryToken,
      siteId,
      value: doc,
    });
  }

  yield put(writeLegacySuccess());
};

const generateMockPanel = (kind, panelId) => {
  const mockPanel = mockPanelData[kind];
  let renderer = {
    iconName: mockPanel.iconName,
    id: panelId,
    panelName: mockPanel.panelName,
    panelType: mockPanel.panelType,
  };

  if (mockPanel.blockData) {
    renderer = {
      ...renderer,
      blockData: mockPanel.blockData,
    }
  }

  return {
    collection: 'panels',
    renderer,
    type: 'panel',
  };
}
export const createChannelOrPageSaga = function* ({ payload }) {
  const { newChannelOrPage, path, updatedDefaultNavigation } = payload;
  try {
    const state = yield select();
    const primaryToken = getPrimaryToken(state);
    const isLegacyPlan = getIsLegacyPlan(state);
    const isUnlimitedPlan = getIsUnlimitedPlan(state);
    const planHavePageLimits = !isLegacyPlan && !isUnlimitedPlan;
    if (planHavePageLimits) {
      const planFeatures = getPlanFeatures(state);
      const maxAmountOfPages = planFeatures?.maxAmountOfPages || MAX_AMOUNT_OF_PAGES;
      const maxAmountOfChannels = planFeatures?.maxAmountOfChannels || MAX_AMOUNT_OF_CHANNELS;
      const amountOfChannels = yield call(getAmountOfChannels, { primaryToken });
      const haveExceededChannelLimit =
      newChannelOrPage?.type === PageType.LANDING ?
        amountOfChannels.pages >= maxAmountOfPages :
        amountOfChannels.channels >= maxAmountOfChannels;
      /*
      * Pricing V2: If the user is on a plan that has page limits and they have reached the limit,
      * show the upgrade plan modal and halt channel creation.
      */
      if (haveExceededChannelLimit) {
        yield put(showModal({
          data: {
            planWarningMessage: 'ADMIN_UPGRADE_PLAN_EXCEEDED_MAX_AMOUNT_OF_CHANNELS',
            preSelectedPlan: SCALE_ANNUAL_PLAN_ID,
          },
          kind: ModalKinds.upgradePlan,
        }));
        return;
      }
    }

    if (newChannelOrPage?.type === PageType.CHANNEL) {
      // create new chat panel
      const newPanelId = createObjectId();
      const newPanel = generateMockPanel(CHAT_ID, newPanelId)

      // enable sidebar with the above created chat panel by default in the new channel
      newChannelOrPage.activeRenderers = { sidebar: true };
      newChannelOrPage.renderers = {
        sidebar: {
          items: [{ arrayId: shortid.generate(), id: newPanelId, isActive: true }],
        },
      };

      yield call(writeToCollectionSaga, { payload: { doc: newPanel } });
    }

    yield call(writeDocSaga, 'objects', newChannelOrPage);
    yield put(updateNavigation(updatedDefaultNavigation));
    // much check the site structure
    yield put(replace({ path: path || `/${newChannelOrPage.slug}` }));
    yield put(setAdminBarSubMenuQueue(['main']));
    yield put(setActiveAction(null));

    while (true) {
      yield take(SET_OBJECT);
      const newState = yield select();
      const slug = getObjectSlug(newState);
      if (slug === newChannelOrPage.slug) {
        yield put(setAdminMode(ModeTypes.EDIT));
        yield put(
          navigateToAdminBarAction({ actionKey: ActionKey.pageOrChannelSettings }),
        );
        break;
      }
    }
  } catch (error) {
    yield put(
      showModal({
        data: { promptStringKey: 'ADMIN_ERROR_GENERIC' },
        kind: ModalKinds.errorModal,
      }),
    );
  }
};

export const createPanelSaga = function* ({ payload }) {
  const { kind, copyInformation } = payload;
  const state = yield select();
  const appObject = getObject(state);

  const newPanelId = createObjectId();
  try {
    // Create a new panel
    const newPanel = generateMockPanel(kind, newPanelId);

    if (copyInformation) {
      const { panel, numCopy } = copyInformation;
      newPanel.numCopy = numCopy;
      newPanel.renderer = {
        ...panel,
        id: newPanelId,
      };
    }
    yield call(writeToCollectionSaga, { payload: { doc: newPanel } });

    // Add new panel to page
    const newSidebarPanel = {
      arrayId: shortid.generate(),
      id: newPanelId,
      isActive: false,
    };

    const pageWithNewPanel = produce(appObject, (draft) => {
      if (appObject?.renderers?.sidebar) {
        draft.renderers.sidebar.items = [
          ...(appObject.renderers.sidebar.items || []),
          newSidebarPanel,
        ];
      } else {
        draft.activeRenderers = appObject.activeRenderers ?
          { ...appObject.activeRenderers } : {};
        draft.activeRenderers[ID_SIDEBAR] = true;
        draft.renderers.sidebar = {
          items: [newSidebarPanel],
        }
      }
    });
    yield call(writeDocSaga, 'objects', pageWithNewPanel);

    // Add new panel to draft changes if they exists
    const draftPanels = getPanelDrafts(state);
    if (!isUndefined(draftPanels)) {
      const newDraftPanels = produce(draftPanels, (draft) => {
        draft.push(newSidebarPanel);
      });
      yield put(setRegionRendererDraft(
        ID_SIDEBAR, { items: newDraftPanels },
      ));
    }
  } catch (error) {
    yield put(createPanelError());
    yield put(
      showModal({
        data: { promptStringKey: 'ADMIN_ERROR_GENERIC' },
        kind: ModalKinds.errorModal,
      }),
    );
  }
};

export const deletePanelSaga = function* ({ payload }) {
  const { id, renderer } = payload;
  const state = yield select();
  const appObject = getObject(state);

  try {
    const newPanels = appObject.renderers.sidebar.items.filter(panel => panel.id !== id);
    const pageWithoutPanel = produce(appObject, (draft) => {
      draft.renderers.sidebar.items = newPanels;
    });

    yield call(writeDocSaga, 'objects', pageWithoutPanel);

    const draftPanels = getPanelDrafts(state);
    if (!isUndefined(draftPanels)) {
      const newDraftPanels = (draftPanels || []).filter(panel => panel.id !== id);
      yield put(setRegionRendererDraft(
        ID_SIDEBAR, { items: newDraftPanels },
      ));
    }

    yield call(
      editCollectionItemSaga, {
        payload: {
          archivedTimestamp: Date().now,
          id,
          renderer,
          type: 'panel',
          validateCrossChannelObject: true,
        },
      },
    );

    yield put(dismissModal(ModalKinds.adminConfirmation));
  } catch (error) {
    yield put(dismissModal(ModalKinds.adminConfirmation));
    yield put(
      showModal({
        data: { promptStringKey: 'ADMIN_ERROR_GENERIC' },
        kind: ModalKinds.errorModal,
      }),
    );
  }
};

const SETTINGS_PATH_MAP = {
  [settingsTypes.ALLOW_NEW_ADMIN]: 'settings.allowNewAdmin',
  [settingsTypes.CONCURRENTS]: 'settings',
  [settingsTypes.SUBSCRIPTIONS]: 'renderers.subscriptionSettings',
  [settingsTypes.ICONS]: 'settings.icons',
  [settingsTypes.LOCALIZATION]: 'settings.localization',
  [settingsTypes.LOGIN]: 'settings.login',
  [settingsTypes.USER_PROFILES]: 'settings.userProfiles',
  [settingsTypes.THIRD_PARTY]: 'settings.services',
  [settingsTypes.VIDEO_PLAYER]: 'regions.player',
  [settingsTypes.CUSTOMER_PROFILE]: 'settings.customerProfile',
  [settingsTypes.TAGS]: 'tags',
};

const formatSiteData = function (site, payload) {
  const { typeKey, data } = payload;

  invariant(
    typeKey && typeof typeKey === 'string' && settingsTypes[typeKey],
    `settingsTypes input error in setSettingsSaga. settingsTypes provided: ${typeKey}`,
  );
  switch (typeKey) {
    case settingsTypes.SUBSCRIPTIONS: {
      const { defaultBackground, subscriptionsActive } = data;
      return {
        defaultBackground,
        subscriptionsActive,
      };
    }
    case settingsTypes.VIDEO_PLAYER:
      return {
        autoplayYoutube: data.autoplayFlag,
        showVideoEmbed: data.embedFlag,
        showVideoTitle: data.titleFlag,
        showVideoUrl: data.urlFlag,
        showYoutubeNoCookie: data.youtubeNoCookieFlag,
        socials: data.socialsData,
      };
    case settingsTypes.CONCURRENTS:
      return {
        ...site.settings,
        ...data,
      };
    case settingsTypes.THIRD_PARTY:
      return {
        ...(site.settings && site.settings.services),
        facebookAppId: data.facebookAppId,
        facebookPixelId: data.facebookPixelId,
        faceit: data.faceit,
        googleAnalyticsId: data.googleAnalyticsId,
      };
    case settingsTypes.ALLOW_NEW_ADMIN:
    case settingsTypes.TAGS:
      return data;
    default:
      return { ...data };
  }
};

const setSiteDataSaga = function* ({ payload }) {
  const { typeKey } = payload;
  const state = yield select();
  const siteObject = getSite(state);
  const siteClone = cloneDeep(siteObject);
  try {
    if (typeKey === TargetTypes.HOME_ID) {
      set(siteClone, SITE_PATH_MAP[typeKey], payload.data.homeId);
    } else {
      const formattedData = yield call(formatSiteData, siteClone, payload);
      set(siteClone, SETTINGS_PATH_MAP[typeKey], formattedData);
    }

    // todo: remove this once they're merged over
    if (
      typeKey === settingsTypes.LOCALIZATION &&
      siteClone.settings.baseLanguage
    ) {
      delete siteClone.settings.baseLanguage;
    }

    const siteId = getSiteId(state);
    const primaryToken = getPrimaryToken(state);
    yield call(setDocument, {
      collection: 'sites',
      id: siteId,
      primaryToken,
      siteId,
      value: siteClone,
    });
  } catch (e) {
    console.log('Error in set site data saga: ', e); // eslint-disable-line no-console
  }
};

const upsertQuestSaga = function* ({ payload: questDoc }) {
  const state = yield select();
  const siteId = getSiteId(state);
  const primaryToken = getPrimaryToken(state);
  const upsertQuest = questDoc._id ? updateQuest : createQuest;

  try {
    yield call(upsertQuest, { primaryToken, questDoc, siteId });
    yield put(updateRefreshKey());
  } catch (e) {
    const error = e.response?.statusText || e.message || 'Unknown error';
    const message = e.response?.data || error;
    yield put(showAdminErrorModal(message));
  }
};

export const openBillingTabSaga = function* () {
  const state = yield select();
  const activeAction = getActiveAction(state);

  if (activeAction !== ActionKey.billing) {
    yield put(navigateToAdminBarAction({ actionKey: ActionKey.billing }));
  }
};

export const trackActiveActionSaga = function* ({ payload }) {
  if (!payload) {
    return;
  }
  const state = yield select();
  const menu = yield getCurrentSubMenuKey(state);
  const element = payload === 'landing' ? 'page' : payload;

  yield put(
    trackEvent({
      event: AdminActionEvents.NAVBAR,
      properties: { element, menu },
    }),
  );
};

export const trackNavigationGoBackSaga = function* ({ payload }) {
  const element = 'back';
  const menu = payload;
  yield put(
    trackEvent({
      event: AdminActionEvents.NAVBAR,
      properties: { element, menu },
    }),
  );
};

export const trackNavigationGoBackOnNestedMenuSaga = function* ({ payload }) {
  const element = 'back';
  const menu = payload.actionKey;
  yield put(
    trackEvent({
      event: AdminActionEvents.NAVBAR,
      properties: { element, menu },
    }),
  );
};

export const trackNavigationNestedSaga = function* ({ payload }) {
  const element = payload;
  const menu = 'main';
  // we already are tracking channels | landing in trackActiveActionSaga
  if (payload === ActionKey.pageOrChannel || payload === 'landing') {
    return;
  }

  yield put(
    trackEvent({
      event: AdminActionEvents.NAVBAR,
      properties: { element, menu },
    }),
  );
};

export const setAccessControlFTUESaga = function* () {
  const state = yield select();
  const isAdmin = isUserAdmin(state);
  const account = getUserAccount(state);
  const accessToken = getPrimaryToken(state);

  if (!isAdmin || !account || !accessToken) {
    return;
  }

  const accountTags = account.tags || [];

  const tags = [
    ...accountTags.filter((tag) => !/access-ftue:/.test(tag)),
    `access-ftue:${Date.now()}`,
  ];

  const updatedTags = yield call(
    putTag,
    accessToken,
    SITE_ID,
    account._id,
    tags,
  );

  yield put(setAccountTags(updatedTags.tags));
};

/**
 * ⚠ IMPORTANT ⚠
 * Changes applied to page regions are stored on pendingAdminChanges
 * However, to publish changes all of them needs to be part of pendinPageDocs
 * To resolve this issue, we implemented mergeAdminChangesToPendingPageDoc
 * to merge these two objects in Channels 2.0.
 *
 * In the future, we plan to deprecate pendingAdminChanges (NS-7016) and only use pendingPageDocs
 * Until that, please avoid make changes to this saga or
 * if you need it please ask Jean Karlo Obando first.
 */
export const mergeAdminChangesToPendingPageDoc = function* ({ payload }) {
  const state = yield select();
  const { targetType, pendingData } = payload;

  const targetData = getPendingAdminChanges(state);
  const localData = targetData[targetType]?.localData;
  const toEditPageDoc = getEditingPageDoc(state);

  if (!Object.keys(CHANNEL_PATH_MAP).includes(targetType)) {
    return;
  }

  if (!isEqual(localData, pendingData)) {
    const updatedChannelObject = yield call(
      mergeObject,
      toEditPageDoc,
      { [targetType]: targetData[targetType] },
      CHANNEL_PATH_MAP,
    );

    yield put(
      setPendingPageDoc(updatedChannelObject._id, updatedChannelObject),
    );
  }
};

/**
 * ⚠ IMPORTANT ⚠
 * Changes applied to sidebar (cards and panels) are stored on regionRendererDrafts
 * However, to publish changes all of them needs to be part of pendinPageDocs
 * To resolve this issue, we implemented mergeRendererChangesToPendingPageDoc
 * to merge these two objects in Channels 2.0.
 *
 * In the future, we plan to deprecate regionRendererDrafts (NS-7016) and only use pendingPageDocs
 * Until that, please avoid make changes to this saga or
 * if you need it please ask Jean Karlo Obando first.
 */
export const mergeRendererChangesToPendingPageDoc = function* ({ payload }) {
  const state = yield select();
  const toEditPage = getEditingPageDoc(state);
  const { renderer } = payload;
  const updatedChannelObject = {
    ...toEditPage,
    renderers: {
      ...toEditPage.renderers,
      sidebar: renderer,
    },
  };

  yield put(setPendingPageDoc(updatedChannelObject._id, updatedChannelObject));
};

export const setHomePageSaga = function* ({ payload }) {
  const { pageId } = payload;
  yield put(setPendingAdminChange(TargetTypes.HOME_ID, pageId));
  yield put(publishPendingChanges());
};

export const adminBarNavigationSaga = function* ({ payload }) {
  const state = yield select();
  const roles = getUserRoles(state);
  const subMenuQueue = getAdminBarSubMenuQueue(state);
  const { actionKey, replace: shouldReplace } = payload;

  if (
    roles.some((role) => SCOPE_BLACKLIST_ROLE_MAP[role.scope]?.includes(actionKey))
  ) {
    return;
  }

  const currentQueue = shouldReplace ? subMenuQueue.slice(0, -1) : subMenuQueue;
  const currentSubMenuKey = currentQueue[currentQueue.length - 1];
  const navigatedSubMenuKey = SUB_MENU_ACTION_MAP[actionKey];
  const adminBarSubMenuQueue =
    navigatedSubMenuKey !== 'orphans' &&
    navigatedSubMenuKey &&
    currentSubMenuKey !== navigatedSubMenuKey ?
      [...currentQueue, navigatedSubMenuKey] :
      currentQueue;

  yield put(
    adminBarNavigation({ activeAction: actionKey, adminBarSubMenuQueue }),
  );
};

function* setAdminStreamModeSaga({ newObject }) {
  const state = yield select();
  const isAdmin = isUserAdmin(state);
  if (!isAdmin) {
    return;
  }
  const object = newObject || getObject(state);
  const { renderers } = object;
  const livestreamPublished = renderers?.livestream?.livestreamPublished;
  const source = renderers?.livestream?.source;
  const isInStreamMode = isMaestroStreamMode(state);
  const activeAction = getActiveAction(state);
  const activeActionType = getActiveActionType(state);

  if (livestreamPublished && source === StreamSource.THIRD_PARTY) {
    if (!isInStreamMode && !activeAction && !activeActionType) {
      yield put(setAdminMode(ModeTypes.MAESTRO_STREAM));
    }
  } else {
    if (isInStreamMode) {
      yield put(setAdminMode(null));
    }
    if (activeAction === ActionKey.streaming) {
      yield put(setActiveAction(null));
    }
    if (activeActionType === ActionType.openAdminMode) {
      yield put(setActiveActionType(undefined));
    }
  }
}

const adminSaga = function* () {
  yield takeEvery(SAVE_QUEST, upsertQuestSaga);
  yield takeLatest(LOG_OUT, exitEditModeOnLogoutSaga);
  yield takeEvery(PUBLISH_PENDING_CHANGES, publishSaga);
  yield takeEvery(PUBLISH_PAGE_PENDING_CHANGES, publishPendingPage)
  yield takeEvery(PUBLISH_PENDING_GATE_CHANGES, publishPendingGateChangesSaga);
  yield takeEvery(PUBLISH_GATE_STATE, publishGateStateSaga);
  yield takeEvery(
    PUBLISH_PENDING_PANEL_CHANGES,
    publishPendingPanelChangesSaga,
  );
  yield takeEvery(CREATE_CHANNEL_OR_PAGE, createChannelOrPageSaga);
  yield takeEvery(CREATE_PANEL, createPanelSaga);
  yield takeEvery(DELETE_PANEL, deletePanelSaga);
  yield takeEvery(WRITE_TO_COLLECTION, writeToCollectionSaga);
  yield takeEvery(ARCHIVE_PAGE, archivePageSaga);
  yield takeEvery(EDIT_COLLECTION_ITEM, editCollectionItemSaga);
  // TODO abstract to save all settings
  yield takeEvery(SAVE_SITE_SETTINGS, setSiteDataSaga);
  yield takeEvery(BEFORE_PAGE_CHANGE, clearPendingDataSaga);
  yield takeEvery(SET_EDIT_TARGET, setEditTargetSaga);
  yield takeLatest(SET_ACCESS_CONTROL_FTUE, setAccessControlFTUESaga);
  yield takeEvery(SET_PENDING_ADMIN_CHANGE, mergeAdminChangesToPendingPageDoc);
  yield takeEvery(
    SET_REGION_RENDERER_DRAFT,
    mergeRendererChangesToPendingPageDoc,
  );
  yield takeEvery(OPEN_BILLING_TAB, openBillingTabSaga);
  yield takeEvery(SET_ACTIVE_ACTION, trackActiveActionSaga);
  yield takeEvery(
    NAVIGATE_TO_ADMIN_BAR_ACTION,
    trackNavigationGoBackOnNestedMenuSaga,
  );
  yield takeEvery(NAVIGATE_TO_ADMIN_BAR_ACTION, adminBarNavigationSaga);
  yield takeEvery(POP_ADMIN_BAR_SUB_MENU_KEY, trackNavigationGoBackSaga);
  yield takeEvery(PUSH_ADMIN_BAR_SUB_MENU_KEY, trackNavigationNestedSaga);
  yield takeEvery(SET_HOME_PAGE, setHomePageSaga);
  yield takeEvery([
    LOG_IN_SUCCESS,
    GLOBAL_ACCOUNTS_LOG_IN_SUCCESS,
    ON_PAGE_CHANGE,
  ],
  setAdminStreamModeSaga);
};

export default adminSaga;
