import { IProductData } from 'components/Ecommerce/models';
import { ICartItem } from './models';
import hash from 'json-stable-stringify';
import { ICheckoutStorefront } from 'services/shopify/models';
import { IShippingAddress } from 'services/shipping-address';

export const removeCartOrShopifyItemDuplicates = (items: ICartItem[] | IProductData[]): ICartItem[] | IProductData[] => {
  if (!items.length) {
    return [];
  }

  const removeCartItemDuplicates = (cartItems: ICartItem[]): ICartItem[] => {
    const cartHashMap: Map<string, ICartItem> = new Map();
    for (const cartItem of cartItems) {
      const { productId, variantId, quantity } = cartItem;
      const key = hash({ productId, variantId });
      if (key in cartHashMap) {
        const currentItem = cartHashMap[key] as ICartItem;
        cartHashMap[key] = { ...currentItem, quantity: currentItem.quantity + quantity };
        continue;
      }
      cartHashMap[key] = cartItem;
    }

    return Object.values(cartHashMap);
  };

  const removeShopifyItemDuplicates = (productsData: IProductData[]): IProductData[] => {
    const productHashMap: Map<string, IProductData> = new Map();
    for (const productData of productsData) {
      const { product, preSelectedOptions } = productData;
      const { id } = product;
      const key = hash({ id, preSelectedOptions });
      if (key in productHashMap) {
        const { product: { quantity: currentQuantity } } = productHashMap[key] as IProductData;
        product.quantity += currentQuantity;
        continue;
      }
      productHashMap[key] = productData;
    }

    return Object.values(productHashMap);
  };

  if ('product' in items[0]) {
    return removeShopifyItemDuplicates(items as IProductData[]);
  }
  return removeCartItemDuplicates(items as ICartItem[]);
};

export const hasMatchingShippingAddress = (checkout: ICheckoutStorefront | null, inputShippingAddress: IShippingAddress) => {
  if (!checkout) return false;
  /**
   * Remove State, Province Code & address 2 fields from stripe and shopify. The stripe state field uses the ISO-3166 state abbreviations,
   * while shopify provideCode uses the 2 letter code which will fail the check in certain scenarios. For certian mexican addresses, stripe
   * will utilize the address 2 field while shopify won't even have it included.
   * Ex: a Mexico City address will result in inputShippingAddress.state = 'CDMX' & checkout.shippingAddress.provinceCode = 'DF'
   * Links: https://matrixify.app/documentation/shopify-address-countries-and-provinces/ & https://stripe.com/docs/tax/customer-locations
   */
  const { state, address2: stripeAddress2, ...stripeShippingAddress } = inputShippingAddress;
  const { shippingAddress: { provinceCode, province, address2, ...shopifyShippingAddress } } = checkout;
  // removes all falsy values
  const sanitizedInputSA = Object.values(stripeShippingAddress).filter(data => !!data);
  const sanitizedCheckoutSA = Object.values(shopifyShippingAddress).filter(data => !!data) as string[];
  // fail early if both falsy removed arrays have different lengths
  if (sanitizedInputSA.length !== sanitizedCheckoutSA.length) return false;

  const inputShippingAddressSet = new Set(sanitizedInputSA);
  for (const value of sanitizedCheckoutSA) {
    if (!inputShippingAddressSet.has(value)) return false;
  }
  return true;
};
