import * as Sentry from '@sentry/react';
import { ENVIRONMENT } from 'config';

const filteredErrors = [
  /ResizeObserver loop limit exceeded/,
  /Not in fullscreen mode/,
];

Sentry.init({
  dsn: 'https://7e50775b8ee68e6be5f7b40536d5cfcc@o4506894189330432.ingest.us.sentry.io/4506894190903296',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  ignoreErrors: filteredErrors,
  environment: ENVIRONMENT,
  // Performance Monitoring
  tracesSampleRate: 0.05, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api\.maestro\.io/, /^https:\/\/dev\.api\.maestro\.io/, /^https:\/\/ruby\.maestro\.io/],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: ENVIRONMENT !== 'development',
});