import type IStream from 'models/IStream';
import { decryptStreamData } from 'services/livestream';
import { flattenCalls } from 'utils';
import { makeCached } from 'utils/cache';

const { cachedFn: cachedDecryptStreamData } = makeCached(
  flattenCalls(
    decryptStreamData,
  ),
);

export const streamMapper = async (
  rawStreamDoc: any,
  authToken: string,
): Promise<IStream> => {
  if (!rawStreamDoc?.sensitive || !rawStreamDoc._id)
    throw new Error(`Stream must have a 'sensitive' prop and '_id'`);

  const sensitiveData = await cachedDecryptStreamData(
    rawStreamDoc._id,
    authToken,
    rawStreamDoc.sensitive,
  );

  return {
    ...rawStreamDoc,
    ...sensitiveData,
  };
};
