// TODO: UA detection
import { persistenceService } from 'services/persistence';
import { v4 } from 'uuid';
import {
  IDeviceAction,
  ORIENTATION,
  ORIENTATION_DEGREES,
  UPDATE_DEVICE_CREATED,
  UPDATE_DIMENSIONS,
} from './actions';

export const getPermanentId = (): string => {
  const persistence = persistenceService();
  const key = 'mio_pid_5855';
  let id = persistence.readStatic<string>(key, true);
  if (!id) {
    id = v4();
    persistence.write(key, id, true);
  }
  return id;
};

export interface IDeviceState {
  created: null | number;
  height: number;
  navigatorPlatform: string;
  newDevice: null | boolean;
  orientation: 'portrait' | 'landscape';
  orientationDegrees: number;
  permanentId: string;
  screenHeight: number;
  screenWidth: number;
  tabId: string;
  userAgent: string | null;
  width: number;
}

// TODO: Merge with UA stuff
export const getInitialState = (): IDeviceState => ({
  created: null,
  height: window.innerHeight,
  navigatorPlatform: navigator && navigator.platform,
  newDevice: null,
  orientation: 'portrait',
  orientationDegrees: 0,
  permanentId: getPermanentId(),
  screenHeight: window.screen.height,
  screenWidth: window.screen.width,
  tabId: v4(),
  userAgent: navigator && navigator.userAgent || null,
  width: window.innerWidth,
});

const deviceReducer = (
  state: IDeviceState = getInitialState(),
  action: IDeviceAction,
): IDeviceState => {
  switch (action.type) {
    case UPDATE_DEVICE_CREATED: {
      const { created, newDevice } = action.payload;
      return {
        ...state,
        created,
        newDevice,
      };
    }
    case UPDATE_DIMENSIONS: {
      const { height, width } = action.payload;
      return {
        ...state,
        height,
        width,
      };
    }
    case ORIENTATION:
      return {
        ...state,
        orientation: action.payload ? 'landscape' : 'portrait',
      };
    case ORIENTATION_DEGREES:
      const { orientationDegrees } = action.payload;
      if (orientationDegrees === state.orientationDegrees) {
        return state;
      }

      return {
        ...state,
        orientationDegrees,
      };
    default:
      return state;
  }
};

export default deviceReducer;
