import IBundle, { IBundleItem } from 'models/IBundle';
import ISubscription from 'models/ISubscription';

export const isBundleItem = (item: any): item is IBundleItem => {
  return (
    item &&
    item.type && typeof item.type === 'string' &&
    (item.type === 'ticket' || item.type === 'shopify') &&
    item.id && typeof item.id === 'string'
  );
};

export const isBundle = (obj: any): obj is IBundle => {
  return (
    obj &&
    obj.name && typeof obj.name === 'string' &&
    obj.siteId && typeof obj.siteId === 'string' &&
    obj.sku && typeof obj.sku === 'string' &&
    obj.price && typeof obj.price === 'number' && obj.price > 0 &&
    obj.presentmentCurrency && typeof obj.presentmentCurrency === 'string' &&
    Array.isArray(obj.items) && obj.items.every(isBundleItem)
  );
};

export const getServiceChargeOfAllTicketsOnABundle = (
  tickets: ISubscription[],
  presentmentCurrency: string,
  serviceChargeRate: number,
  ) => {
  let ticketPriceSum = 0;

  for (const ticket of tickets) {
    const presentmentCurrencyAmount = ticket.prices.find(ticketPrice => {
      return ticketPrice.currency === presentmentCurrency;
    })?.value || 0;
    ticketPriceSum += presentmentCurrencyAmount;
  }
  const serviceChargeAmount = ticketPriceSum * serviceChargeRate;
  return serviceChargeAmount;
};
