import IState from 'services/state';
import { getQuery } from './common';

export * from './common';

export const deleteQuery = (param:string) => {
  const localUrl = new URL(window.location.href);
  localUrl.searchParams.delete(param);
  window.history.pushState('', document.title, localUrl.toString());
};

const ADOBE_TOKEN_KEY = 'adobe_access_token';
const ADOBE_ERROR_KEY = 'adobe_error';
const BOWLTV_TOKEN_KEY = 'bowltv_access_token';
const BOWLTV_ID_KEY = 'id_token';
const XBOX_TOKEN_KEY = 'xbox_access_token';
const XBOX_ERROR_KEY = 'xbox_err';

export interface IAdobeLegacyAuthQuery {
  [ADOBE_ERROR_KEY]?: string;
  [ADOBE_TOKEN_KEY]?: string;
}

export interface IBowlTvLegacyAuthQuery {
  [BOWLTV_TOKEN_KEY]?: string;
  [BOWLTV_ID_KEY]?: string;
}
export interface IXboxLegacyAuthQuery {
  [XBOX_TOKEN_KEY]?: string;
  [XBOX_ERROR_KEY]?: string;
}

export interface ICornellLegacyAuthQuery {
  email?: string;
  name?: string;
  saml_response?: string;
  username?: string;
}

export const getAdobeParamsFromQuery = (state: IState): IAdobeLegacyAuthQuery => {
  const query = getQuery(state);
  return {
    [ADOBE_ERROR_KEY]: query[ADOBE_ERROR_KEY]?.toString(),
    [ADOBE_TOKEN_KEY]: query[ADOBE_TOKEN_KEY]?.toString(),
  };
};

export const getBowlTvParamsFromQuery = (state: IState): IBowlTvLegacyAuthQuery => {
  const query = getQuery(state);
  return {
    [BOWLTV_ID_KEY]: query[BOWLTV_ID_KEY]?.toString(),
    [BOWLTV_TOKEN_KEY]: query[BOWLTV_TOKEN_KEY]?.toString(),
  };
};

export const getCornellParamsFromQuery = (state: IState): ICornellLegacyAuthQuery => {
  const query = getQuery(state);
  return {
    email: query.email && query.email.toString(),
    name: query.name && query.name.toString(),
    saml_response: query.saml_response && query.saml_response.toString(),
    username: query.username && query.username.toString(),
  };
};

export const getXboxParamsFromQuery = (state: IState): IXboxLegacyAuthQuery => {
  const query = getQuery(state);
  return {
    [XBOX_ERROR_KEY]: query[XBOX_ERROR_KEY]?.toString(),
    [XBOX_TOKEN_KEY]: query[XBOX_TOKEN_KEY]?.toString(),
  };
};
