import { SERVER_TIME } from 'firebase-db';
import { call, select, takeEvery, put } from 'redux-saga/effects';
import {
  getPrimaryToken,
  getUserToken,
  isLoggedIn,
  isUserAdmin,
  isUserChatModeratorRole,
} from 'services/auth/selectors';
import { getObjectId, getSiteId } from 'services/app/selectors';
import { showTextModal, loginModal } from 'services/modals/actions';
import { getUserName, getUserAvatar } from 'services/user-profile/selectors';
import {
  CLEAR_ALL_MESSAGES,
  EXPORT_CHAT,
  SEND_MESSAGE,
  SEND_ADMIN_MESSAGE,
  FETCH_SILENCED_USERS,
  sendAdminMessage,
  TOGGLE_USER_SILENCE,
  REMOVE_MESSAGE,
  setExportGenerating,
  setSilencedUsers,
  INIT_CHAT_CHANNEL,
  ISendMessageAction,
  ISendAdminMessageAction,
  IExportChatAction,
  IFetchSilencedUsersAction,
  IClearAllMessagesAction,
  IRemoveMessageAction,
  IInitChatChannelAction,
  IToggleUserSilenceAction,
} from './actions';
import { exportChat, pushToAdminQueue, pushToChatQueue, getSilencedUsers } from './api';

export const sendMessage = function* ({ payload: { key, channel, text } }: ISendMessageAction) {
  const state = yield select();
  if (!isLoggedIn(state)) {
    // Chat is login only -- show a login modal
    yield put(loginModal());
    return;
  }
  const username = getUserName(state);
  const image = getUserAvatar(state);

  const data = {
    image: image || null,
    text,
    username,
  };
  yield call(pushToChatQueue, key, {
    channel,
    data,
    object_id: getObjectId(state) || null,
    site_id: getSiteId(state) || null,
    time: SERVER_TIME,
    token: getUserToken(state),
  });
};

export const sendAdminMessageSaga = function* ({ payload: { channel, data } }: ISendAdminMessageAction) {
  const state = yield select();
  if (!isUserAdmin(state) && !isUserChatModeratorRole(state)) {
    return;
  }

  yield call(pushToAdminQueue, {
    channel,
    data,
    object_id: getObjectId(state) || null,
    site_id: getSiteId(state) || null,
    time: SERVER_TIME,
    token: getUserToken(state),
  });
};

export const exportChatSaga = function* ({ payload: { channelId, endTime, startTime } }: IExportChatAction) {
  const state = yield select();
  const siteId = getSiteId(state);
  const token = getPrimaryToken(state);
  yield put(setExportGenerating(true));
  try {
    yield call(exportChat, {
      channelId,
      endTime,
      siteId,
      startTime,
      token,
    });
  } catch (err) {
    // tslint:disable-next-line: no-console
    console.error(err.stack);
    yield put(showTextModal('An error occurred. Please refresh and try again.'));
  } finally {
    yield put(setExportGenerating(false));
  }
};

export const authenticatedCall = function* (method: any, params: any) {
  const state = yield select();
  const primaryToken = getPrimaryToken(state);
  const siteId = getSiteId(state);
  if (!primaryToken) {
    throw new Error('No primary token existed in state!');
  }
  const results = yield call(method, { primaryToken, siteId, ...params });
  return results;
};

export const fetchSilencedUsersSaga = function* (action: IFetchSilencedUsersAction) {
  const { channelId } = action.payload;
  try {
    const silencedUsers = yield authenticatedCall(getSilencedUsers, { channelId });
    if (silencedUsers) {
      yield put(setSilencedUsers({ silencedUsers }));
    }
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error('Error in CHAT saga! fetchSilencedUsersSaga =>', e, action);
  }
};

export const clearAllMessagesSaga = function* (action: IClearAllMessagesAction) {
  const { channel } = action.payload;
  yield put(sendAdminMessage({
    channel,
    data: {
      ':clearMessages': 1,
    },
  }));
};

export const removeMessageSaga = function* (action: IRemoveMessageAction) {
  const { channel, messageKey } = action.payload;
  yield put(sendAdminMessage({
    channel,
    data: {
      ':removeMessage': messageKey,
    },
  }));
};

export const initChatChannelSaga = function* (action: IInitChatChannelAction) {
  const { channel } = action.payload;
  yield put(sendAdminMessage({
    channel,
    data: {
      ':admin': false,
      ':history': 50,
      ':limit': 4,
      ':secure': false,
      ':unique': false,
    },
  }));
};

export const toggleUserSilenceSaga = function* (action: IToggleUserSilenceAction) {
  const { channel, isSilenced, userId, userType } = action.payload;
  yield put(sendAdminMessage({
    channel,
    data: {
      ':chatSilence': {
        isSilenced,
        userId,
        userType,
      },
    },
  }));
};

export default function* translationSaga() {
  yield takeEvery(SEND_ADMIN_MESSAGE, sendAdminMessageSaga);
  yield takeEvery(SEND_MESSAGE, sendMessage);
  yield takeEvery(EXPORT_CHAT, exportChatSaga);
  yield takeEvery(FETCH_SILENCED_USERS, fetchSilencedUsersSaga);
  yield takeEvery(CLEAR_ALL_MESSAGES, clearAllMessagesSaga);
  yield takeEvery(INIT_CHAT_CHANNEL, initChatChannelSaga);
  yield takeEvery(TOGGLE_USER_SILENCE, toggleUserSilenceSaga);
  yield takeEvery(REMOVE_MESSAGE, removeMessageSaga);
}
