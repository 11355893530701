import { createSelector } from 'reselect';
import { msIn } from 'shared/datetime-utils';
import IState from 'services/state';
import { IAccessTokenData, IThirdPartyAuthProvider, RoleScope } from 'services/auth/models';
import { OauthProvider } from 'services/auth/actions';
import { getUserToken, getUserTokenData } from './common';
import { getAnonUsername } from 'services/chat/selectors';
import { getDevicePermanentId } from 'services/device/selectors';
import { injectT } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';

export * from './common';

export enum ViewerState {
  anonymous = 'anonymous',
  authorized = 'authorized',
}

export const getViewerState = (state: IState): ViewerState => {
  const loggedIn = isLoggedIn(state);
  const accountId = getUserId(state);
  return loggedIn && accountId ? ViewerState.authorized : ViewerState.anonymous;
};

export const getViewerId = (state: IState): string => {
  const viewerState = getViewerState(state);
  const accountId = getUserId(state);
  const deviceId = getDevicePermanentId(state);
  return viewerState === ViewerState.authorized && accountId ? accountId : deviceId;
};

export const getUserSubscriptions = (state: IState) => {
  return state.auth.account?.subscriptions;
};

export function getUserAccount(state: IState): IAccessTokenData | null {
  return state.auth.account;
}

export function getRefreshToken(state: IState): string | null {
  return state.auth.refreshToken;
}

export const getUserRoles = createSelector(
  [getUserAccount],
  (userAccount: IAccessTokenData | null) => {
    return userAccount ? userAccount.roles : null;
  },
);

export const getUserRole = injectT(
  (t) => lazy(
    () => createSelector(
      [getUserRoles],
      (roles) => {
        const siteAdmin = roles?.some(role => role.scope === '*' && role.write);
        const producer = roles?.some(role => role.scope === 'Producer');
        const otherRole = roles?.some(role => role.scope === 'Chat_Moderator');
        if (siteAdmin) {
          return t('ADMIN_INVITE_TAB_SITE_ADMIN');
        }
        if (producer) {
          return t('ADMIN_LABEL_PRODUCER');
        }
        if (otherRole) {
          return t('ADMIN_LABEL_CHAT_MODERATOR');
        }
        return '';
      },
    ),
  ),
);

export function isUserLoggedIn(state: IState): boolean {
  return Boolean(getUserToken(state));
}

export function isBusy(state: IState): boolean {
  return state.auth.busy;
}

// LEGACY COMPAT

export const getBusy = (state: IState) => state.auth.busy;
export const getAutoLogin = (state: IState) => Boolean(state.auth.autoLogin);
export const getPrimaryToken = getUserToken;
// TODO: Re-implement
// export const getRefreshToken = (state: IAppState) => state.auth.refreshToken;
// TODO: Ensure this doesn't exist any more
// export const getShardToken = (state: IAppState) => state.auth.shardToken;
export const getUid = createSelector([getUserTokenData], (userTokenData) => (userTokenData ? userTokenData.uid : null));

export const getUserUid = getUid; // TODO: Why are these separate? Are they different?
export const getUserId = createSelector([getUserTokenData], (userTokenData) =>
  userTokenData ? userTokenData._id : null,
);
export const getMaestroLoginFailed = (state: IState) => Boolean(state.auth.loginError);

// TODO: This will 100% break shit
export const getUserBlob = getUserTokenData;

const getPageId = (state: IState): string | null => {
  if (!state.app.object) {
    return null;
  }
  return state.app.object._id;
};

export const isUserChatModeratorRole = createSelector([getUserRoles, getPageId], (roles, pageId) => {
  if (!(pageId && roles) || !Array.isArray(roles)) {
    return false;
  }
  return roles.some(({ pageId: rolePageId = [], scope }) => {
    const hasChatModScope = scope === RoleScope.ChatModerator;
    const allPageMod = !!(hasChatModScope && rolePageId.length === 0);
    const currentPageMod = !!(hasChatModScope && rolePageId.includes(pageId));
    return currentPageMod || allPageMod;
  });
});

export const isGdprAccepted = (state: IState) => state.auth.gdprAccepted;

export const getThirdPartyAuthProviders = createSelector([getUserTokenData], (tokenData: IAccessTokenData | null) => {
  if (!tokenData) {
    return null;
  }
  return tokenData.thirdPartyAuthProviders ? tokenData.thirdPartyAuthProviders : [];
});

export const getProviderId = (providers: IThirdPartyAuthProvider[], provider: OauthProvider): string | null => {
  for (const { accountId, provider: providerName } of providers) {
    if (providerName === provider) {
      return accountId;
    }
  }
  return null;
};

export const isUserSuperAdmin = (state: IState): boolean => {
  return state.auth.isSuperAdmin || false;
};

export const isLoggedIn = (state: IState) => Boolean(getUserBlob(state));

export const siteRequiresEmailVerification = (state: IState) =>
  Boolean(state.app.site.settings && state.app.site.settings.requireEmailVerification);

// TODO: implement
// @ts-ignore
export const pendingEmailVerification = (state: IState) => false;
// export const pendingEmailVerification = createSelector(
//   [isLoggedIn, siteRequiresEmailVerification, getUserBlob],
//   (userIsLoggedIn, siteRequiresVerification, user) => (
//     userIsLoggedIn &&
//     siteRequiresVerification &&
//     Boolean(user.pendingEmailVerification)
//   ),
// );

// TODO: implement
// @ts-ignore
export const isUserEmailVerified = (state: IState) => true;
// export const isUserEmailVerified = createSelector(
//   [siteRequiresEmailVerification, getUserBlob],
//   (siteRequiresVerification, user) => (
//     siteRequiresVerification && Boolean(user.emailVerified)
//   ),
// );

export const getUserCreated = createSelector(
  [getUserTokenData],
  (tokenData) => (tokenData && tokenData.created) || null,
);

export const getUserEmail = createSelector([getUserTokenData], (tokenData) => (tokenData && tokenData.email) || null);

export const getUserName = createSelector([getUserTokenData], (tokenData) => (tokenData && tokenData.name) || null);

export const getUserUsername = getUserName;

// TODO: importing this from services/app caused a circular dependency issue
const getServerTime = (state: IState): number | null => {
  return state.app.timeOffsetLoaded ? Date.now() + state.app.timeOffset : null;
};

export const isNewUser = (state: IState) => {
  const tokenData = getUserTokenData(state);
  const serverTime = getServerTime(state);
  return tokenData ? Math.abs((serverTime || Date.now()) - tokenData.created) < msIn.day : true;
};

export const getUserService = (state: IState) => {
  const tokenData = getUserTokenData(state);
  return tokenData ? tokenData.service : null;
};

export const getIsEpicGamesOauth = createSelector([getUserService], (service) => service === OauthProvider.EPIC);

export const getRegisterError = (state: IState): string | null => {
  return state.auth.registerError;
};

// TODO: implement
// @ts-ignore
export const getUserShardName = (state: unknown) => null;

export const getAccountCreatedDate = (state: IState): number | null => {
  const date = state.auth.account?.created;
  return date ? date : null;
};

// export const getUserShardName = createSelector(
//   getUserBlob,
//   (user) => user ? .shardName || null  ,
// );

// export const getEditorToken = createSelector(
//   [isUserAdmin, getUserBlob, getRefreshToken],
//   (admin, userBlob, refreshToken) => {
//     if (!admin || !userBlob) {
//       return '';
//     }
//     return encodeURIComponent(JSON.stringify({
//       refresh_token: refreshToken,
//       uid: userBlob.uid,
//     }));
//   },
// );

export const getCurrentUser = createSelector([getUserAccount, getAnonUsername], (account, anonUsername) => ({
  name: account?.name || anonUsername,
  uid: account?.uid,
}));
