import axios from 'axios';
import { SERVICE_BASE_URL } from 'config';
import { shardName } from 'firebase-db';
import { OauthProvider } from 'services/auth/actions';
import { ILoginResponse } from 'services/auth/actions';

const LOGIN_URL = `${SERVICE_BASE_URL}/auth/v1/login`;

export declare interface ILegacyAuthResponse {
  _id: string;
  accountCollectionTokens?: ILoginResponse;
  custom_token: string;
  shard_name: string;
  shard_token: string;
  site_id: string;
  token: string; // this one can be parsed by lessthan3 secret
  uid: string;
}

export interface IRequestPayload {
  site_id: string;
}

export interface IAdobeRequestPayload extends IRequestPayload {
  access_token?: string;
}

export interface IBowlTvRequestPayload extends IRequestPayload {
  access_token?: string;
}

export interface IBowlTvRequestPayload extends IRequestPayload {
  access_token?: string;
}

export interface ICornellRequestPayload extends IRequestPayload {
  email?: string;
  name?: string;
  username?: string;
}

export interface IFacebookRequestPayload extends IRequestPayload {
  access_token?: string;
}

export interface ITwitchRequestPayload extends IRequestPayload {
  access_token?: string;
}

export interface IXboxRequestPayload extends IRequestPayload {
  access_token?: string;
}

export const oauthRequest = async <T extends IRequestPayload>(
  provider: OauthProvider,
  data: T,
): Promise<ILegacyAuthResponse> => {
  const resp = await axios.post(`${LOGIN_URL}/${provider}`, {
    ...data,
    firebase: shardName,
  });

  return resp.data;
};
