import { parse } from 'querystring';
import { createSelector } from 'reselect';
import IState from 'services/state';

export const getQuery = createSelector(
  (state: IState): string => state.router?.location.search,
  search => parse(search.replace('?', '')),
);

export const getPath = createSelector(
  (state: IState): string => state.router?.location.pathname,
  path => path,
);
